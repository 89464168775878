import { de, es, fr, it, uk, com, ca } from "../utils/images";

export const sellerCentral = [
  { type: "icon", icon: "TiHome" },
  {
    type: "text",
    title: "Add Product",
    link: "https://google.com",
    target: "_blank",
  },
  {
    type: "text",
    title: "Inventory",
    link: "https://google.com",
    target: "_blank",
  },
  {
    type: "text",
    title: "Orders",
    link: "https://google.com",
    target: "_blank",
  },
];

export const lookuptext = [
  {
    type: "text",
    title: "Load More",
    link: "https://google.com",
    target: "_blank",
  },
];
export const heading = ["seller", "stock", "price", "profit", "roi"];

export const dropdownItems = [
  { id: 1, label: "Seller" },
  { id: 2, label: "Avg.price" },
  { id: 3, label: "Won" },
  { id: 4, label: "Last won" },
  { id: 5, label: "Stock" },
  { id: 6, label: "Type" },
];
export const marketplaceheading = ["Rank", "Price", "Profit", "ROI"];

export const variationsHeading = ["Style", "Size"];

export const analysisheading = ["Seller", "Avg.price", "Won", "Last won"];

export const roiheading = ["R.O.I.", "Profit", "Sale Price"];

export const lookupheading = ["History", "Source", "Cost", "Sale"];

export const valuesKeepa = ["7", "30", "90", "180", "365", "730"];

export const valuesPieChart = ["30", "90", "180", "All"];

export const lookupdata = [
  {
    History: "May ",
    Source: "Not ",
    Cost: "$100.0",
    Sale: "$241.4",
  },
  {
    History: "May ",
    Source: "Not ",
    Cost: "$100.0",
    Sale: "$241.4",
  },
  {
    History: "May ",
    Source: "Not ",
    Cost: "$100.0",
    Sale: "$241.4",
  },
  {
    History: "May ",
    Source: "Not ",
    Cost: "$100.0",
    Sale: "$241.4",
  },
  {
    History: "May ",
    Source: "Not ",
    Cost: "$100.0",
    Sale: "$241.4",
  },
];

export const roidata = [
  {
    "R.O.I.": "1%",
    Profit: "10%",
    "Sale Price": "$100",
  },
  {
    "R.O.I.": "1%",
    Profit: "10%",
    "Sale Price": "$100",
  },
  {
    "R.O.I.": "1%",
    Profit: "10%",
    "Sale Price": "$100",
  },
  {
    "R.O.I.": "1%",
    Profit: "10%",
    "Sale Price": "$100",
  },
  {
    "R.O.I.": "1%",
    Profit: "10%",
    "Sale Price": "$100",
  },
];

export const flagImages = [
  {
    src: uk,
    key: "UK",
  },
  {
    src: fr,
    key: "FR",
  },
  {
    src: de,
    key: "DE",
  },
  {
    src: es,
    key: "SP",
  },
  {
    src: it,
    key: "IT",
  },
  {
    src: com,
    key: "US",
  },
  {
    src: ca,
    key: "CA",
  },
];

export const marketimages = [
  {
    src: uk,
    Rank: "A one",
    Price: "10%",
    Profit: "100",
    ROI: "--",
  },
  {
    src: fr,
    Rank: "A one",
    Price: "10%",
    Profit: "100",
    ROI: "--",
  },
  {
    src: de,
    Rank: "A one",
    Price: "10%",
    Profit: "100",
    ROI: "--",
  },
  {
    src: es,
    Rank: "A one",
    Price: "10%",
    Profit: "100",
    ROI: "--",
  },
  {
    src: it,
    Rank: "A one",
    Price: "10%",
    Profit: "100",
    ROI: "--",
  },
];

export const notesButton = [
  { type: "icon", icon: "FaTag" },
  {
    type: "button",
    title: "Add note",
  },
];

export const buyBoxAnalysis = [
  {
    seller: "Amazon.com",
    avgPrice: "$293.37",
    won: "99%",
    lastWon: "2024-05-31T04:48:00Z",
    stock: "7",
    type: "Retail",
    currentRatingCount: 2510,
    currentRating: 4.5,
  },
  {
    seller: "Mookie's Footwear",
    avgPrice: "$287.98",
    won: "<1%",
    lastWon: "2024-05-21T04:48:00Z",
    stock: "5",
    type: "Supplements",
    currentRatingCount: 510,
    currentRating: 3.3,
  },
  {
    seller: "Small Town ",
    avgPrice: "$249.84",
    won: "<1%",
    lastWon: "2024-03-02T04:48:00Z",
    stock: "3",
    type: "Footwear",
  },
];

export const buyBoxColumns = [
  {
    Header: "Seller",
    accessor: "seller",
  },
  {
    Header: "Avg. price",
    accessor: "avgPrice",
  },
  {
    Header: "Won",
    accessor: "won",
  },
  {
    Header: "Last won",
    accessor: "lastWon",
  },
  {
    Header: "Stock",
    accessor: "stock",
    width: "20%",
  },
  {
    Header: "Type",
    accessor: "type",
  },
];


export const csvData = [
    [
        6515144,
        -1
    ],
    [
        6515144,
        8999,
        6515902,
        -1,
        6649996,
        4699,
        6654572,
        4799,
        6654676,
        4699,
        6656154,
        3999,
        6663578,
        4699,
        6663618,
        3999,
        6675608,
        4699,
        6675776,
        -1,
        6681272,
        8999,
        6681772,
        -1,
        6686808,
        3999,
        6686868,
        8999,
        6687402,
        -1,
        6689188,
        3999,
        6689220,
        -1,
        6696544,
        8999,
        6696620,
        3999,
        6696722,
        -1,
        6697512,
        6999,
        6697580,
        -1,
        6700508,
        3999,
        6700572,
        -1,
        6706212,
        8999,
        6706928,
        3999,
        6707196,
        8999,
        6707504,
        8899,
        6708024,
        -1,
        6711344,
        3999,
        6712008,
        -1,
        6712360,
        8999,
        6712390,
        -1,
        6716758,
        4699,
        6718484,
        4799,
        6720942,
        3999,
        6722904,
        3599,
        6722946,
        3999,
        6736854,
        2998,
        6739352,
        2997,
        6765200,
        3999,
        6767228,
        2997,
        6777108,
        3999,
        6777146,
        4899,
        6777208,
        4997,
        6784248,
        5597,
        6785832,
        3999,
        6877924,
        3599,
        6898080,
        5099,
        6898098,
        3599,
        6908172,
        3999,
        6918534,
        3599,
        6938326,
        3999,
        6938476,
        3599,
        6958656,
        3999,
        6968608,
        3599,
        6976984,
        3999,
        6977338,
        3599,
        6977572,
        3999,
        6977914,
        3599,
        6978328,
        3999,
        6978424,
        3599,
        6978456,
        3999,
        6978472,
        3599,
        6978552,
        3999,
        7008928,
        3599,
        7009152,
        3999,
        7019008,
        3599,
        7029044,
        3999,
        7033136,
        3499,
        7034124,
        3999,
        7042880,
        3773,
        7042982,
        3999,
        7049284,
        3598,
        7057556,
        3499
    ],
    [
        6515144,
        -1,
        6694896,
        2333,
        6694980,
        -1,
        6722534,
        2581,
        6722946,
        -1,
        6724480,
        2581,
        6724666,
        -1,
        6739588,
        2639,
        6740048,
        -1,
        6743270,
        2639,
        6744602,
        -1,
        6756286,
        2697,
        6756456,
        -1,
        6762258,
        2697,
        6762640,
        -1,
        6772296,
        2697,
        6773312,
        -1,
        6773596,
        2697,
        6774224,
        -1,
        6781812,
        3444,
        6782528,
        -1,
        6788648,
        3621,
        6789468,
        -1,
        6790736,
        3512,
        6791092,
        -1,
        6791644,
        3648,
        6791720,
        -1,
        6791838,
        3648,
        6792896,
        -1,
        6793100,
        3716,
        6793268,
        -1,
        6793992,
        3716,
        6804716,
        3693,
        6816508,
        3625,
        6828868,
        3820,
        6830552,
        3903,
        6842504,
        3786,
        6845622,
        3868,
        6857080,
        3751,
        6864894,
        3637,
        6869398,
        3524,
        6880476,
        3411,
        6893232,
        3298,
        6905694,
        3184,
        6909758,
        3264,
        6913378,
        3344,
        6914260,
        3448,
        6914620,
        3344,
        6915568,
        3423,
        6915848,
        3530,
        6920060,
        3423,
        6927540,
        3355,
        6939006,
        3287,
        6939100,
        3390,
        6943436,
        3287,
        6944950,
        3367,
        6949452,
        3472,
        6951686,
        3367,
        6952774,
        3472,
        6955028,
        3367,
        6956360,
        3299,
        6959890,
        3402,
        6960642,
        3299,
        6964828,
        3402,
        6967298,
        3495,
        6971684,
        3563,
        6981868,
        3645,
        6986784,
        3727,
        6989208,
        3614,
        6990684,
        3694,
        6991524,
        3810,
        6994176,
        3694,
        6998088,
        3810,
        7000180,
        3892,
        7005620,
        3774,
        7005892,
        3892,
        7011584,
        3821,
        7019576,
        3563,
        7022666,
        3455,
        7031744,
        3387,
        7032446,
        3493,
        7033254,
        3464,
        7034768,
        3821,
        7041534,
        3904,
        7045880,
        3785,
        7050760,
        3454,
        7057594,
        3359,
        7069502,
        3291
    ],
    [
        6649996,
        -1,
        6650764,
        101756,
        6651142,
        -1,
        6651646,
        33234,
        6652324,
        26492,
        6653136,
        27635,
        6653856,
        19365,
        6654676,
        19882,
        6654956,
        15329,
        6655028,
        19882,
        6655054,
        15329,
        6655116,
        19882,
        6655344,
        13212,
        6656154,
        11214,
        6656612,
        9579,
        6656688,
        11214,
        6656908,
        8078,
        6657516,
        7893,
        6658288,
        5695,
        6659032,
        5692,
        6659292,
        4438,
        6659492,
        4300,
        6659572,
        5692,
        6659740,
        3289,
        6660236,
        3435,
        6660436,
        3289,
        6660500,
        3515,
        6661304,
        1985,
        6661762,
        2177,
        6661824,
        1985,
        6662004,
        1466,
        6662526,
        963,
        6662618,
        1466,
        6662744,
        1124,
        6663426,
        1232,
        6663618,
        959,
        6663796,
        1232,
        6663900,
        853,
        6664002,
        1232,
        6664248,
        925,
        6664410,
        1072,
        6664582,
        925,
        6664764,
        858,
        6665340,
        476,
        6665476,
        858,
        6665740,
        534,
        6666062,
        931,
        6666090,
        858,
        6666338,
        731,
        6666548,
        702,
        6666698,
        663,
        6666944,
        682,
        6667036,
        771,
        6667136,
        928,
        6667408,
        937,
        6667472,
        957,
        6667720,
        810,
        6667908,
        721,
        6668246,
        696,
        6668568,
        860,
        6668674,
        925,
        6668842,
        998,
        6669044,
        933,
        6669168,
        867,
        6669392,
        819,
        6669496,
        813,
        6669716,
        877,
        6669936,
        971,
        6670160,
        1001,
        6670282,
        1061,
        6670478,
        1091,
        6670574,
        926,
        6670744,
        908,
        6671000,
        930,
        6671212,
        993,
        6671344,
        1143,
        6671512,
        1296,
        6671730,
        1374,
        6671806,
        1448,
        6672016,
        1333,
        6672204,
        1225,
        6672366,
        1266,
        6672528,
        1285,
        6672704,
        1293,
        6672898,
        1474,
        6673196,
        1639,
        6673260,
        1749,
        6673696,
        1436,
        6673760,
        1388,
        6674018,
        1433,
        6674194,
        1538,
        6674256,
        1814,
        6674460,
        2101,
        6674744,
        2219,
        6674802,
        2066,
        6675056,
        1909,
        6675236,
        1877,
        6675440,
        1840,
        6675500,
        2003,
        6675776,
        2345,
        6675916,
        2706,
        6676056,
        3592,
        6676252,
        4508,
        6676556,
        5271,
        6676968,
        5756,
        6677280,
        7346,
        6677462,
        10108,
        6677832,
        12167,
        6678804,
        15869,
        6678944,
        18069,
        6679204,
        19473,
        6679370,
        20110,
        6679436,
        18256,
        6680240,
        24779,
        6680484,
        29414,
        6681272,
        26184,
        6681584,
        29815,
        6681628,
        32947,
        6681772,
        34130,
        6682094,
        37702,
        6682236,
        38534,
        6682604,
        33057,
        6682788,
        36130,
        6683422,
        47768,
        6683746,
        44520,
        6683890,
        40035,
        6684440,
        49394,
        6684532,
        55264,
        6684920,
        61220,
        6685308,
        56140,
        6685860,
        60151,
        6686404,
        73947,
        6686808,
        64655,
        6686868,
        54662,
        6687402,
        42271,
        6687804,
        61459,
        6687976,
        63573,
        6688554,
        61898,
        6688852,
        68679,
        6689188,
        76273,
        6689536,
        73300,
        6690324,
        79107,
        6690792,
        89903,
        6691086,
        76470,
        6691850,
        94163,
        6692162,
        98178,
        6692368,
        91789,
        6692472,
        83557,
        6692642,
        81403,
        6693984,
        90052,
        6694646,
        111355,
        6694896,
        119478,
        6694980,
        118610,
        6696084,
        117366,
        6696372,
        133154,
        6696544,
        131953,
        6696856,
        120142,
        6697512,
        131597,
        6698072,
        128825,
        6698220,
        122594,
        6698536,
        104766,
        6698888,
        119671,
        6698966,
        129950,
        6699460,
        142349,
        6699816,
        122345,
        6700366,
        143887,
        6700508,
        151653,
        6700836,
        133580,
        6700980,
        130204,
        6701382,
        120779,
        6701532,
        135426,
        6701936,
        147671,
        6702122,
        158560,
        6702440,
        154660,
        6703036,
        144562,
        6703438,
        175476,
        6703606,
        181494,
        6704724,
        184198,
        6705220,
        197869,
        6705442,
        187417,
        6705602,
        170059,
        6705816,
        171982,
        6706012,
        194007,
        6706212,
        215350,
        6706396,
        232015,
        6706724,
        223467,
        6706928,
        203710,
        6707196,
        187642,
        6707504,
        134180,
        6707692,
        164774,
        6707914,
        178850,
        6708024,
        163026,
        6708254,
        162963,
        6708516,
        167523,
        6708634,
        168887,
        6708984,
        199181,
        6709062,
        214796,
        6709312,
        226728,
        6709612,
        227130,
        6710016,
        198847,
        6710140,
        209585,
        6710556,
        227179,
        6710614,
        242491,
        6711576,
        104966,
        6712030,
        69582,
        6712212,
        104966,
        6712390,
        69582,
        6713012,
        74340,
        6713664,
        99662,
        6713926,
        95386,
        6714760,
        95204,
        6715384,
        110852,
        6716068,
        220822,
        6716760,
        288116,
        6717666,
        268801,
        6718086,
        22892,
        6718608,
        2300,
        6718700,
        22892,
        6718950,
        2300,
        6719550,
        1064,
        6719686,
        1072,
        6719772,
        1064,
        6720392,
        1187,
        6721060,
        1878,
        6721146,
        1697,
        6721700,
        1624,
        6722510,
        2053,
        6723242,
        1859,
        6724244,
        2162,
        6724666,
        1762,
        6725364,
        2041,
        6726048,
        1488,
        6726182,
        1830,
        6726264,
        1488,
        6726828,
        2137,
        6727304,
        1498,
        6727460,
        2137,
        6727570,
        1498,
        6728232,
        2087,
        6728598,
        1563,
        6728760,
        1473,
        6729788,
        1600,
        6730000,
        1873,
        6730160,
        1467,
        6730280,
        1873,
        6730420,
        1467,
        6730494,
        1714,
        6730678,
        1835,
        6730892,
        1816,
        6731072,
        1467,
        6731312,
        1816,
        6731972,
        3076,
        6732104,
        3528,
        6732324,
        3076,
        6732416,
        3512,
        6732500,
        3076,
        6732592,
        3512,
        6733364,
        3784,
        6733660,
        4074,
        6733800,
        4003,
        6733872,
        3784,
        6734008,
        3760,
        6734170,
        4003,
        6734950,
        3827,
        6734960,
        3894,
        6735190,
        3827,
        6735570,
        3896,
        6735708,
        4035,
        6735852,
        3896,
        6736304,
        4040,
        6737700,
        4826,
        6737772,
        5418,
        6737864,
        4826,
        6738098,
        6117,
        6738220,
        6156,
        6738320,
        4826,
        6738456,
        6156,
        6739204,
        5502,
        6739596,
        6290,
        6739746,
        5107,
        6740876,
        4877,
        6741220,
        4440,
        6741288,
        4220,
        6741416,
        4440,
        6742036,
        4270,
        6742200,
        4931,
        6742320,
        4270,
        6743452,
        4375,
        6744144,
        4672,
        6744234,
        5115,
        6744352,
        5060,
        6744468,
        5115,
        6744994,
        5585,
        6745176,
        4791,
        6745572,
        5911,
        6746262,
        5699,
        6746400,
        6210,
        6746484,
        6365,
        6746760,
        6962,
        6747014,
        5860,
        6747200,
        5795,
        6747384,
        6962,
        6747816,
        425,
        6747896,
        323,
        6748024,
        425,
        6748116,
        312,
        6748184,
        425,
        6748584,
        312,
        6749508,
        174,
        6749644,
        152,
        6749732,
        178,
        6749788,
        152,
        6750048,
        110,
        6750356,
        178,
        6750784,
        126,
        6750888,
        108,
        6751512,
        103,
        6752224,
        60,
        6752378,
        80,
        6752432,
        60,
        6752948,
        71,
        6753286,
        56,
        6753368,
        71,
        6753666,
        56,
        6753722,
        68,
        6753894,
        56,
        6753988,
        55,
        6754074,
        56,
        6754228,
        36,
        6755168,
        26,
        6755804,
        27,
        6756286,
        -1,
        6756376,
        27,
        6756660,
        22,
        6756700,
        27,
        6756788,
        22,
        6756892,
        33,
        6756988,
        22,
        6757292,
        27,
        6758092,
        52,
        6758258,
        30,
        6758344,
        66,
        6758416,
        30,
        6758688,
        65,
        6758936,
        55,
        6758992,
        65,
        6759518,
        56,
        6759686,
        105,
        6759796,
        56,
        6760128,
        764,
        6760832,
        955,
        6760912,
        791,
        6761660,
        1096,
        6762140,
        2261,
        6762258,
        1096,
        6762476,
        2485,
        6762640,
        2459,
        6762796,
        2261,
        6763058,
        2408,
        6763300,
        2480,
        6763370,
        2408,
        6763722,
        2620,
        6763752,
        2708,
        6763860,
        2620,
        6763880,
        2987,
        6764032,
        2620,
        6764260,
        3126,
        6764348,
        2620,
        6764500,
        3126,
        6765240,
        3104,
        6765328,
        3475,
        6765516,
        3104,
        6765952,
        3211,
        6766000,
        3097,
        6766116,
        3211,
        6766692,
        3033,
        6766770,
        3609,
        6766888,
        3033,
        6766900,
        3465,
        6766978,
        3033,
        6767408,
        3254,
        6767582,
        2885,
        6767788,
        3254,
        6768018,
        2462,
        6768096,
        2568,
        6768368,
        2181,
        6768456,
        2568,
        6768730,
        1786,
        6769512,
        1944,
        6769656,
        2016,
        6769760,
        1944,
        6770228,
        1730,
        6771114,
        1526,
        6771652,
        1633,
        6772416,
        1769,
        6772600,
        1789,
        6772652,
        1769,
        6773182,
        1627,
        6773312,
        1601,
        6773640,
        1627,
        6773918,
        1518,
        6774352,
        1343,
        6774426,
        1518,
        6774536,
        1145,
        6775344,
        1203,
        6775440,
        1311,
        6775512,
        1203,
        6775536,
        1316,
        6775594,
        1203,
        6775824,
        1219,
        6775878,
        1203,
        6776080,
        1189,
        6776164,
        1148,
        6776384,
        1257,
        6776448,
        1148,
        6776740,
        1328,
        6776800,
        -1,
        6776900,
        1404,
        6777108,
        1328,
        6777536,
        1215,
        6777704,
        1178,
        6777776,
        1288,
        6777892,
        1178,
        6778178,
        1512,
        6778686,
        1259,
        6779668,
        1296,
        6780040,
        1271,
        6780112,
        1296,
        6780360,
        1282,
        6781088,
        1413,
        6781220,
        1480,
        6781328,
        1413,
        6781424,
        1430,
        6781616,
        1413,
        6781812,
        1298,
        6782412,
        1292,
        6782480,
        1298,
        6782564,
        1292,
        6783240,
        867,
        6783892,
        812,
        6783946,
        867,
        6784000,
        812,
        6784316,
        616,
        6784600,
        812,
        6784748,
        582,
        6785480,
        862,
        6785580,
        887,
        6785650,
        862,
        6785768,
        951,
        6785952,
        973,
        6786288,
        1008,
        6786416,
        1091,
        6786534,
        1008,
        6786912,
        1456,
        6787372,
        1717,
        6787488,
        1692,
        6788042,
        1841,
        6788200,
        1692,
        6788434,
        1918,
        6788992,
        1859,
        6789128,
        1861,
        6789776,
        2354,
        6790476,
        2610,
        6791232,
        2759,
        6791416,
        3139,
        6791572,
        3019,
        6791720,
        3048,
        6791920,
        3024,
        6792156,
        2984,
        6792444,
        3126,
        6792544,
        3120,
        6792748,
        3267,
        6792896,
        3078,
        6793100,
        3091,
        6793432,
        3112,
        6793660,
        3126,
        6793680,
        3328,
        6793908,
        3625,
        6794146,
        3745,
        6794290,
        3540,
        6794608,
        3499,
        6795036,
        3547,
        6795184,
        3615,
        6795280,
        3729,
        6795470,
        3746,
        6795792,
        3581,
        6795890,
        3764,
        6796078,
        3701,
        6796314,
        3713,
        6796498,
        4134,
        6796812,
        4342,
        6796954,
        4467,
        6797184,
        4248,
        6797326,
        4134,
        6797512,
        4365,
        6797692,
        4405,
        6797990,
        4557,
        6798116,
        4471,
        6798362,
        5074,
        6798566,
        5125,
        6798716,
        4875,
        6798970,
        4753,
        6799108,
        4829,
        6799496,
        5020,
        6799736,
        4997,
        6799904,
        4905,
        6800164,
        4830,
        6800292,
        4739,
        6800500,
        4657,
        6800788,
        4555,
        6800996,
        4474,
        6801160,
        4407,
        6801340,
        4334,
        6801532,
        4293,
        6801824,
        4484,
        6801952,
        4559,
        6802396,
        4631,
        6802576,
        5069,
        6802764,
        5377,
        6803118,
        5475,
        6803184,
        5302,
        6803384,
        5327,
        6803584,
        5590,
        6803856,
        5212,
        6803968,
        5283,
        6804420,
        5036,
        6804600,
        4751,
        6804848,
        4696,
        6805044,
        4859,
        6805158,
        4912,
        6805450,
        5287,
        6805568,
        5134,
        6805840,
        5084,
        6805960,
        4712,
        6806140,
        4593,
        6806540,
        4988,
        6806820,
        5299,
        6806978,
        5327,
        6807228,
        5205,
        6807408,
        4565,
        6807728,
        4608,
        6807820,
        4829,
        6808102,
        5108,
        6808328,
        5544,
        6808428,
        5475,
        6808608,
        4973,
        6808900,
        4674,
        6808984,
        4476,
        6809194,
        4703,
        6809476,
        4712,
        6809696,
        5030,
        6809778,
        5021,
        6809996,
        4898,
        6810220,
        4775,
        6810408,
        4736,
        6810654,
        4726,
        6811000,
        5040,
        6811114,
        5281,
        6811332,
        5223,
        6811440,
        5231,
        6811792,
        4935,
        6811872,
        4586,
        6812266,
        4718,
        6813036,
        3938,
        6813208,
        3890,
        6813460,
        3919,
        6813638,
        4067,
        6813880,
        3990,
        6814124,
        4278,
        6814250,
        4230,
        6814510,
        3893,
        6814698,
        3500,
        6814900,
        3593,
        6815214,
        3562,
        6815288,
        3615,
        6815460,
        3860,
        6815772,
        3933,
        6815880,
        3799,
        6816132,
        3684,
        6816338,
        3559,
        6816508,
        3736,
        6816832,
        3796,
        6817048,
        3926,
        6817214,
        3995,
        6817340,
        3762,
        6817508,
        3674,
        6817732,
        3641,
        6817936,
        3852,
        6818198,
        3946,
        6818368,
        4221,
        6818608,
        4385,
        6818792,
        4205,
        6818980,
        4025,
        6819176,
        4009,
        6819390,
        4057,
        6819564,
        4385,
        6819940,
        4716,
        6820016,
        4882,
        6820356,
        4906,
        6820420,
        4573,
        6820648,
        4375,
        6821010,
        4668,
        6821136,
        4839,
        6821326,
        4979,
        6821456,
        4936,
        6821740,
        4344,
        6821942,
        4537,
        6822096,
        4566,
        6822380,
        4627,
        6822636,
        4549,
        6822706,
        4922,
        6823016,
        4871,
        6823130,
        4731,
        6823432,
        4617,
        6823492,
        4660,
        6823904,
        4624,
        6824018,
        4622,
        6824134,
        5013,
        6824320,
        5323,
        6824560,
        5357,
        6824804,
        5483,
        6824980,
        5438,
        6825294,
        5586,
        6825380,
        5684,
        6825654,
        5992,
        6825748,
        5955,
        6826058,
        6314,
        6826308,
        6125,
        6826424,
        6172,
        6826620,
        6314,
        6827004,
        6679,
        6827268,
        6900,
        6827440,
        6919,
        6827640,
        5991,
        6827860,
        6088,
        6828110,
        6081,
        6828298,
        6034,
        6828496,
        5524,
        6828702,
        5573,
        6828868,
        5488,
        6829048,
        5314,
        6829296,
        4807,
        6829476,
        4982,
        6829652,
        5075,
        6829932,
        5631,
        6830088,
        6322,
        6830392,
        5694,
        6830552,
        5396,
        6830656,
        4933,
        6830936,
        5060,
        6831156,
        5435,
        6831358,
        6090,
        6831524,
        6263,
        6831732,
        6088,
        6831984,
        5324,
        6832272,
        5341,
        6832500,
        5470,
        6832940,
        6951,
        6833328,
        6496,
        6833502,
        6154,
        6833700,
        6453,
        6833906,
        6653,
        6834072,
        6345,
        6834316,
        6609,
        6834506,
        6558,
        6834868,
        6099,
        6834924,
        6203,
        6835150,
        6300,
        6835340,
        6329,
        6835524,
        6392,
        6835776,
        6920,
        6835928,
        7088,
        6836140,
        6277,
        6836590,
        6317,
        6836778,
        6648,
        6836992,
        7276,
        6837198,
        7630,
        6837600,
        7064,
        6837612,
        6967,
        6837920,
        6563,
        6838012,
        6496,
        6838522,
        7022,
        6838628,
        7132,
        6838788,
        7025,
        6839050,
        6744,
        6839246,
        6117,
        6839470,
        6284,
        6839620,
        6363,
        6839904,
        6786,
        6840056,
        6997,
        6840250,
        7079,
        6840506,
        6599,
        6840624,
        5985,
        6840866,
        5941,
        6841136,
        6254,
        6841392,
        6241,
        6841584,
        7219,
        6841772,
        6822,
        6842198,
        6231,
        6842320,
        5865,
        6842504,
        5899,
        6842592,
        5894,
        6842912,
        6259,
        6843002,
        6542,
        6843280,
        6387,
        6843456,
        5706,
        6843584,
        5330,
        6843832,
        5604,
        6844066,
        5425,
        6844322,
        4924,
        6844400,
        3898,
        6844676,
        3666,
        6844864,
        3031,
        6845032,
        2845,
        6845252,
        3113,
        6845434,
        3089,
        6845768,
        3228,
        6845846,
        3345,
        6846130,
        3332,
        6846268,
        3060,
        6846544,
        2913,
        6846712,
        3135,
        6846880,
        3511,
        6847082,
        3898,
        6847326,
        3805,
        6847486,
        3911,
        6847504,
        3805,
        6847620,
        3911,
        6847792,
        3786,
        6848176,
        3816,
        6848358,
        3853,
        6848688,
        4038,
        6848776,
        4249,
        6848916,
        4363,
        6849170,
        3836,
        6849340,
        3725,
        6849640,
        3806,
        6849764,
        3727,
        6850108,
        4159,
        6850232,
        4016,
        6850378,
        3293,
        6850620,
        2850,
        6850848,
        2896,
        6851008,
        2905,
        6851232,
        3006,
        6851460,
        3450,
        6851618,
        3581,
        6851844,
        3590,
        6852040,
        3363,
        6852272,
        3273,
        6852524,
        3303,
        6852670,
        3522,
        6852914,
        3515,
        6853104,
        3879,
        6853528,
        3792,
        6853912,
        3822,
        6854234,
        3967,
        6854368,
        4140,
        6854674,
        4564,
        6854752,
        4235,
        6854936,
        3557,
        6855370,
        3455,
        6855596,
        3589,
        6855766,
        3801,
        6856004,
        4155,
        6856156,
        3915,
        6856484,
        3688,
        6856612,
        3749,
        6856912,
        3941,
        6857080,
        4192,
        6857248,
        4345,
        6857466,
        4507,
        6857630,
        4367,
        6857886,
        4058,
        6858378,
        4276,
        6858516,
        4361,
        6858704,
        4764,
        6858988,
        5098,
        6859096,
        4860,
        6859312,
        4533,
        6859442,
        4484,
        6859752,
        4531,
        6859918,
        4882,
        6860182,
        4754,
        6860420,
        5009,
        6860532,
        5210,
        6860728,
        4793,
        6860972,
        4702,
        6861214,
        4758,
        6861342,
        4836,
        6861666,
        5026,
        6861818,
        5456,
        6861970,
        5316,
        6862308,
        4978,
        6862444,
        5154,
        6862644,
        5171,
        6862788,
        5412,
        6863036,
        5688,
        6863236,
        5830,
        6863388,
        5787,
        6863586,
        5179,
        6863840,
        4977,
        6864008,
        4853,
        6864234,
        4754,
        6864432,
        4846,
        6864628,
        5265,
        6864894,
        5602,
        6865034,
        5126,
        6865276,
        5063,
        6865502,
        5169,
        6865740,
        5432,
        6865896,
        5320,
        6866074,
        5134,
        6866290,
        5197,
        6866548,
        4574,
        6866864,
        4331,
        6867104,
        4506,
        6867332,
        4761,
        6867548,
        4926,
        6867730,
        4800,
        6867936,
        4442,
        6868084,
        4609,
        6868522,
        4777,
        6868716,
        4993,
        6868954,
        5286,
        6869152,
        5303,
        6869398,
        5059,
        6869614,
        5143,
        6869928,
        5234,
        6870146,
        5483,
        6870448,
        5626,
        6870612,
        5634,
        6870756,
        5148,
        6870780,
        5634,
        6870880,
        5148,
        6870972,
        5007,
        6871398,
        5311,
        6871722,
        5743,
        6871902,
        5638,
        6872080,
        5136,
        6872324,
        4939,
        6872492,
        5050,
        6872764,
        5135,
        6873048,
        5604,
        6873358,
        5708,
        6873480,
        5890,
        6873604,
        5523,
        6873886,
        5187,
        6874158,
        5534,
        6874404,
        5415,
        6874628,
        5670,
        6874908,
        5799,
        6875148,
        4847,
        6875432,
        4837,
        6875556,
        4942,
        6875952,
        4883,
        6876200,
        4825,
        6876480,
        5065,
        6876580,
        4813,
        6876966,
        4814,
        6877020,
        5057,
        6877216,
        5296,
        6877612,
        5669,
        6877832,
        5235,
        6878028,
        4804,
        6878360,
        4711,
        6878480,
        4687,
        6878616,
        4971,
        6878920,
        5004,
        6879070,
        4994,
        6879300,
        4743,
        6879724,
        4483,
        6880130,
        4306,
        6880352,
        4232,
        6880568,
        4314,
        6880896,
        4103,
        6881004,
        3775,
        6881230,
        3892,
        6881448,
        4097,
        6881662,
        4164,
        6881808,
        4313,
        6882124,
        4285,
        6882212,
        3923,
        6882636,
        3650,
        6882868,
        3782,
        6883052,
        3985,
        6883350,
        4418,
        6883580,
        4204,
        6883764,
        3789,
        6884274,
        3592,
        6884418,
        3608,
        6884722,
        3644,
        6884836,
        3642,
        6885010,
        3650,
        6885460,
        3386,
        6885600,
        3482,
        6885854,
        3347,
        6886140,
        3676,
        6886328,
        3778,
        6886480,
        3611,
        6886668,
        3524,
        6886912,
        3496,
        6887206,
        3479,
        6887288,
        3611,
        6887500,
        3738,
        6887672,
        3872,
        6887890,
        3505,
        6888118,
        2933,
        6888300,
        2923,
        6888460,
        2968,
        6888708,
        2975,
        6888910,
        3061,
        6889096,
        3372,
        6889376,
        3409,
        6889556,
        3035,
        6889712,
        3004,
        6890020,
        3049,
        6890108,
        3104,
        6890304,
        3249,
        6890584,
        3313,
        6890764,
        3416,
        6891124,
        3332,
        6891368,
        3184,
        6891524,
        3212,
        6891890,
        3286,
        6892036,
        3421,
        6892332,
        3381,
        6892436,
        3175,
        6892588,
        3102,
        6892896,
        3064,
        6893016,
        3188,
        6893232,
        3077,
        6893374,
        3239,
        6893620,
        3315,
        6893784,
        3094,
        6894016,
        2967,
        6894224,
        2989,
        6894412,
        3023,
        6894612,
        3229,
        6894818,
        3257,
        6895008,
        3265,
        6895210,
        3329,
        6895432,
        3126,
        6895670,
        3182,
        6895884,
        3270,
        6896064,
        3093,
        6896226,
        3237,
        6896504,
        3350,
        6896652,
        3120,
        6896944,
        2963,
        6897144,
        3012,
        6897388,
        2885,
        6897484,
        3131,
        6897700,
        3254,
        6897922,
        3440,
        6898080,
        3266,
        6898268,
        2926,
        6898500,
        3056,
        6898736,
        3083,
        6899128,
        3351,
        6899348,
        3367,
        6899604,
        3044,
        6899734,
        3140,
        6900024,
        3020,
        6900144,
        3060,
        6900460,
        3390,
        6900536,
        3469,
        6900756,
        3732,
        6900948,
        3754,
        6901176,
        3795,
        6901376,
        3856,
        6901556,
        3862,
        6901808,
        3940,
        6902020,
        3923,
        6902188,
        4029,
        6902350,
        3635,
        6902616,
        3515,
        6902884,
        3362,
        6903060,
        3276,
        6903272,
        3382,
        6903452,
        3313,
        6903632,
        3139,
        6903868,
        3000,
        6904172,
        2982,
        6904262,
        3059,
        6904572,
        3098,
        6904740,
        3111,
        6905024,
        3118,
        6905064,
        3201,
        6905272,
        2986,
        6905428,
        3008,
        6905694,
        3031,
        6905872,
        3023,
        6906060,
        2969,
        6906328,
        3165,
        6906480,
        3363,
        6906670,
        3153,
        6906924,
        2981,
        6907100,
        3032,
        6907372,
        3053,
        6907538,
        3193,
        6907686,
        3539,
        6907888,
        3774,
        6908136,
        3751,
        6908308,
        3412,
        6908492,
        3442,
        6908668,
        3665,
        6909036,
        4060,
        6909278,
        4760,
        6909312,
        5334,
        6909544,
        5368,
        6909758,
        5486,
        6909960,
        5458,
        6910328,
        6019,
        6910734,
        5782,
        6910740,
        5980,
        6910960,
        6249,
        6911122,
        5815,
        6911648,
        5626,
        6911780,
        5525,
        6912020,
        5730,
        6912172,
        5895,
        6912408,
        6040,
        6912892,
        6234,
        6913080,
        6295,
        6913378,
        6359,
        6913598,
        6720,
        6913760,
        6392,
        6914076,
        6236,
        6914260,
        6233,
        6914620,
        6120,
        6914868,
        5854,
        6915068,
        5981,
        6915244,
        5950,
        6915568,
        5768,
        6915696,
        5864,
        6915848,
        6009,
        6916066,
        5864,
        6916314,
        6244,
        6916640,
        6512,
        6916836,
        6552,
        6917276,
        6468,
        6917544,
        6613,
        6917700,
        7419,
        6918038,
        7343,
        6918118,
        7104,
        6918534,
        6287,
        6918692,
        6281,
        6918992,
        6610,
        6919100,
        6483,
        6919444,
        6803,
        6919572,
        6788,
        6919740,
        6100,
        6919928,
        5974,
        6920248,
        6130,
        6920376,
        5605,
        6920876,
        5422,
        6920984,
        5062,
        6921260,
        4898,
        6921412,
        4917,
        6921648,
        4988,
        6921810,
        5024,
        6922004,
        5183,
        6922302,
        4942,
        6922472,
        4732,
        6922652,
        4446,
        6922808,
        4391,
        6923126,
        4460,
        6923516,
        4853,
        6923652,
        4833,
        6923880,
        4794,
        6924204,
        4360,
        6924312,
        4118,
        6924476,
        4128,
        6924664,
        4417,
        6924868,
        4821,
        6925070,
        4953,
        6925300,
        4768,
        6925548,
        4475,
        6925736,
        4469,
        6925924,
        4419,
        6926100,
        4258,
        6926300,
        4389,
        6926532,
        4550,
        6926684,
        4771,
        6927332,
        4252,
        6927540,
        4572,
        6927764,
        4803,
        6927964,
        5145,
        6928166,
        5307,
        6928296,
        4868,
        6928782,
        4551,
        6928940,
        4888,
        6929270,
        5297,
        6929344,
        5351,
        6929532,
        5843,
        6929842,
        5417,
        6929966,
        5260,
        6930220,
        5165,
        6930378,
        4989,
        6930764,
        5646,
        6930946,
        5675,
        6931284,
        5597,
        6931600,
        5338,
        6931796,
        5456,
        6931912,
        5548,
        6932286,
        5268,
        6932404,
        5438,
        6932544,
        5599,
        6932768,
        5140,
        6932890,
        5107,
        6933124,
        5273,
        6933340,
        5293,
        6933572,
        6010,
        6933792,
        6382,
        6933928,
        6056,
        6934156,
        5349,
        6934526,
        5263,
        6934720,
        5404,
        6934780,
        5642,
        6934964,
        5914,
        6935198,
        5832,
        6935348,
        5761,
        6935600,
        5411,
        6935896,
        4877,
        6936224,
        4990,
        6936348,
        5089,
        6936572,
        5386,
        6936850,
        5325,
        6936896,
        4914,
        6937232,
        4743,
        6937392,
        4822,
        6937638,
        4915,
        6937862,
        4713,
        6937992,
        4997,
        6938176,
        5372,
        6938326,
        5133,
        6938668,
        4983,
        6938922,
        5274,
        6939006,
        5312,
        6939330,
        5689,
        6939604,
        5862,
        6939876,
        5707,
        6940108,
        5318,
        6940218,
        5305,
        6940432,
        5085,
        6940620,
        5421,
        6940822,
        5572,
        6941040,
        5780,
        6941272,
        5685,
        6941530,
        5356,
        6941608,
        5336,
        6941860,
        5216,
        6942336,
        5815,
        6942610,
        5620,
        6942736,
        5698,
        6943052,
        5663,
        6943134,
        5639,
        6943364,
        5772,
        6943590,
        6005,
        6943738,
        6334,
        6944022,
        6071,
        6944416,
        5903,
        6944532,
        5723,
        6944648,
        5589,
        6944818,
        5706,
        6945092,
        5812,
        6945316,
        6391,
        6945440,
        5842,
        6945700,
        5612,
        6946032,
        5344,
        6946172,
        5227,
        6946324,
        5398,
        6946426,
        5633,
        6946812,
        6080,
        6946902,
        5499,
        6947084,
        5309,
        6947512,
        4986,
        6947768,
        5024,
        6947916,
        4999,
        6948370,
        4942,
        6948500,
        4686,
        6948752,
        4600,
        6949068,
        4581,
        6949452,
        5383,
        6949730,
        5913,
        6949932,
        5684,
        6950158,
        5609,
        6950344,
        5481,
        6950522,
        5694,
        6950710,
        7139,
        6950896,
        7208,
        6951116,
        6983,
        6951408,
        6313,
        6951686,
        6131,
        6951928,
        6019,
        6952204,
        6254,
        6952372,
        6339,
        6952554,
        6100,
        6952748,
        6279,
        6953008,
        6135,
        6953272,
        6221,
        6953376,
        6147,
        6953584,
        6280,
        6953790,
        6428,
        6953964,
        6305,
        6954208,
        5717,
        6954576,
        5578,
        6954786,
        5459,
        6955028,
        5413,
        6955300,
        5785,
        6955448,
        5629,
        6955570,
        5731,
        6955844,
        5483,
        6956016,
        5333,
        6956236,
        5353,
        6956360,
        5003,
        6956618,
        4891,
        6956804,
        4965,
        6957086,
        4845,
        6957238,
        4185,
        6957468,
        4125,
        6957720,
        4273,
        6957876,
        4187,
        6958102,
        4403,
        6958218,
        4589,
        6958450,
        4680,
        6958656,
        4557,
        6958908,
        4757,
        6959330,
        5700,
        6959780,
        6246,
        6959890,
        6071,
        6960092,
        5850,
        6960348,
        6009,
        6960518,
        5968,
        6960740,
        6557,
        6960908,
        7362,
        6961124,
        8142,
        6961260,
        7767,
        6961494,
        7616,
        6961680,
        7357,
        6961896,
        6855,
        6962214,
        7074,
        6962500,
        7402,
        6962636,
        7360,
        6962934,
        7984,
        6963052,
        7664,
        6963176,
        7486,
        6963360,
        7423,
        6963608,
        6895,
        6963960,
        7178,
        6964210,
        7018,
        6964512,
        6810,
        6964562,
        6406,
        6964828,
        6575,
        6964988,
        6592,
        6965280,
        6679,
        6965412,
        6600,
        6965614,
        6107,
        6965868,
        4991,
        6966116,
        4847,
        6966292,
        4897,
        6966612,
        5341,
        6966826,
        6003,
        6967000,
        5863,
        6967298,
        5332,
        6967580,
        4955,
        6967996,
        5278,
        6968190,
        5229,
        6968298,
        5351,
        6968440,
        5047,
        6968666,
        4484,
        6968832,
        4301,
        6969062,
        4249,
        6969280,
        4103,
        6969508,
        4266,
        6969650,
        4303,
        6969830,
        4378,
        6970040,
        3736,
        6970444,
        3076,
        6970612,
        2980,
        6970852,
        3008,
        6971092,
        3091,
        6971364,
        3017,
        6971436,
        2847,
        6971684,
        2467,
        6971904,
        2533,
        6972076,
        2550,
        6972280,
        2577,
        6972480,
        2653,
        6972728,
        2709,
        6973136,
        2125,
        6973396,
        2146,
        6973812,
        2389,
        6974124,
        2381,
        6974136,
        2389,
        6974202,
        2381,
        6974380,
        2255,
        6974644,
        1949,
        6974814,
        2026,
        6974998,
        2049,
        6975282,
        2155,
        6975436,
        2261,
        6975686,
        2205,
        6975904,
        1866,
        6976154,
        1783,
        6976296,
        1769,
        6976504,
        1894,
        6976736,
        1997,
        6976970,
        2132,
        6977140,
        2170,
        6977320,
        2086,
        6977474,
        1944,
        6977684,
        1991,
        6977914,
        2194,
        6978212,
        2309,
        6978316,
        2384,
        6978476,
        2478,
        6978820,
        2420,
        6978938,
        2265,
        6979136,
        2332,
        6979388,
        2578,
        6979576,
        2858,
        6979774,
        3133,
        6979888,
        3009,
        6980152,
        2699,
        6980294,
        2535,
        6980544,
        2628,
        6980790,
        2855,
        6981000,
        3328,
        6981216,
        3438,
        6981384,
        3731,
        6981606,
        3334,
        6981822,
        3107,
        6982072,
        3146,
        6982204,
        3279,
        6982484,
        3459,
        6982652,
        3523,
        6983966,
        4115,
        6984130,
        4207,
        6984370,
        3930,
        6984646,
        3675,
        6984996,
        3634,
        6985396,
        3985,
        6985596,
        3944,
        6985896,
        3810,
        6986308,
        3355,
        6986640,
        3639,
        6986784,
        3804,
        6987044,
        3764,
        6987556,
        3400,
        6987696,
        3290,
        6987812,
        3449,
        6987816,
        3290,
        6987870,
        3449,
        6988060,
        3776,
        6988244,
        4070,
        6988584,
        4314,
        6988652,
        4300,
        6989038,
        4089,
        6989256,
        4149,
        6989464,
        4156,
        6989720,
        4400,
        6989886,
        4551,
        6990132,
        4631,
        6990334,
        4476,
        6990584,
        4393,
        6990944,
        4686,
        6991132,
        4860,
        6991200,
        5164,
        6991424,
        5388,
        6991644,
        5194,
        6991932,
        4903,
        6992026,
        4957,
        6992252,
        5171,
        6992518,
        5174,
        6992704,
        5153,
        6992816,
        5070,
        6993006,
        4754,
        6993232,
        4462,
        6993516,
        4455,
        6993740,
        4546,
        6993936,
        4844,
        6994126,
        5254,
        6994352,
        5317,
        6994476,
        5086,
        6994656,
        4707,
        6994964,
        4576,
        6995032,
        4650,
        6995256,
        5092,
        6995460,
        5010,
        6995648,
        5262,
        6995908,
        5233,
        6996048,
        4943,
        6996344,
        4789,
        6996456,
        4849,
        6996740,
        4994,
        6996864,
        5221,
        6997060,
        5110,
        6997360,
        5089,
        6997460,
        4564,
        6997628,
        4326,
        6997848,
        4473,
        6998088,
        4618,
        6998230,
        4805,
        6998448,
        4991,
        6998640,
        4970,
        6998850,
        4886,
        6999036,
        4256,
        6999278,
        4376,
        6999442,
        4447,
        6999632,
        4637,
        6999844,
        5120,
        7000060,
        5456,
        7000240,
        4961,
        7000800,
        4682,
        7000872,
        4923,
        7001376,
        5175,
        7001686,
        5152,
        7001894,
        4640,
        7002068,
        4485,
        7002340,
        4651,
        7002460,
        4866,
        7002800,
        4903,
        7002876,
        5375,
        7003108,
        5395,
        7003392,
        5279,
        7003474,
        5049,
        7003642,
        5027,
        7003908,
        5175,
        7004160,
        5166,
        7004232,
        5296,
        7004516,
        5274,
        7004720,
        5350,
        7004932,
        5280,
        7005096,
        5293,
        7005620,
        5451,
        7005700,
        5483,
        7005892,
        5790,
        7006080,
        5456,
        7006432,
        5321,
        7006600,
        5066,
        7006760,
        5034,
        7006956,
        5556,
        7007164,
        5370,
        7007420,
        5676,
        7007554,
        5218,
        7007754,
        5050,
        7007964,
        5042,
        7008152,
        5052,
        7008390,
        5076,
        7008656,
        5428,
        7008754,
        5623,
        7009256,
        4950,
        7009432,
        4872,
        7009676,
        5145,
        7009988,
        5502,
        7010152,
        5546,
        7010252,
        5557,
        7010612,
        5127,
        7010820,
        4980,
        7011042,
        5055,
        7011126,
        5030,
        7011352,
        5500,
        7011480,
        5698,
        7011716,
        5368,
        7011924,
        5078,
        7012126,
        5025,
        7012328,
        5135,
        7012516,
        5399,
        7012704,
        5748,
        7012896,
        5667,
        7013280,
        5652,
        7013374,
        5316,
        7013588,
        5425,
        7013696,
        5366,
        7013914,
        5607,
        7014154,
        5815,
        7014340,
        6016,
        7014586,
        5818,
        7014732,
        5372,
        7014954,
        5139,
        7015230,
        5160,
        7015376,
        5456,
        7015588,
        5651,
        7015754,
        5632,
        7015996,
        5442,
        7016192,
        5146,
        7016388,
        5115,
        7016590,
        4995,
        7016820,
        4770,
        7017118,
        5074,
        7017200,
        4897,
        7017388,
        4795,
        7017648,
        4536,
        7018012,
        4493,
        7018240,
        4613,
        7018556,
        4541,
        7018660,
        4388,
        7018852,
        4445,
        7019024,
        3953,
        7019216,
        3622,
        7019448,
        3777,
        7019620,
        3603,
        7019896,
        3650,
        7020084,
        3580,
        7020256,
        3717,
        7020582,
        3473,
        7020676,
        3387,
        7020850,
        3503,
        7021150,
        3543,
        7021264,
        3903,
        7021540,
        3736,
        7021748,
        3631,
        7021968,
        3355,
        7022236,
        3419,
        7022424,
        3454,
        7022666,
        3544,
        7022780,
        3776,
        7022976,
        3888,
        7023164,
        3833,
        7023432,
        3915,
        7023562,
        3628,
        7023964,
        3618,
        7024012,
        3533,
        7024292,
        3935,
        7024388,
        3950,
        7024680,
        4021,
        7024816,
        3766,
        7025132,
        3768,
        7025254,
        3788,
        7025498,
        3924,
        7025648,
        3984,
        7025928,
        3824,
        7026104,
        3965,
        7026470,
        3705,
        7026676,
        3733,
        7026872,
        3731,
        7026948,
        4008,
        7027216,
        4011,
        7027472,
        3985,
        7027670,
        3910,
        7027816,
        3558,
        7028064,
        3621,
        7028344,
        3663,
        7028580,
        3921,
        7028704,
        4230,
        7028960,
        4335,
        7029020,
        4208,
        7029310,
        4094,
        7029440,
        4137,
        7029834,
        4593,
        7029978,
        5021,
        7030074,
        5476,
        7030320,
        5950,
        7030532,
        5845,
        7030704,
        5494,
        7030868,
        5621,
        7031162,
        5648,
        7031406,
        5772,
        7031512,
        6358,
        7031744,
        6440,
        7031968,
        6163,
        7032146,
        6156,
        7032446,
        5979,
        7032668,
        5916,
        7033008,
        6378,
        7033136,
        6461,
        7033264,
        6499,
        7033472,
        6288,
        7033664,
        6186,
        7033874,
        6436,
        7034316,
        6938,
        7034368,
        7633,
        7034500,
        6989,
        7034768,
        6946,
        7034896,
        6690,
        7034982,
        6685,
        7035294,
        6867,
        7035404,
        7046,
        7035548,
        7331,
        7035718,
        7603,
        7035904,
        7774,
        7036000,
        8061,
        7036124,
        8444,
        7036264,
        8351,
        7036436,
        8237,
        7036916,
        7897,
        7037172,
        7749,
        7037368,
        7915,
        7037488,
        7665,
        7037636,
        7841,
        7037792,
        7764,
        7038000,
        7498,
        7038284,
        7829,
        7038508,
        7818,
        7038696,
        8389,
        7038814,
        8159,
        7038924,
        8971,
        7039206,
        8728,
        7039342,
        8568,
        7039428,
        8216,
        7039800,
        8757,
        7039904,
        8913,
        7040128,
        9682,
        7040208,
        9338,
        7040354,
        8884,
        7040540,
        8891,
        7040572,
        8932,
        7040734,
        8683,
        7040840,
        8357,
        7040984,
        8413,
        7041176,
        8600,
        7041376,
        8760,
        7041724,
        10078,
        7042014,
        9786,
        7042172,
        9561,
        7042268,
        9335,
        7042308,
        9420,
        7042880,
        10030,
        7042982,
        10290,
        7043102,
        10723,
        7043468,
        10075,
        7043618,
        8910,
        7043830,
        8955,
        7043956,
        9051,
        7044248,
        9487,
        7044476,
        10167,
        7044600,
        10750,
        7044760,
        10602,
        7045104,
        9913,
        7045206,
        9455,
        7045588,
        9804,
        7045720,
        10353,
        7045880,
        10657,
        7046070,
        10480,
        7046236,
        9659,
        7046536,
        10050,
        7046700,
        9538,
        7046856,
        9286,
        7047122,
        9954,
        7047282,
        9885,
        7047572,
        9543,
        7047734,
        9336,
        7048182,
        8515,
        7048292,
        8686,
        7048532,
        9439,
        7048706,
        9534,
        7048876,
        10271,
        7049148,
        9756,
        7049452,
        9303,
        7049668,
        8966,
        7049916,
        8474,
        7050284,
        8333,
        7050516,
        7571,
        7050760,
        7516,
        7050902,
        7200,
        7051396,
        7371,
        7051656,
        8065,
        7051744,
        8701,
        7052000,
        7933,
        7052152,
        7221,
        7052614,
        7145,
        7052828,
        7169,
        7053036,
        7301,
        7053176,
        7414,
        7053412,
        7781,
        7053570,
        7328,
        7053800,
        6981,
        7054120,
        6876,
        7054280,
        7199,
        7054400,
        7760,
        7054562,
        7642,
        7054818,
        8438,
        7055026,
        8132,
        7055346,
        7505,
        7055558,
        7407,
        7055844,
        7686,
        7055868,
        7867,
        7056096,
        8049,
        7056336,
        8429,
        7056472,
        8202,
        7056712,
        8161,
        7056884,
        8103,
        7057108,
        8185,
        7057348,
        8994,
        7057556,
        8939,
        7057712,
        9254,
        7058152,
        8761,
        7058324,
        8916,
        7058496,
        8546,
        7058754,
        9329,
        7058944,
        9825,
        7059272,
        8985,
        7059328,
        8796,
        7059586,
        7972,
        7059712,
        8198,
        7060004,
        8794,
        7060162,
        9664,
        7060292,
        10199,
        7060784,
        9409,
        7060968,
        8466,
        7061292,
        8664,
        7061380,
        8396,
        7061612,
        9469,
        7061794,
        9319,
        7062068,
        10158,
        7062230,
        9908,
        7062426,
        9356,
        7062756,
        9443,
        7063008,
        9912,
        7063136,
        9660,
        7063346,
        9688,
        7063556,
        9793,
        7063760,
        8514,
        7063994,
        8378,
        7064222,
        8328,
        7064470,
        7327,
        7064684,
        7750,
        7064970,
        7520,
        7065268,
        6586,
        7065638,
        6818,
        7065700,
        6708,
        7065900,
        6762,
        7066120,
        7014,
        7066322,
        6713,
        7066472,
        6802,
        7066780,
        6475,
        7066976,
        6218,
        7067136,
        6444,
        7067428,
        6848,
        7067604,
        6455,
        7067774,
        5834,
        7068112,
        3070,
        7068168,
        3341,
        7068380,
        3589,
        7068712,
        3839,
        7068832,
        4245,
        7069072,
        4513,
        7069212,
        4580,
        7069502,
        4165,
        7069660,
        4065,
        7069860,
        4134,
        7070138,
        4386,
        7070274,
        4539,
        7070494,
        4823,
        7070746,
        4896
    ],
    [
        6649996,
        8999,
        6970276,
        -1,
        6983094,
        8999,
        7044476,
        -1,
        7051744,
        8999,
        7059776,
        -1
    ],
    null,
    null,
    [
        6707692,
        8899,
        0,
        6708024,
        -1,
        -1,
        7033284,
        3499,
        0,
        7034318,
        -1,
        -1
    ],
    null,
    [
        6694896,
        2333,
        6694980,
        -1,
        6722534,
        2581,
        6722904,
        -1,
        6739562,
        2639,
        6740048,
        -1,
        6743046,
        2639,
        6744602,
        -1,
        6756286,
        2697,
        6756456,
        -1,
        6762258,
        2697,
        6762536,
        -1,
        6772416,
        2697,
        6773312,
        -1,
        6773484,
        2697,
        6774224,
        -1,
        6781964,
        3444,
        6782528,
        -1,
        6788648,
        3621,
        6789468,
        -1,
        6790868,
        3512,
        6791092,
        -1,
        6792052,
        3648,
        6792896,
        -1,
        6793100,
        3716,
        6793268,
        -1,
        6793992,
        3716,
        6804716,
        3693,
        6816508,
        3625,
        6828868,
        3820,
        6830552,
        3903,
        6842684,
        3786,
        6845622,
        3868,
        6857248,
        3751,
        6865044,
        3637,
        6869472,
        3524,
        6880660,
        3411,
        6893232,
        3298,
        6905820,
        3184,
        6909704,
        3264,
        6913378,
        3344,
        6914308,
        3448,
        6914620,
        3344,
        6915696,
        3423,
        6916066,
        3530,
        6920248,
        3423,
        6926542,
        3530,
        6926740,
        3423,
        6927628,
        3355,
        6939112,
        3390,
        6943438,
        3287,
        6944940,
        3367,
        6949532,
        3472,
        6951686,
        3367,
        6952776,
        3472,
        6955028,
        3367,
        6956392,
        3299,
        6959890,
        3402,
        6960740,
        3299,
        6964988,
        3402,
        6967340,
        3495,
        6971790,
        3563,
        6981900,
        3645,
        6986974,
        3727,
        6989238,
        3614,
        6990830,
        3694,
        6991540,
        3810,
        6994360,
        3694,
        6998044,
        3810,
        7000304,
        3892,
        7005660,
        3774,
        7006100,
        3892,
        7011716,
        3821,
        7019576,
        3563,
        7022780,
        3455,
        7031512,
        3563,
        7031782,
        3387,
        7032668,
        3493,
        7033284,
        3464,
        7034768,
        3821,
        7041534,
        3904,
        7045880,
        3785,
        7050760,
        3454,
        7053112,
        3562,
        7053216,
        3454,
        7053300,
        3562,
        7053932,
        3454,
        7054562,
        3562,
        7055306,
        3454,
        7057594,
        3359,
        7069408,
        3291
    ],
    [
        6650020,
        4699,
        6656156,
        3999,
        6675776,
        -1,
        6681376,
        8999,
        6681772,
        -1,
        6700508,
        3999,
        6700572,
        -1,
        6706212,
        8999,
        6708024,
        -1,
        6711344,
        3999,
        6712008,
        -1,
        6712360,
        8999,
        6712390,
        -1,
        6716758,
        4699,
        6718484,
        4799,
        6720942,
        3999,
        6722904,
        3599,
        6723242,
        3999,
        6731604,
        3599,
        6731972,
        3999,
        6736854,
        2998,
        6739562,
        2997,
        6765200,
        3999,
        6767298,
        2997,
        6777142,
        4899,
        6777252,
        4997,
        6784436,
        5597,
        6785952,
        3999,
        6877930,
        3599,
        6908172,
        3999,
        6918534,
        3599,
        6958802,
        3999,
        6968608,
        3599,
        6977140,
        3999,
        6978312,
        3599,
        6978572,
        3999,
        7019008,
        3599,
        7029046,
        3999,
        7049452,
        3598,
        7057594,
        3499
    ],
    [
        6515144,
        1,
        6515902,
        -1,
        6649996,
        2,
        6654572,
        1,
        6654796,
        2,
        6654880,
        1,
        6654956,
        2,
        6657272,
        3,
        6658344,
        2,
        6660018,
        3,
        6663796,
        2,
        6665032,
        1,
        6666090,
        2,
        6666548,
        1,
        6666626,
        2,
        6666698,
        1,
        6666820,
        2,
        6671344,
        3,
        6673696,
        2,
        6674570,
        3,
        6675608,
        2,
        6675654,
        1,
        6675776,
        -1,
        6681272,
        1,
        6681772,
        -1,
        6686808,
        2,
        6686868,
        1,
        6687402,
        -1,
        6689188,
        2,
        6689220,
        -1,
        6696544,
        1,
        6696620,
        2,
        6696722,
        -1,
        6697512,
        1,
        6697580,
        -1,
        6700508,
        1,
        6700572,
        -1,
        6706212,
        1,
        6706928,
        2,
        6707196,
        1,
        6707504,
        2,
        6707728,
        1,
        6708024,
        -1,
        6711344,
        1,
        6712008,
        -1,
        6712360,
        1,
        6712390,
        -1,
        6716758,
        2,
        6718408,
        3,
        6720982,
        2,
        6726676,
        3,
        6726828,
        2,
        6730000,
        3,
        6733012,
        4,
        6734222,
        5,
        6736436,
        6,
        6736482,
        7,
        6743920,
        8,
        6765200,
        7,
        6766578,
        8,
        6767898,
        9,
        6777146,
        7,
        6777208,
        3,
        6777536,
        4,
        6778380,
        6,
        6778450,
        4,
        6779738,
        3,
        6779920,
        2,
        6780112,
        3,
        6781088,
        2,
        6783544,
        3,
        6784248,
        2,
        6784464,
        3,
        6785832,
        5,
        6785952,
        4,
        6791208,
        2,
        6810760,
        3,
        6811000,
        2,
        6811332,
        3,
        6811406,
        2,
        6812020,
        3,
        6812760,
        2,
        6821456,
        3,
        6823016,
        4,
        6823432,
        5,
        6835340,
        6,
        6837920,
        7,
        6838788,
        6,
        6840148,
        7,
        6841700,
        8,
        6850848,
        7,
        6851170,
        8,
        6857630,
        10,
        6857886,
        9,
        6857948,
        8,
        6867548,
        9,
        6872096,
        10,
        6874158,
        9,
        6880352,
        10,
        6901176,
        11,
        6901376,
        12,
        6935198,
        14,
        6959016,
        12,
        6961124,
        13,
        6961680,
        12,
        6962034,
        11,
        6963052,
        12,
        6969280,
        11,
        6970852,
        12,
        6974040,
        11,
        6976984,
        10,
        6977338,
        11,
        6977572,
        10,
        6977914,
        11,
        6978316,
        12,
        6978328,
        11,
        6978424,
        12,
        6978456,
        11,
        6978472,
        12,
        6978552,
        11,
        6978572,
        10,
        6979136,
        11,
        6979248,
        10,
        6979576,
        11,
        6979656,
        10,
        6980050,
        11,
        6981300,
        10,
        6981822,
        11,
        6981900,
        12,
        6982072,
        9,
        6982204,
        8,
        6982484,
        9,
        6982590,
        8,
        6983124,
        7,
        6983966,
        9,
        6984024,
        10,
        6984284,
        9,
        6984996,
        8,
        6985396,
        7,
        6986140,
        8,
        6989596,
        9,
        6989720,
        8,
        6990334,
        9,
        6991932,
        10,
        6992252,
        9,
        6993936,
        10,
        6994126,
        9,
        6994656,
        10,
        6996636,
        9,
        6997628,
        10,
        6998544,
        11,
        6998640,
        10,
        7001376,
        9,
        7003712,
        10,
        7004172,
        9,
        7004232,
        8,
        7004292,
        7,
        7004658,
        8,
        7004932,
        7,
        7005096,
        8,
        7005316,
        7,
        7005652,
        8,
        7005816,
        7,
        7006286,
        9,
        7006432,
        8,
        7006548,
        6,
        7006760,
        7,
        7007904,
        8,
        7008152,
        7,
        7008292,
        6,
        7008524,
        7,
        7009152,
        6,
        7010412,
        5,
        7011272,
        6,
        7011352,
        5,
        7011444,
        6,
        7011584,
        7,
        7011654,
        6,
        7012672,
        7,
        7013280,
        8,
        7017128,
        9,
        7017460,
        8,
        7018012,
        9,
        7019368,
        8,
        7025648,
        7,
        7025928,
        6,
        7026676,
        7,
        7026948,
        8,
        7027368,
        7,
        7033008,
        8,
        7033136,
        9,
        7033856,
        10,
        7034124,
        9,
        7036746,
        10,
        7037172,
        9,
        7038508,
        8,
        7040128,
        9,
        7042880,
        10,
        7042982,
        9,
        7043160,
        8,
        7044532,
        9,
        7044600,
        8,
        7048292,
        9,
        7049036,
        8,
        7051396,
        7,
        7052828,
        8,
        7053544,
        9,
        7064970,
        8,
        7065950,
        9,
        7067692,
        8,
        7068314,
        9,
        7069150,
        8,
        7069502,
        9
    ],
    [
        6694896,
        1,
        6694980,
        -1,
        6722534,
        1,
        6722946,
        -1,
        6724480,
        1,
        6724666,
        -1,
        6739440,
        1,
        6740048,
        -1,
        6743270,
        1,
        6744602,
        -1,
        6756286,
        1,
        6756456,
        -1,
        6762258,
        1,
        6762640,
        -1,
        6772296,
        1,
        6773312,
        -1,
        6773596,
        1,
        6774224,
        -1,
        6781812,
        1,
        6782528,
        -1,
        6788648,
        1,
        6789468,
        -1,
        6790736,
        1,
        6791092,
        -1,
        6791644,
        1,
        6791720,
        -1,
        6791838,
        1,
        6792896,
        -1,
        6793100,
        1,
        6793268,
        -1,
        6793992,
        1,
        6803680,
        2,
        6804640,
        3,
        6805840,
        4,
        6813072,
        3,
        6813120,
        4,
        6813590,
        5,
        6817340,
        4,
        6826424,
        5,
        6826620,
        6,
        6828868,
        5,
        6831156,
        4,
        6836838,
        5,
        6843088,
        4,
        6852914,
        5,
        6857080,
        6,
        6864894,
        7,
        6878950,
        6,
        6878966,
        7,
        6914260,
        6,
        6914620,
        7,
        6915848,
        6,
        6920060,
        7,
        6939100,
        6,
        6943436,
        7,
        6949452,
        6,
        6951686,
        7,
        6952748,
        6,
        6955028,
        7,
        6959890,
        6,
        6960642,
        7,
        6964828,
        6,
        6966968,
        5,
        6984088,
        4,
        6989208,
        5,
        6991524,
        4,
        6994176,
        5,
        6998088,
        4,
        7005620,
        5,
        7005892,
        4,
        7022666,
        5,
        7032446,
        4,
        7040128,
        5,
        7045880,
        6
    ],
    null,
    null,
    [
        6650020,
        2,
        6650676,
        2,
        6652012,
        2,
        6653572,
        2,
        6653858,
        2,
        6654880,
        2,
        6656156,
        2,
        6656222,
        2,
        6656300,
        2,
        6656688,
        2,
        6657464,
        3,
        6658148,
        3,
        6659032,
        2,
        6659260,
        2,
        6659876,
        2,
        6660080,
        3,
        6660436,
        3,
        6660620,
        3,
        6661656,
        3,
        6661760,
        3,
        6661762,
        3,
        6661826,
        3,
        6662076,
        3,
        6662526,
        3,
        6663028,
        3,
        6663372,
        3,
        6663796,
        2,
        6663900,
        2,
        6665876,
        1,
        6666032,
        1,
        6666100,
        2,
        6666296,
        2,
        6666340,
        2,
        6666476,
        2,
        6666956,
        2,
        6667408,
        2,
        6667472,
        2,
        6667568,
        2,
        6667742,
        2,
        6667948,
        2,
        6667992,
        2,
        6667994,
        2,
        6668376,
        2,
        6668708,
        2,
        6668842,
        2,
        6668918,
        2,
        6669154,
        2,
        6670204,
        2,
        6670492,
        2,
        6670744,
        2,
        6671344,
        3,
        6672228,
        3,
        6672366,
        3,
        6672620,
        3,
        6673028,
        3,
        6673196,
        3,
        6673762,
        2,
        6674194,
        2,
        6674802,
        3,
        6675250,
        3,
        6675440,
        3,
        6675572,
        3,
        6681376,
        1,
        6700508,
        1,
        6706212,
        1,
        6706582,
        1,
        6706904,
        1,
        6707196,
        1,
        6707316,
        1,
        6707692,
        2,
        6708780,
        0,
        6709812,
        0,
        6711344,
        1,
        6711764,
        1,
        6712360,
        1,
        6715672,
        0,
        6716574,
        0,
        6716758,
        2,
        6717158,
        2,
        6717466,
        2,
        6717714,
        2,
        6717932,
        2,
        6718224,
        2,
        6718484,
        3,
        6718762,
        3,
        6719078,
        3,
        6719460,
        3,
        6719550,
        3,
        6719932,
        3,
        6720204,
        3,
        6720484,
        3,
        6720808,
        3,
        6720942,
        3,
        6721060,
        2,
        6721140,
        2,
        6721284,
        2,
        6721700,
        2,
        6721980,
        2,
        6722074,
        2,
        6722374,
        2,
        6722534,
        3,
        6722904,
        -2,
        6723242,
        2,
        6723526,
        2,
        6723760,
        2,
        6724032,
        2,
        6724312,
        2,
        6724398,
        2,
        6724400,
        2,
        6724682,
        2,
        6724940,
        2,
        6725200,
        2,
        6725536,
        2,
        6725832,
        2,
        6726116,
        2,
        6726368,
        2,
        6726644,
        2,
        6726730,
        3,
        6727152,
        2,
        6727460,
        2,
        6727742,
        2,
        6728032,
        2,
        6728360,
        2,
        6728760,
        2,
        6729132,
        2,
        6729520,
        2,
        6729788,
        2,
        6730062,
        3,
        6730348,
        3,
        6730628,
        3,
        6730724,
        3,
        6730960,
        3,
        6731072,
        3,
        6731312,
        3,
        6731604,
        3,
        6731972,
        3,
        6732104,
        3,
        6732268,
        3,
        6732592,
        3,
        6732860,
        3,
        6733114,
        4,
        6733364,
        4,
        6733660,
        4,
        6733840,
        4,
        6734010,
        4,
        6734384,
        5,
        6734756,
        5,
        6734976,
        5,
        6735280,
        5,
        6735548,
        5,
        6735724,
        5,
        6736094,
        5,
        6736336,
        5,
        6736536,
        -6,
        6736854,
        -6,
        6736856,
        -6,
        6737020,
        -6,
        6737284,
        -6,
        6737544,
        -6,
        6737864,
        -6,
        6738160,
        -6,
        6738454,
        -6,
        6738596,
        -6,
        6738884,
        -6,
        6739204,
        -6,
        6739562,
        -7,
        6739726,
        -7,
        6740048,
        -6,
        6740292,
        -6,
        6740616,
        -6,
        6740864,
        -6,
        6741040,
        -6,
        6741128,
        -6,
        6741196,
        -6,
        6741336,
        -6,
        6741416,
        -6,
        6742036,
        -6,
        6742320,
        -6,
        6742480,
        -6,
        6742748,
        -6,
        6743046,
        7,
        6743270,
        -7,
        6743552,
        -7,
        6743812,
        -7,
        6743920,
        -8,
        6744222,
        -8,
        6744468,
        -8,
        6744858,
        -7,
        6745112,
        -7,
        6745274,
        -7,
        6745360,
        -7,
        6745736,
        -7,
        6746040,
        -7,
        6746356,
        -7,
        6746640,
        -7,
        6746760,
        -7,
        6746844,
        8,
        6746952,
        8,
        6747014,
        8,
        6747360,
        8,
        6747384,
        8,
        6747816,
        8,
        6748116,
        8,
        6748240,
        8,
        6748384,
        8,
        6748602,
        8,
        6748664,
        8,
        6748792,
        8,
        6749076,
        8,
        6749502,
        8,
        6749680,
        8,
        6749842,
        8,
        6749908,
        8,
        6750022,
        8,
        6750356,
        8,
        6750620,
        8,
        6750882,
        8,
        6750948,
        8,
        6751120,
        8,
        6751236,
        8,
        6751332,
        8,
        6751468,
        8,
        6751640,
        8,
        6751952,
        8,
        6752224,
        8,
        6752536,
        8,
        6752810,
        8,
        6753070,
        8,
        6753280,
        8,
        6753284,
        8,
        6753532,
        8,
        6753708,
        8,
        6753722,
        8,
        6753970,
        8,
        6754074,
        8,
        6754192,
        8,
        6754472,
        8,
        6754532,
        8,
        6754678,
        8,
        6755000,
        8,
        6755304,
        8,
        6755468,
        8,
        6755580,
        8,
        6755660,
        8,
        6755736,
        8,
        6755928,
        8,
        6756286,
        9,
        6756582,
        8,
        6756684,
        8,
        6756830,
        8,
        6756916,
        8,
        6756988,
        -7,
        6757092,
        -7,
        6757292,
        -7,
        6757536,
        -7,
        6757706,
        -7,
        6758000,
        -7,
        6758260,
        -7,
        6758508,
        -7,
        6758602,
        -7,
        6758662,
        -7,
        6758900,
        -7,
        6758936,
        -7,
        6758938,
        -7,
        6758940,
        -7,
        6759190,
        -7,
        6759330,
        -7,
        6759578,
        -7,
        6759760,
        -7,
        6759816,
        -7,
        6760128,
        -7,
        6760396,
        -7,
        6760620,
        -7,
        6760912,
        -7,
        6761024,
        -7,
        6761124,
        -7,
        6761380,
        -7,
        6761660,
        -7,
        6761936,
        -7,
        6762258,
        -8,
        6762536,
        -7,
        6762670,
        -7,
        6762972,
        -7,
        6763296,
        -7,
        6763300,
        -7,
        6763752,
        -7,
        6763910,
        -7,
        6764080,
        -7,
        6764348,
        -7,
        6764642,
        8,
        6764908,
        8,
        6765200,
        7,
        6765456,
        7,
        6765736,
        7,
        6766000,
        7,
        6766356,
        7,
        6766692,
        8,
        6766942,
        8,
        6767020,
        8,
        6767298,
        8,
        6767572,
        8,
        6767898,
        9,
        6768174,
        9,
        6768312,
        9,
        6768400,
        9,
        6768458,
        9,
        6768728,
        9,
        6768730,
        9,
        6768906,
        9,
        6768920,
        9,
        6768924,
        9,
        6769192,
        9,
        6769434,
        9,
        6769688,
        9,
        6769810,
        9,
        6769918,
        9,
        6770160,
        9,
        6770514,
        9,
        6770872,
        9,
        6771114,
        9,
        6771250,
        9,
        6771420,
        9,
        6771492,
        9,
        6771552,
        9,
        6771844,
        9,
        6772164,
        9,
        6772416,
        10,
        6772672,
        10,
        6772690,
        10,
        6772988,
        10,
        6773084,
        10,
        6773184,
        10,
        6773484,
        10,
        6773674,
        10,
        6773918,
        10,
        6774130,
        10,
        6774426,
        9,
        6774536,
        9,
        6774804,
        9,
        6775112,
        9,
        6775440,
        9,
        6775552,
        9,
        6775824,
        9,
        6775878,
        9,
        6775884,
        9,
        6776136,
        9,
        6776384,
        9,
        6776648,
        9,
        6776900,
        9,
        6777142,
        -7,
        6777252,
        3,
        6777512,
        4,
        6777544,
        -3,
        6777556,
        -3,
        6777700,
        -3,
        6777776,
        -3,
        6778092,
        -3,
        6778254,
        -3,
        6778380,
        -4,
        6778570,
        -3,
        6778686,
        -3,
        6778806,
        -3,
        6779048,
        -3,
        6779340,
        -3,
        6779606,
        -3,
        6779920,
        2,
        6780198,
        3,
        6780452,
        3,
        6780700,
        3,
        6780984,
        3,
        6781124,
        2,
        6781270,
        2,
        6781364,
        2,
        6781616,
        2,
        6781670,
        2,
        6781964,
        3,
        6782378,
        3,
        6782574,
        2,
        6782800,
        2,
        6783092,
        2,
        6783352,
        2,
        6783544,
        2,
        6783840,
        3,
        6784194,
        3,
        6784436,
        3,
        6784470,
        3,
        6784748,
        3,
        6785002,
        3,
        6785272,
        3,
        6785400,
        3,
        6785580,
        3,
        6785650,
        3,
        6785952,
        4,
        6786288,
        4,
        6786534,
        4,
        6786780,
        4,
        6787024,
        4,
        6787268,
        4,
        6787372,
        4,
        6787680,
        4,
        6787944,
        4,
        6788200,
        4,
        6788312,
        4,
        6788560,
        4,
        6788648,
        5,
        6788912,
        5,
        6789218,
        5,
        6789468,
        4,
        6789724,
        4,
        6789970,
        4,
        6790152,
        4,
        6790406,
        4,
        6790460,
        4,
        6790564,
        4,
        6790868,
        5,
        6791166,
        4,
        6791416,
        2,
        6791720,
        2,
        6792052,
        3,
        6792444,
        3,
        6792652,
        3,
        6792824,
        3,
        6793004,
        2,
        6793100,
        3,
        6793432,
        2,
        6793680,
        2,
        6793992,
        3,
        6794290,
        3,
        6794608,
        3,
        6794894,
        3,
        6795182,
        3,
        6795456,
        3,
        6795792,
        3,
        6796078,
        3,
        6796210,
        3,
        6796460,
        3,
        6796712,
        3,
        6796954,
        3,
        6797196,
        3,
        6797440,
        3,
        6797692,
        3,
        6797976,
        3,
        6798248,
        3,
        6798566,
        3,
        6798640,
        3,
        6798716,
        3,
        6798968,
        3,
        6799238,
        3,
        6799496,
        3,
        6799752,
        3,
        6800012,
        3,
        6800268,
        3,
        6800524,
        3,
        6800788,
        3,
        6801160,
        3,
        6801340,
        3,
        6801454,
        3,
        6801532,
        3,
        6801748,
        3,
        6802002,
        3,
        6802396,
        3,
        6802648,
        3,
        6802768,
        3,
        6802930,
        3,
        6803184,
        3,
        6803484,
        3,
        6803856,
        4,
        6804104,
        4,
        6804420,
        4,
        6804716,
        -4,
        6805040,
        -4,
        6805044,
        -4,
        6805346,
        -4,
        6805610,
        -4,
        6805840,
        -4,
        6806084,
        -4,
        6806352,
        -4,
        6806748,
        -4,
        6807056,
        -4,
        6807340,
        -4,
        6807430,
        -4,
        6807696,
        -4,
        6808102,
        -4,
        6808346,
        -4,
        6808608,
        -4,
        6808900,
        -4,
        6809142,
        -4,
        6809454,
        -4,
        6809696,
        -4,
        6809940,
        -4,
        6810206,
        -4,
        6810448,
        -4,
        6810722,
        -4,
        6811000,
        -4,
        6811254,
        -4,
        6811406,
        -4,
        6811584,
        -4,
        6811832,
        -4,
        6812136,
        -5,
        6812454,
        -5,
        6812696,
        -5,
        6812794,
        -4,
        6812856,
        -4,
        6813040,
        -4,
        6813120,
        -4,
        6813258,
        -4,
        6813592,
        -4,
        6813880,
        -4,
        6814124,
        -4,
        6814250,
        -4,
        6814416,
        -4,
        6814510,
        -4,
        6814824,
        -4,
        6815128,
        -4,
        6815192,
        -4,
        6815288,
        -4,
        6815544,
        -4,
        6815664,
        -4,
        6815892,
        -4,
        6816240,
        -4,
        6816508,
        -4,
        6816832,
        -4,
        6817048,
        -4,
        6817324,
        -4,
        6817636,
        -4,
        6817920,
        -4,
        6818198,
        -4,
        6818508,
        -4,
        6818792,
        -4,
        6818980,
        -4,
        6819232,
        -4,
        6819564,
        -4,
        6819940,
        -4,
        6820208,
        -4,
        6820420,
        -4,
        6820662,
        -4,
        6821010,
        -4,
        6821326,
        -4,
        6821578,
        -5,
        6821740,
        -5,
        6822048,
        -5,
        6822380,
        -5,
        6822636,
        -5,
        6823004,
        -6,
        6823212,
        -6,
        6823492,
        -7,
        6823792,
        -7,
        6824134,
        -7,
        6824428,
        -6,
        6824560,
        -6,
        6824804,
        -7,
        6825104,
        -7,
        6825380,
        -7,
        6825656,
        -6,
        6825896,
        -7,
        6826240,
        -7,
        6826516,
        -7,
        6826816,
        -7,
        6827268,
        -6,
        6827596,
        -7,
        6827856,
        -7,
        6828110,
        -6,
        6828496,
        -7,
        6828868,
        -6,
        6829060,
        -5,
        6829308,
        -6,
        6829428,
        -5,
        6829764,
        -6,
        6829932,
        -6,
        6830192,
        -6,
        6830290,
        -6,
        6830552,
        -6,
        6830800,
        -6,
        6831096,
        -5,
        6831358,
        -6,
        6831732,
        -6,
        6831984,
        -6,
        6832244,
        -6,
        6832500,
        -6,
        6832744,
        -6,
        6832956,
        -5,
        6833172,
        -6,
        6833502,
        -6,
        6833778,
        -6,
        6834072,
        -6,
        6834316,
        -6,
        6834576,
        -6,
        6834868,
        -6,
        6835130,
        -6,
        6835340,
        -6,
        6835352,
        -6,
        6835676,
        -5,
        6835926,
        -5,
        6836284,
        -5,
        6836590,
        -6,
        6836838,
        -6,
        6837152,
        -6,
        6837600,
        -6,
        6837608,
        -6,
        6837612,
        -6,
        6837892,
        -7,
        6838334,
        -7,
        6838628,
        -7,
        6838912,
        -6,
        6839050,
        -6,
        6839294,
        -6,
        6839480,
        -6,
        6839720,
        -6,
        6839968,
        -6,
        6840084,
        -6,
        6840200,
        -7,
        6840506,
        -7,
        6840774,
        -7,
        6841136,
        -7,
        6841392,
        -7,
        6841700,
        -8,
        6841772,
        -8,
        6842072,
        -8,
        6842320,
        -7,
        6842684,
        -8,
        6842920,
        -8,
        6843170,
        -8,
        6843280,
        -8,
        6843544,
        -8,
        6843832,
        -8,
        6844200,
        -8,
        6844472,
        -8,
        6844676,
        -8,
        6844832,
        -8,
        6845080,
        -8,
        6845622,
        -8,
        6845864,
        -8,
        6846130,
        -8,
        6846436,
        -8,
        6846712,
        -8,
        6847028,
        -8,
        6847298,
        -8,
        6847548,
        -8,
        6847630,
        -8,
        6847792,
        -8,
        6847856,
        -7,
        6848176,
        -8,
        6848472,
        -8,
        6848732,
        -8,
        6848856,
        -8,
        6849170,
        -8,
        6849452,
        -8,
        6849764,
        -8,
        6850108,
        -8,
        6850356,
        -8,
        6850392,
        -8,
        6850648,
        -8,
        6850890,
        -7,
        6850916,
        -7,
        6851170,
        -8,
        6851446,
        -7,
        6851672,
        -8,
        6851674,
        -8,
        6851890,
        -8,
        6852214,
        -8,
        6852524,
        -7,
        6852914,
        -8,
        6853040,
        -8,
        6853290,
        -8,
        6853556,
        -7,
        6853912,
        -8,
        6853966,
        -7,
        6853970,
        -8,
        6854234,
        -8,
        6854476,
        -8,
        6854752,
        -8,
        6854876,
        -8,
        6854948,
        -8,
        6855198,
        -8,
        6855486,
        -8,
        6855766,
        -8,
        6856070,
        -8,
        6856210,
        -8,
        6856484,
        -7,
        6856612,
        -8,
        6856912,
        -8,
        6857248,
        -7,
        6857586,
        -10,
        6857710,
        -10,
        6857980,
        -8,
        6858292,
        -8,
        6858788,
        -7,
        6859032,
        -8,
        6859096,
        -8,
        6859442,
        -8,
        6859752,
        -7,
        6860182,
        -8,
        6860532,
        -7,
        6860808,
        -7,
        6861068,
        -8,
        6861342,
        -8,
        6861666,
        -8,
        6861970,
        -8,
        6862308,
        -8,
        6862464,
        -7,
        6862466,
        -8,
        6862700,
        -8,
        6862970,
        -8,
        6863216,
        -8,
        6863322,
        -8,
        6863412,
        -8,
        6863594,
        -8,
        6863676,
        -7,
        6863840,
        -8,
        6864094,
        -8,
        6864348,
        -8,
        6864628,
        -8,
        6864800,
        -8,
        6865044,
        -9,
        6865290,
        -9,
        6865536,
        -9,
        6865896,
        -9,
        6866172,
        -8,
        6866290,
        -8,
        6866548,
        -9,
        6866864,
        -9,
        6867160,
        -9,
        6867440,
        -8,
        6867700,
        -9,
        6867940,
        -10,
        6868084,
        -9,
        6868350,
        -10,
        6868716,
        -10,
        6868960,
        -10,
        6869172,
        -10,
        6869180,
        -10,
        6869472,
        -10,
        6869724,
        -10,
        6869998,
        -10,
        6870266,
        -10,
        6870330,
        -10,
        6870556,
        -10,
        6870842,
        -10,
        6870938,
        -10,
        6871284,
        -10,
        6871536,
        -10,
        6871812,
        -10,
        6872080,
        -10,
        6872160,
        -11,
        6872324,
        -11,
        6872332,
        -11,
        6872652,
        -11,
        6872990,
        -11,
        6873358,
        -11,
        6873490,
        -11,
        6873674,
        -11,
        6874010,
        -11,
        6874404,
        -10,
        6874728,
        -10,
        6874932,
        -10,
        6875288,
        -10,
        6875558,
        -10,
        6875974,
        -10,
        6876132,
        -10,
        6876308,
        -10,
        6876580,
        -10,
        6876842,
        -10,
        6876980,
        -10,
        6877298,
        -10,
        6877382,
        -10,
        6877712,
        -10,
        6877930,
        -10,
        6878182,
        -10,
        6878360,
        -10,
        6878616,
        -10,
        6878920,
        -10,
        6879252,
        -10,
        6879556,
        -10,
        6879810,
        -10,
        6880058,
        -10,
        6880354,
        -11,
        6880660,
        -11,
        6881004,
        -11,
        6881280,
        -11,
        6881664,
        -11,
        6881930,
        -11,
        6882130,
        -11,
        6882540,
        -11,
        6882804,
        -11,
        6882936,
        -11,
        6883100,
        -11,
        6883350,
        -12,
        6883542,
        -11,
        6883934,
        -11,
        6884274,
        -12,
        6884536,
        -11,
        6884836,
        -11,
        6885012,
        -10,
        6885068,
        -11,
        6885394,
        -12,
        6885680,
        -11,
        6885944,
        -11,
        6886328,
        -11,
        6886480,
        -11,
        6886788,
        -11,
        6886956,
        -10,
        6887238,
        -11,
        6887408,
        -12,
        6887672,
        -12,
        6887872,
        -11,
        6888118,
        -12,
        6888366,
        -12,
        6888708,
        -12,
        6888950,
        -12,
        6889096,
        -12,
        6889276,
        -12,
        6889376,
        -12,
        6889648,
        -12,
        6890020,
        -12,
        6890272,
        -12,
        6890432,
        -12,
        6890680,
        -12,
        6890770,
        -12,
        6891124,
        -12,
        6891212,
        -11,
        6891276,
        -12,
        6891526,
        -12,
        6891890,
        -11,
        6892184,
        -12,
        6892472,
        -12,
        6892588,
        -12,
        6892896,
        -12,
        6893232,
        -12,
        6893480,
        -12,
        6893680,
        -12,
        6894016,
        -12,
        6894260,
        -12,
        6894612,
        -12,
        6894858,
        -12,
        6895210,
        -12,
        6895508,
        -12,
        6895780,
        -12,
        6896054,
        -12,
        6896298,
        -12,
        6896504,
        -12,
        6896652,
        -12,
        6896944,
        -12,
        6897256,
        -11,
        6897500,
        -12,
        6897760,
        -12,
        6898032,
        -12,
        6898268,
        -12,
        6898736,
        -12,
        6899108,
        -12,
        6899412,
        -12,
        6899734,
        -12,
        6900024,
        -12,
        6900314,
        -11,
        6900612,
        -12,
        6900820,
        -12,
        6901176,
        -13,
        6901424,
        -14,
        6901808,
        -14,
        6902094,
        -14,
        6902352,
        -14,
        6902616,
        -14,
        6902884,
        -14,
        6903272,
        -14,
        6903300,
        -13,
        6903632,
        -14,
        6903852,
        -14,
        6904040,
        -14,
        6904320,
        -14,
        6904572,
        -14,
        6904824,
        -14,
        6905272,
        -14,
        6905546,
        -14,
        6905820,
        -14,
        6906242,
        -14,
        6906588,
        -14,
        6906670,
        -14,
        6906736,
        -14,
        6907100,
        -14,
        6907372,
        -14,
        6907624,
        -14,
        6907788,
        -14,
        6907956,
        -14,
        6908020,
        -13,
        6908172,
        -13,
        6908440,
        -14,
        6908494,
        -14,
        6908856,
        -14,
        6909088,
        -13,
        6909460,
        -13,
        6909704,
        -14,
        6909960,
        -14,
        6910264,
        -13,
        6910352,
        -13,
        6910596,
        -13,
        6910888,
        -14,
        6910960,
        -13,
        6911122,
        -13,
        6911780,
        -14,
        6912022,
        -13,
        6912328,
        -13,
        6912734,
        -13,
        6913080,
        -12,
        6913378,
        -13,
        6913606,
        -13,
        6913780,
        -13,
        6914076,
        -13,
        6914308,
        -13,
        6914620,
        -13,
        6914868,
        -13,
        6915152,
        -14,
        6915252,
        -13,
        6915696,
        -13,
        6916066,
        -12,
        6916326,
        -12,
        6916640,
        -12,
        6916930,
        -13,
        6917276,
        -13,
        6917544,
        -12,
        6917650,
        -12,
        6918038,
        -12,
        6918132,
        -13,
        6918534,
        -13,
        6918644,
        -13,
        6918936,
        -13,
        6919002,
        -13,
        6919118,
        -13,
        6919296,
        -13,
        6919572,
        -13,
        6919672,
        -13,
        6919862,
        -13,
        6919940,
        -13,
        6920248,
        -14,
        6920504,
        -14,
        6920876,
        -14,
        6920986,
        -14,
        6921146,
        -14,
        6921412,
        -14,
        6921420,
        -14,
        6921504,
        -14,
        6921520,
        -14,
        6921648,
        -14,
        6921708,
        -14,
        6921824,
        -14,
        6922118,
        -14,
        6922392,
        -14,
        6922652,
        -14,
        6922936,
        -14,
        6923042,
        -14,
        6923304,
        -14,
        6923564,
        -14,
        6923812,
        -14,
        6923880,
        -14,
        6924000,
        -14,
        6924300,
        -14,
        6924604,
        -14,
        6924852,
        -14,
        6925104,
        -14,
        6925300,
        -14,
        6925548,
        -14,
        6925736,
        -14,
        6925826,
        -14,
        6926030,
        -14,
        6926300,
        -14,
        6926542,
        -13,
        6926740,
        -14,
        6927064,
        -14,
        6927332,
        -14,
        6927628,
        -14,
        6927876,
        -14,
        6928146,
        -14,
        6928296,
        -14,
        6928592,
        -14,
        6928940,
        -14,
        6929064,
        -14,
        6929344,
        -14,
        6929620,
        -14,
        6929880,
        -14,
        6930220,
        -14,
        6930482,
        -14,
        6930764,
        -14,
        6931016,
        -14,
        6931284,
        -14,
        6931576,
        -14,
        6931832,
        -14,
        6932288,
        -14,
        6932544,
        -14,
        6932840,
        -14,
        6933208,
        -14,
        6933572,
        -14,
        6933886,
        -14,
        6934134,
        -14,
        6934228,
        -14,
        6934526,
        -14,
        6934780,
        -14,
        6935188,
        -16,
        6935410,
        -16,
        6935776,
        -16,
        6936224,
        -16,
        6936508,
        -16,
        6936720,
        -16,
        6936850,
        -16,
        6937232,
        -16,
        6937392,
        -16,
        6937636,
        -16,
        6937994,
        -16,
        6938236,
        -16,
        6938602,
        -16,
        6938772,
        -16,
        6938922,
        -16,
        6939112,
        -15,
        6939516,
        -15,
        6939700,
        -15,
        6939896,
        -15,
        6940218,
        -15,
        6940480,
        -15,
        6940728,
        -15,
        6940952,
        -15,
        6941172,
        -15,
        6941272,
        -15,
        6941544,
        -15,
        6941786,
        -15,
        6941860,
        -15,
        6941916,
        -15,
        6942272,
        -15,
        6942336,
        -15,
        6942452,
        -15,
        6942826,
        -15,
        6943134,
        -15,
        6943438,
        -16,
        6943696,
        -16,
        6943892,
        -16,
        6944050,
        -16,
        6944380,
        -16,
        6944648,
        -16,
        6944940,
        -16,
        6945216,
        -16,
        6945460,
        -16,
        6945822,
        -16,
        6946046,
        -16,
        6946184,
        -16,
        6946426,
        -16,
        6946812,
        -16,
        6946902,
        -16,
        6946962,
        -16,
        6946972,
        -16,
        6947236,
        -16,
        6947512,
        -16,
        6947768,
        -16,
        6948040,
        -16,
        6948312,
        -16,
        6948518,
        -16,
        6948768,
        -16,
        6948868,
        -16,
        6949124,
        -16,
        6949276,
        -16,
        6949532,
        -15,
        6949656,
        -15,
        6949680,
        -15,
        6949780,
        -15,
        6950048,
        -15,
        6950282,
        -14,
        6950482,
        -15,
        6950760,
        -15,
        6951004,
        -15,
        6951250,
        -15,
        6951544,
        -15,
        6951686,
        -16,
        6951928,
        -16,
        6952204,
        -16,
        6952480,
        -16,
        6952776,
        -15,
        6953032,
        -15,
        6953376,
        -15,
        6953790,
        -15,
        6954038,
        -15,
        6954130,
        -15,
        6954376,
        -15,
        6954664,
        -15,
        6955028,
        -16,
        6955300,
        -16,
        6955570,
        -16,
        6955844,
        -16,
        6956236,
        -16,
        6956392,
        -16,
        6956648,
        -16,
        6956906,
        -16,
        6957012,
        -16,
        6957264,
        -16,
        6957586,
        -16,
        6957876,
        -16,
        6958136,
        -16,
        6958368,
        -16,
        6958450,
        -16,
        6958802,
        -16,
        6959142,
        -14,
        6959454,
        -14,
        6959648,
        -14,
        6959890,
        -13,
        6960144,
        -13,
        6960452,
        -13,
        6960740,
        -14,
        6960986,
        -14,
        6961124,
        -15,
        6961330,
        -15,
        6961646,
        -14,
        6961898,
        -14,
        6962034,
        -13,
        6962444,
        -13,
        6962770,
        -13,
        6963028,
        -14,
        6963204,
        -14,
        6963476,
        -14,
        6963754,
        -14,
        6963960,
        -14,
        6964210,
        -14,
        6964462,
        -14,
        6964704,
        -14,
        6964988,
        -13,
        6965280,
        -13,
        6965556,
        -13,
        6965650,
        -13,
        6965982,
        -13,
        6966284,
        -13,
        6966612,
        -13,
        6966866,
        -13,
        6967004,
        -13,
        6967090,
        -13,
        6967340,
        -13,
        6967760,
        -13,
        6968002,
        -13,
        6968252,
        -13,
        6968334,
        -13,
        6968608,
        -13,
        6968864,
        -13,
        6969064,
        -13,
        6969328,
        -12,
        6969516,
        -12,
        6969630,
        -12,
        6969876,
        -12,
        6969940,
        -12,
        6970040,
        -12,
        6970284,
        -12,
        6970612,
        -12,
        6970852,
        -13,
        6971096,
        -13,
        6971252,
        -13,
        6971380,
        -13,
        6971512,
        -13,
        6971790,
        -13,
        6972040,
        -13,
        6972316,
        -13,
        6972566,
        -13,
        6972810,
        -13,
        6973060,
        -13,
        6973308,
        -13,
        6973580,
        -13,
        6973852,
        -13,
        6974094,
        -12,
        6974290,
        -12,
        6974548,
        -12,
        6974814,
        -12,
        6975072,
        -12,
        6975316,
        -12,
        6975604,
        -12,
        6975696,
        -12,
        6976042,
        -12,
        6976252,
        -12,
        6976504,
        -12,
        6976770,
        -12,
        6976890,
        -12,
        6977140,
        -11,
        6977272,
        -11,
        6977516,
        -11,
        6977766,
        -11,
        6978312,
        -13,
        6978572,
        -11,
        6978820,
        -11,
        6979066,
        -12,
        6979388,
        -11,
        6979656,
        -11,
        6979866,
        -11,
        6980050,
        -12,
        6980112,
        -12,
        6980354,
        -12,
        6980472,
        -12,
        6980724,
        -12,
        6980972,
        -12,
        6981216,
        -12,
        6981356,
        -11,
        6981520,
        -11,
        6981784,
        -12,
        6981900,
        -13,
        6982168,
        -10,
        6982276,
        -9,
        6982524,
        -10,
        6982780,
        -9,
        6982930,
        -9,
        6983178,
        -8,
        6983438,
        -8,
        6983692,
        -9,
        6983966,
        -10,
        6984228,
        -10,
        6984370,
        -10,
        6984520,
        -10,
        6984774,
        -10,
        6985040,
        -9,
        6985396,
        -8,
        6985502,
        -8,
        6985810,
        -8,
        6985898,
        -8,
        6986140,
        -9,
        6986408,
        -9,
        6986718,
        -9,
        6986974,
        -9,
        6987280,
        -9,
        6987556,
        -9,
        6987800,
        -9,
        6988060,
        -9,
        6988124,
        -9,
        6988372,
        -9,
        6988616,
        -9,
        6988690,
        -9,
        6988980,
        -9,
        6989238,
        -10,
        6989560,
        -11,
        6989888,
        -10,
        6990132,
        -10,
        6990408,
        -11,
        6990512,
        -11,
        6990830,
        -11,
        6991132,
        -11,
        6991288,
        -11,
        6991540,
        -10,
        6991742,
        -10,
        6992010,
        -11,
        6992252,
        -10,
        6992518,
        -10,
        6992816,
        -10,
        6993104,
        -10,
        6993350,
        -10,
        6993740,
        -10,
        6994054,
        -11,
        6994126,
        -10,
        6994360,
        -11,
        6994658,
        -12,
        6994964,
        -12,
        6995256,
        -12,
        6995556,
        -12,
        6995568,
        -12,
        6995908,
        -12,
        6996164,
        -12,
        6996456,
        -12,
        6996740,
        -11,
        6996988,
        -11,
        6997060,
        -11,
        6997068,
        -11,
        6997132,
        -11,
        6997460,
        -11,
        6997780,
        -12,
        6998044,
        -11,
        6998402,
        -11,
        6998500,
        -12,
        6998584,
        -12,
        6998850,
        -11,
        6999104,
        -11,
        6999360,
        -11,
        6999632,
        -11,
        6999880,
        -11,
        7000060,
        -11,
        7000304,
        -11,
        7000388,
        -11,
        7000632,
        -11,
        7000800,
        -11,
        7000872,
        -11,
        7001198,
        -10,
        7001376,
        -10,
        7001686,
        -10,
        7001954,
        -10,
        7002310,
        -10,
        7002340,
        -10,
        7002590,
        -10,
        7002820,
        -10,
        7002952,
        -10,
        7003108,
        -10,
        7003384,
        -10,
        7003642,
        -10,
        7003908,
        -11,
        7004160,
        -11,
        7004254,
        -9,
        7004516,
        -8,
        7004658,
        -9,
        7004904,
        -8,
        7005172,
        -9,
        7005416,
        -8,
        7005660,
        -10,
        7005846,
        -9,
        7006100,
        -8,
        7006404,
        -9,
        7006668,
        -8,
        7006958,
        -8,
        7007140,
        -8,
        7007480,
        -8,
        7007736,
        -8,
        7008152,
        -9,
        7008472,
        -7,
        7008580,
        -8,
        7008848,
        -8,
        7009150,
        -7,
        7009432,
        -7,
        7009676,
        -7,
        7009924,
        -7,
        7009988,
        -7,
        7010020,
        -7,
        7010308,
        -7,
        7010310,
        -7,
        7010312,
        -7,
        7010592,
        -6,
        7010856,
        -6,
        7011126,
        -6,
        7011352,
        -6,
        7011460,
        -7,
        7011716,
        -7,
        7012000,
        -7,
        7012172,
        -7,
        7012406,
        -7,
        7012672,
        -8,
        7012900,
        -8,
        7013044,
        -8,
        7013374,
        -9,
        7013594,
        -9,
        7013914,
        -9,
        7014156,
        -9,
        7014342,
        -9,
        7014586,
        -9,
        7014692,
        -9,
        7014954,
        -9,
        7015230,
        -9,
        7015484,
        -9,
        7015754,
        -9,
        7015996,
        -9,
        7016258,
        -9,
        7016504,
        -9,
        7016654,
        -9,
        7016660,
        -9,
        7017062,
        -9,
        7017220,
        -10,
        7017388,
        -10,
        7017648,
        -9,
        7017916,
        -10,
        7018158,
        -10,
        7018416,
        -10,
        7018660,
        -10,
        7018908,
        -10,
        7019008,
        -10,
        7019256,
        -10,
        7019576,
        -9,
        7019896,
        -9,
        7020084,
        -9,
        7020344,
        -9,
        7020604,
        -9,
        7020850,
        -9,
        7021052,
        -9,
        7021394,
        -9,
        7021540,
        -9,
        7021748,
        -9,
        7021970,
        -9,
        7022236,
        -9,
        7022780,
        -10,
        7022980,
        -10,
        7023200,
        -10,
        7023284,
        -10,
        7023432,
        -10,
        7023676,
        -10,
        7023964,
        -10,
        7024294,
        -10,
        7024420,
        -10,
        7024480,
        -10,
        7024496,
        -10,
        7024708,
        -10,
        7024968,
        -10,
        7025254,
        -10,
        7025368,
        -10,
        7025648,
        -9,
        7025928,
        -8,
        7026170,
        -8,
        7026416,
        -8,
        7026676,
        -9,
        7026948,
        -10,
        7027216,
        -10,
        7027472,
        -9,
        7027652,
        -9,
        7027912,
        -9,
        7028204,
        -9,
        7028456,
        -9,
        7028704,
        -9,
        7028960,
        -9,
        7029046,
        -9,
        7029186,
        -11,
        7029440,
        -9,
        7029512,
        -9,
        7029592,
        -9,
        7029834,
        -9,
        7030076,
        -9,
        7030320,
        -9,
        7030704,
        -9,
        7030960,
        -9,
        7031240,
        -9,
        7031512,
        -8,
        7031782,
        -9,
        7032054,
        -9,
        7032316,
        -9,
        7032668,
        -8,
        7032940,
        -8,
        7033032,
        -9,
        7033284,
        -10,
        7033436,
        -10,
        7033720,
        -10,
        7034026,
        -11,
        7034318,
        -10,
        7034476,
        -10,
        7034768,
        -10,
        7034992,
        -10,
        7035294,
        -10,
        7035420,
        -10,
        7035548,
        -10,
        7035810,
        -10,
        7036102,
        -10,
        7036436,
        -10,
        7036662,
        -2,
        7036746,
        -11,
        7037172,
        -10,
        7037488,
        -10,
        7037792,
        -10,
        7038064,
        -10,
        7038508,
        -9,
        7038814,
        -9,
        7039018,
        -9,
        7039342,
        -9,
        7039586,
        -9,
        7039904,
        -9,
        7040208,
        -10,
        7040354,
        -10,
        7040572,
        -10,
        7040840,
        -10,
        7041176,
        -2,
        7041436,
        -10,
        7041534,
        -10,
        7041840,
        14,
        7042128,
        -10,
        7042372,
        -2,
        7042396,
        -2,
        7042746,
        -10,
        7043042,
        -10,
        7043180,
        -9,
        7043468,
        -9,
        7043748,
        -9,
        7043956,
        -9,
        7044248,
        -9,
        7044532,
        -10,
        7044880,
        -9,
        7045206,
        -9,
        7045416,
        -9,
        7045880,
        -10,
        7046090,
        -10,
        7046168,
        -10,
        7046472,
        -10,
        7046536,
        -10,
        7046820,
        -10,
        7047122,
        -10,
        7047404,
        -10,
        7047732,
        -10,
        7048080,
        -10,
        7048452,
        -11,
        7048876,
        -11,
        7049148,
        -10,
        7049452,
        -10,
        7049768,
        -10,
        7050040,
        -10,
        7050284,
        -10,
        7050580,
        -10,
        7050760,
        -10,
        7051006,
        -10,
        7051252,
        -10,
        7051418,
        -9,
        7051700,
        -9,
        7051962,
        -9,
        7052120,
        -9,
        7052396,
        -9,
        7052828,
        -10,
        7053112,
        -2,
        7053216,
        -10,
        7053300,
        -2,
        7053544,
        -2,
        7053690,
        -2,
        7053932,
        -11,
        7054280,
        -11,
        7054562,
        -2,
        7054808,
        -2,
        7055058,
        -2,
        7055306,
        -11,
        7055844,
        -11,
        7055868,
        -11,
        7055870,
        -11,
        7056176,
        -11,
        7056472,
        -11,
        7056712,
        -11,
        7057080,
        -11,
        7057348,
        -11,
        7057594,
        -11,
        7057992,
        -14,
        7058152,
        -14,
        7058404,
        -14,
        7058528,
        -14,
        7058836,
        -14,
        7059272,
        -14,
        7059524,
        -14,
        7059772,
        -14,
        7059776,
        -14,
        7060044,
        -14,
        7060248,
        -14,
        7060496,
        -14,
        7060764,
        -14,
        7061020,
        -14,
        7061294,
        -14,
        7061612,
        -14,
        7061902,
        -14,
        7062230,
        -14,
        7062426,
        -14,
        7062756,
        -14,
        7062794,
        -14,
        7063136,
        -14,
        7063348,
        -14,
        7063628,
        -11,
        7063884,
        -14,
        7063994,
        -11,
        7064300,
        -11,
        7064684,
        -14,
        7064928,
        -12,
        7065008,
        -10,
        7065268,
        -12,
        7065638,
        -13,
        7065864,
        -13,
        7066120,
        -11,
        7066208,
        -14,
        7066472,
        -11,
        7066748,
        -13,
        7066992,
        -11,
        7067380,
        15,
        7067694,
        -13,
        7067964,
        -13,
        7068122,
        -11,
        7068314,
        -14,
        7068712,
        -13,
        7068960,
        -11,
        7069072,
        -11,
        7069408,
        14,
        7069550,
        -11,
        7069660,
        -11,
        7069882,
        -11,
        7069884,
        -11,
        7069896,
        -14,
        7070138,
        -11,
        7070140,
        -13,
        7070278,
        -14,
        7070632,
        -14,
        7070794,
        -11,
        7070812,
        -11
    ],
    [
        6649996,
        50,
        6664380,
        49,
        6665320,
        48,
        6666578,
        49,
        6667390,
        48,
        6667948,
        49,
        6668022,
        48,
        6668136,
        49,
        6668180,
        48,
        6668312,
        49,
        6668330,
        48,
        6668592,
        49,
        6668600,
        48,
        6670408,
        49,
        6670420,
        48,
        6685096,
        47,
        6685160,
        48,
        6686492,
        47,
        6686708,
        48,
        6686784,
        47,
        6692798,
        46,
        6697612,
        45,
        6699248,
        46,
        6700124,
        45,
        6700506,
        47,
        6700964,
        44,
        6715672,
        43,
        6716644,
        46,
        6720940,
        45,
        6721124,
        46,
        6725536,
        45,
        6726320,
        46,
        6726730,
        45,
        6731048,
        43,
        6731126,
        48,
        6731144,
        45,
        6736854,
        43,
        6737020,
        42,
        6738020,
        45,
        6742848,
        44,
        6742864,
        45,
        6742892,
        44,
        6744308,
        45,
        6744372,
        44,
        6747190,
        45,
        6747200,
        44,
        6758308,
        43,
        6759760,
        44,
        6759972,
        43,
        6760006,
        44,
        6762312,
        43,
        6762536,
        44,
        6762996,
        43,
        6786108,
        42,
        6786774,
        43,
        6786780,
        42,
        6786988,
        43,
        6786990,
        42,
        6787268,
        43,
        6788512,
        42,
        6806236,
        43,
        6806748,
        -1,
        6806766,
        43,
        6806932,
        42,
        6806936,
        43,
        6807632,
        42,
        6807656,
        43,
        6813880,
        -1,
        6813916,
        43,
        6815192,
        -1,
        6815220,
        43,
        6867910,
        42,
        6867940,
        43,
        6889764,
        42,
        6916930,
        -1,
        6917008,
        42,
        6918038,
        -1,
        6918086,
        42,
        6923304,
        -1,
        6923376,
        42,
        6960940,
        43,
        6964306,
        44,
        6967512,
        45,
        6967862,
        44,
        6967872,
        45,
        7026676,
        -1,
        7026736,
        45,
        7032870,
        42,
        7032984,
        45,
        7033000,
        42,
        7034320,
        45,
        7034362,
        42,
        7035548,
        -1,
        7035556,
        42,
        7036668,
        45,
        7036674,
        42,
        7036776,
        45,
        7036824,
        42,
        7044028,
        45,
        7044044,
        42,
        7050208,
        45,
        7050228,
        42,
        7051776,
        45,
        7051788,
        42,
        7053136,
        45,
        7053176,
        42,
        7054432,
        45,
        7054492,
        42,
        7055878,
        45,
        7055890,
        42,
        7057524,
        45,
        7057532,
        42,
        7063716,
        43,
        7065112,
        42,
        7065150,
        43,
        7068974,
        42,
        7068988,
        43
    ],
    [
        6649996,
        40,
        6651436,
        139,
        6652134,
        140,
        6652638,
        139,
        6653120,
        143,
        6653308,
        144,
        6653508,
        145,
        6654508,
        146,
        6654672,
        147,
        6654932,
        148,
        6656026,
        147,
        6656202,
        148,
        6656380,
        149,
        6656440,
        148,
        6656484,
        149,
        6656652,
        148,
        6656656,
        149,
        6656784,
        150,
        6657448,
        151,
        6657552,
        152,
        6657816,
        154,
        6657890,
        148,
        6657916,
        156,
        6658020,
        149,
        6658048,
        158,
        6658528,
        159,
        6659288,
        160,
        6659388,
        161,
        6659414,
        148,
        6659428,
        162,
        6659856,
        163,
        6660080,
        165,
        6660160,
        167,
        6660268,
        168,
        6660342,
        169,
        6660582,
        170,
        6660688,
        172,
        6660716,
        175,
        6660876,
        162,
        6660888,
        175,
        6661108,
        176,
        6661512,
        177,
        6661580,
        162,
        6661588,
        177,
        6661596,
        178,
        6661650,
        179,
        6661696,
        180,
        6661850,
        181,
        6662116,
        184,
        6662128,
        185,
        6662156,
        186,
        6662172,
        187,
        6662236,
        177,
        6662254,
        187,
        6662334,
        188,
        6662636,
        162,
        6662652,
        188,
        6662892,
        189,
        6663016,
        177,
        6663018,
        189,
        6663042,
        190,
        6663054,
        191,
        6663110,
        192,
        6663580,
        195,
        6663618,
        197,
        6663620,
        177,
        6663628,
        197,
        6663714,
        198,
        6663866,
        199,
        6663900,
        200,
        6664014,
        201,
        6664084,
        202,
        6664156,
        203,
        6664308,
        204,
        6664380,
        206,
        6664524,
        207,
        6664630,
        208,
        6665020,
        212,
        6665056,
        213,
        6665320,
        214,
        6665696,
        215,
        6666142,
        216,
        6666256,
        217,
        6666308,
        218,
        6666432,
        219,
        6666476,
        221,
        6666562,
        222,
        6666578,
        223,
        6666740,
        224,
        6666846,
        225,
        6667908,
        226,
        6667924,
        228,
        6667948,
        229,
        6668022,
        230,
        6668136,
        229,
        6668180,
        230,
        6668192,
        231,
        6668216,
        232,
        6668312,
        225,
        6668330,
        232,
        6668592,
        225,
        6668600,
        232,
        6668800,
        233,
        6668892,
        215,
        6668894,
        233,
        6668974,
        234,
        6669090,
        236,
        6669316,
        237,
        6669340,
        239,
        6669360,
        245,
        6669370,
        246,
        6669836,
        247,
        6670144,
        248,
        6670332,
        215,
        6670336,
        248,
        6670408,
        225,
        6670420,
        248,
        6670760,
        249,
        6670768,
        255,
        6670770,
        248,
        6670784,
        257,
        6670796,
        261,
        6670816,
        264,
        6670854,
        266,
        6670956,
        233,
        6670976,
        266,
        6671112,
        267,
        6671264,
        268,
        6671272,
        269,
        6671510,
        248,
        6671524,
        269,
        6671630,
        270,
        6671876,
        271,
        6672068,
        272,
        6672208,
        274,
        6672228,
        275,
        6672250,
        277,
        6672264,
        278,
        6672288,
        279,
        6672398,
        280,
        6672520,
        281,
        6672784,
        233,
        6672796,
        281,
        6672944,
        282,
        6673120,
        283,
        6673312,
        284,
        6673364,
        285,
        6673432,
        286,
        6673632,
        283,
        6673648,
        290,
        6673666,
        291,
        6673674,
        290,
        6673680,
        292,
        6673700,
        294,
        6673860,
        233,
        6673872,
        294,
        6674302,
        295,
        6674530,
        283,
        6674536,
        295,
        6674772,
        296,
        6674824,
        215,
        6674826,
        296,
        6674888,
        233,
        6674908,
        296,
        6675090,
        298,
        6675108,
        300,
        6675114,
        301,
        6675136,
        304,
        6675376,
        305,
        6675814,
        306,
        6676208,
        308,
        6676408,
        309,
        6676492,
        296,
        6676702,
        320,
        6677384,
        296,
        6677482,
        320,
        6677832,
        266,
        6677844,
        323,
        6678840,
        334,
        6678888,
        335,
        6679492,
        350,
        6681248,
        368,
        6683422,
        377,
        6685096,
        384,
        6685160,
        296,
        6685632,
        388,
        6686028,
        283,
        6686124,
        390,
        6686404,
        391,
        6686708,
        395,
        6687068,
        396,
        6687562,
        397,
        6687838,
        396,
        6688554,
        405,
        6689204,
        404,
        6690506,
        412,
        6692116,
        416,
        6692798,
        421,
        6694720,
        426,
        6696084,
        431,
        6697612,
        436,
        6699248,
        439,
        6700124,
        442,
        6700506,
        530,
        6700964,
        276,
        6704724,
        285,
        6706212,
        290,
        6706582,
        291,
        6706904,
        293,
        6707292,
        294,
        6708780,
        296,
        6709812,
        298,
        6711136,
        300,
        6711288,
        301,
        6711500,
        302,
        6715672,
        312,
        6716574,
        313,
        6716644,
        603,
        6716758,
        602,
        6716972,
        605,
        6717652,
        606,
        6717796,
        607,
        6717932,
        608,
        6718258,
        609,
        6718288,
        610,
        6718328,
        612,
        6718508,
        611,
        6718554,
        612,
        6718556,
        611,
        6718598,
        612,
        6719724,
        614,
        6719766,
        616,
        6720644,
        617,
        6720940,
        616,
        6721124,
        617,
        6721172,
        619,
        6721980,
        620,
        6722188,
        621,
        6722720,
        625,
        6722904,
        626,
        6723526,
        627,
        6724032,
        628,
        6725536,
        635,
        6726368,
        636,
        6726730,
        637,
        6726908,
        644,
        6726934,
        645,
        6726962,
        650,
        6727152,
        649,
        6728076,
        650,
        6728152,
        651,
        6728360,
        652,
        6728368,
        653,
        6728396,
        654,
        6728420,
        655,
        6728510,
        656,
        6729736,
        657,
        6729852,
        661,
        6729876,
        662,
        6729998,
        663,
        6730062,
        662,
        6730628,
        661,
        6731020,
        662,
        6731048,
        347,
        6731126,
        317,
        6731144,
        664,
        6731264,
        669,
        6731276,
        670,
        6731360,
        662,
        6731388,
        670,
        6731472,
        671,
        6732688,
        676,
        6732728,
        680,
        6732756,
        679,
        6732802,
        662,
        6732808,
        679,
        6734152,
        681,
        6734170,
        682,
        6735440,
        683,
        6735512,
        684,
        6735548,
        688,
        6735612,
        691,
        6736482,
        692,
        6736854,
        363,
        6736998,
        365,
        6737020,
        366,
        6737038,
        367,
        6737468,
        368,
        6738020,
        700,
        6738454,
        701,
        6738492,
        704,
        6739204,
        703,
        6739286,
        704,
        6739562,
        702,
        6740206,
        701,
        6740616,
        709,
        6740864,
        710,
        6740992,
        711,
        6741336,
        713,
        6741342,
        716,
        6741360,
        717,
        6741556,
        662,
        6741646,
        717,
        6742164,
        701,
        6742172,
        717,
        6742220,
        701,
        6742240,
        717,
        6742566,
        718,
        6742748,
        719,
        6742804,
        722,
        6742848,
        726,
        6742864,
        662,
        6742892,
        726,
        6743220,
        727,
        6743268,
        728,
        6743564,
        729,
        6743620,
        728,
        6743652,
        729,
        6743750,
        730,
        6743780,
        729,
        6743812,
        730,
        6744308,
        662,
        6744372,
        730,
        6744636,
        731,
        6745016,
        733,
        6745214,
        734,
        6745300,
        735,
        6745360,
        736,
        6745736,
        748,
        6745848,
        752,
        6746040,
        751,
        6746484,
        752,
        6746536,
        755,
        6746800,
        632,
        6746884,
        755,
        6747176,
        756,
        6747190,
        662,
        6747200,
        756,
        6747360,
        757,
        6747464,
        758,
        6748208,
        759,
        6748348,
        760,
        6748534,
        765,
        6748562,
        767,
        6748596,
        770,
        6748658,
        771,
        6748704,
        770,
        6748926,
        769,
        6749482,
        770,
        6749502,
        771,
        6749536,
        770,
        6749572,
        771,
        6749632,
        770,
        6749976,
        775,
        6749980,
        777,
        6750022,
        779,
        6750192,
        781,
        6750476,
        782,
        6750536,
        781,
        6750922,
        780,
        6751388,
        784,
        6751410,
        786,
        6751432,
        790,
        6752858,
        796,
        6752872,
        797,
        6753070,
        798,
        6753240,
        799,
        6753532,
        798,
        6753726,
        799,
        6753854,
        800,
        6754344,
        807,
        6755712,
        808,
        6755732,
        813,
        6755786,
        817,
        6756058,
        819,
        6756094,
        820,
        6756100,
        821,
        6756116,
        822,
        6756162,
        825,
        6757196,
        826,
        6757224,
        828,
        6758108,
        829,
        6758138,
        828,
        6758140,
        829,
        6758308,
        830,
        6758380,
        831,
        6758508,
        832,
        6758662,
        836,
        6758700,
        839,
        6758936,
        840,
        6759656,
        839,
        6759760,
        840,
        6759788,
        839,
        6760100,
        843,
        6760128,
        844,
        6760144,
        845,
        6760148,
        847,
        6760358,
        846,
        6760396,
        845,
        6760430,
        844,
        6760550,
        845,
        6760836,
        844,
        6761202,
        839,
        6761206,
        844,
        6761536,
        853,
        6761594,
        857,
        6762008,
        856,
        6762792,
        857,
        6762972,
        863,
        6762996,
        865,
        6763016,
        869,
        6763180,
        871,
        6763300,
        872,
        6763752,
        873,
        6763882,
        874,
        6764176,
        875,
        6764436,
        881,
        6764552,
        886,
        6764994,
        885,
        6765200,
        886,
        6765352,
        887,
        6765456,
        888,
        6765512,
        889,
        6765844,
        890,
        6765902,
        896,
        6765912,
        899,
        6766828,
        886,
        6766866,
        899,
        6767298,
        907,
        6767332,
        912,
        6767600,
        911,
        6768192,
        899,
        6768198,
        911,
        6768202,
        912,
        6768312,
        913,
        6768520,
        914,
        6768776,
        916,
        6768792,
        917,
        6768804,
        920,
        6769688,
        921,
        6770004,
        920,
        6770158,
        921,
        6770170,
        927,
        6770200,
        929,
        6770220,
        932,
        6770400,
        933,
        6770574,
        931,
        6771448,
        932,
        6771452,
        931,
        6771608,
        933,
        6771630,
        935,
        6771688,
        936,
        6771908,
        935,
        6772780,
        934,
        6773064,
        940,
        6773092,
        943,
        6773182,
        944,
        6773484,
        943,
        6773620,
        936,
        6773632,
        931,
        6773934,
        932,
        6774508,
        933,
        6774536,
        934,
        6774640,
        936,
        6774980,
        933,
        6775008,
        931,
        6775028,
        907,
        6775966,
        909,
        6776008,
        910,
        6776136,
        911,
        6776436,
        910,
        6776984,
        909,
        6777142,
        908,
        6777160,
        907,
        6777366,
        909,
        6777444,
        914,
        6777940,
        912,
        6778652,
        913,
        6778812,
        916,
        6778856,
        920,
        6778872,
        923,
        6779558,
        921,
        6779810,
        922,
        6780144,
        923,
        6780208,
        924,
        6780306,
        926,
        6780328,
        928,
        6780340,
        929,
        6780372,
        932,
        6780700,
        929,
        6780864,
        930,
        6781552,
        931,
        6781738,
        934,
        6781768,
        935,
        6781808,
        937,
        6782148,
        933,
        6782566,
        931,
        6782572,
        933,
        6782740,
        937,
        6782748,
        933,
        6783140,
        938,
        6783150,
        933,
        6783162,
        940,
        6783176,
        942,
        6783200,
        943,
        6783204,
        945,
        6783476,
        946,
        6783552,
        945,
        6783636,
        944,
        6783712,
        943,
        6784016,
        933,
        6784018,
        943,
        6784424,
        944,
        6784596,
        930,
        6784606,
        947,
        6784628,
        949,
        6784644,
        955,
        6784662,
        930,
        6784668,
        955,
        6785002,
        950,
        6785966,
        930,
        6785980,
        950,
        6786032,
        952,
        6786076,
        954,
        6786108,
        955,
        6786414,
        954,
        6786774,
        933,
        6786780,
        954,
        6786988,
        930,
        6786990,
        954,
        6787070,
        955,
        6787268,
        956,
        6787462,
        960,
        6787512,
        961,
        6787730,
        962,
        6787772,
        957,
        6787824,
        961,
        6787860,
        957,
        6787974,
        961,
        6787976,
        957,
        6788244,
        958,
        6788252,
        962,
        6788258,
        958,
        6788340,
        957,
        6788372,
        958,
        6788480,
        959,
        6788504,
        960,
        6788512,
        961,
        6788756,
        962,
        6788804,
        960,
        6788912,
        964,
        6788930,
        965,
        6788964,
        971,
        6789260,
        972,
        6789300,
        970,
        6789424,
        969,
        6789564,
        962,
        6789622,
        963,
        6789756,
        964,
        6790342,
        969,
        6790356,
        964,
        6790358,
        970,
        6790404,
        972,
        6790672,
        973,
        6790868,
        974,
        6790916,
        973,
        6790938,
        972,
        6791046,
        960,
        6791076,
        961,
        6791760,
        962,
        6791782,
        970,
        6791842,
        972,
        6791900,
        971,
        6792444,
        970,
        6792480,
        971,
        6792508,
        972,
        6792832,
        968,
        6793216,
        972,
        6793258,
        975,
        6793266,
        977,
        6793460,
        978,
        6793602,
        979,
        6793652,
        978,
        6794384,
        979,
        6794488,
        980,
        6794712,
        981,
        6794788,
        983,
        6794826,
        982,
        6794894,
        983,
        6795100,
        972,
        6795128,
        973,
        6795182,
        971,
        6795504,
        961,
        6796094,
        962,
        6796192,
        967,
        6796668,
        962,
        6796712,
        961,
        6796738,
        960,
        6796758,
        961,
        6797288,
        962,
        6797332,
        963,
        6797456,
        964,
        6797548,
        968,
        6797560,
        969,
        6797616,
        974,
        6797732,
        975,
        6797872,
        976,
        6797976,
        978,
        6798248,
        975,
        6798388,
        976,
        6798640,
        977,
        6798774,
        978,
        6798824,
        979,
        6798968,
        983,
        6798986,
        985,
        6799024,
        986,
        6799066,
        992,
        6799548,
        986,
        6799960,
        987,
        6800060,
        986,
        6800120,
        987,
        6800142,
        988,
        6800188,
        989,
        6800268,
        990,
        6800364,
        991,
        6800464,
        992,
        6800468,
        991,
        6800482,
        993,
        6800500,
        995,
        6800524,
        1001,
        6800692,
        1002,
        6800724,
        1003,
        6801308,
        1004,
        6801484,
        1005,
        6801556,
        1006,
        6801562,
        1007,
        6801596,
        1008,
        6801656,
        1009,
        6801748,
        1010,
        6801760,
        1011,
        6801872,
        1013,
        6801888,
        1014,
        6801900,
        1015,
        6801926,
        1020,
        6801940,
        1021,
        6802000,
        1022,
        6802016,
        1023,
        6802602,
        1021,
        6802610,
        1022,
        6802626,
        1021,
        6802688,
        1022,
        6802840,
        1023,
        6802898,
        1024,
        6803212,
        1025,
        6803304,
        1029,
        6803336,
        1031,
        6803344,
        1032,
        6803360,
        1033,
        6803484,
        1034,
        6803892,
        1033,
        6804054,
        1034,
        6804464,
        1036,
        6804530,
        1037,
        6804592,
        1039,
        6804740,
        1041,
        6804756,
        1043,
        6804804,
        1044,
        6804824,
        1045,
        6805040,
        1046,
        6805104,
        1047,
        6806036,
        1045,
        6806062,
        1047,
        6806138,
        1048,
        6806220,
        1055,
        6806236,
        1056,
        6806268,
        1059,
        6806748,
        -1,
        6806766,
        1059,
        6806932,
        1009,
        6806936,
        1059,
        6806964,
        1060,
        6807282,
        1061,
        6807366,
        1062,
        6807444,
        1063,
        6807612,
        1064,
        6807632,
        1066,
        6807656,
        1069,
        6807696,
        1070,
        6807712,
        1074,
        6807740,
        1076,
        6807912,
        1077,
        6808644,
        1078,
        6808762,
        1079,
        6808900,
        1080,
        6809068,
        1084,
        6809084,
        1087,
        6809108,
        1088,
        6809142,
        1089,
        6809454,
        1085,
        6809554,
        1084,
        6809828,
        1083,
        6809890,
        1084,
        6810052,
        1085,
        6810080,
        1086,
        6810126,
        1087,
        6810206,
        1088,
        6810216,
        1089,
        6810324,
        1090,
        6810404,
        1089,
        6810528,
        1092,
        6810536,
        1093,
        6810564,
        1099,
        6810600,
        1101,
        6810662,
        1102,
        6810876,
        1103,
        6811252,
        1102,
        6811310,
        1103,
        6811328,
        1104,
        6811400,
        1105,
        6811418,
        1106,
        6811448,
        1107,
        6811832,
        1106,
        6811928,
        1108,
        6811972,
        1111,
        6811986,
        1113,
        6812010,
        1115,
        6812018,
        1118,
        6812066,
        1117,
        6812136,
        1118,
        6812200,
        1119,
        6812724,
        1118,
        6812786,
        1117,
        6812932,
        1116,
        6813160,
        1117,
        6813258,
        1118,
        6813332,
        1119,
        6813380,
        1118,
        6813400,
        1120,
        6813476,
        1122,
        6813712,
        1121,
        6813880,
        -1,
        6813916,
        1121,
        6814096,
        1122,
        6814244,
        1123,
        6814272,
        1124,
        6814300,
        1125,
        6814416,
        1126,
        6814466,
        1127,
        6814824,
        1133,
        6814856,
        1137,
        6815128,
        1136,
        6815192,
        -1,
        6815220,
        1135,
        6815256,
        1134,
        6815544,
        1135,
        6815656,
        1136,
        6816098,
        1137,
        6816282,
        1140,
        6816320,
        1144,
        6816324,
        1145,
        6816352,
        1146,
        6817012,
        1147,
        6817324,
        1149,
        6817532,
        1150,
        6817636,
        1151,
        6817776,
        1159,
        6817920,
        1160,
        6817940,
        1161,
        6817952,
        1162,
        6818110,
        1163,
        6818198,
        1162,
        6818602,
        1163,
        6818792,
        1164,
        6818980,
        1165,
        6819030,
        1166,
        6819124,
        1167,
        6819166,
        1171,
        6819232,
        1174,
        6819248,
        1175,
        6819264,
        1174,
        6819440,
        1175,
        6819448,
        1176,
        6819624,
        1173,
        6819656,
        1172,
        6819752,
        1173,
        6820208,
        1174,
        6820296,
        1173,
        6820328,
        1174,
        6820470,
        1173,
        6820592,
        1177,
        6820620,
        1178,
        6820628,
        1179,
        6820662,
        1180,
        6820840,
        1181,
        6820888,
        1182,
        6821020,
        1181,
        6821544,
        1182,
        6821678,
        1183,
        6821968,
        1184,
        6822048,
        1192,
        6822056,
        1193,
        6822080,
        1194,
        6822172,
        1196,
        6822426,
        1152,
        6822494,
        1196,
        6823004,
        1197,
        6823076,
        1198,
        6823240,
        1197,
        6823438,
        1196,
        6823492,
        1198,
        6823552,
        1199,
        6823792,
        1200,
        6823840,
        1199,
        6824188,
        1200,
        6824588,
        1196,
        6824602,
        1200,
        6825030,
        1206,
        6825138,
        1207,
        6825826,
        1208,
        6825948,
        1207,
        6826052,
        1209,
        6826240,
        1210,
        6826516,
        1216,
        6826696,
        1215,
        6826816,
        1216,
        6827596,
        1215,
        6827616,
        1216,
        6827672,
        1215,
        6827856,
        1220,
        6828016,
        1221,
        6829060,
        1220,
        6829308,
        1224,
        6829592,
        1220,
        6830052,
        1221,
        6830328,
        1222,
        6830368,
        1221,
        6830456,
        1222,
        6830676,
        1225,
        6830696,
        1228,
        6830708,
        1229,
        6831036,
        1228,
        6831358,
        1229,
        6831848,
        1228,
        6831876,
        1227,
        6831984,
        1228,
        6832100,
        1230,
        6832104,
        1196,
        6832212,
        1234,
        6832460,
        1235,
        6832744,
        1234,
        6832912,
        1233,
        6833394,
        1235,
        6833502,
        1233,
        6833552,
        1237,
        6833706,
        1241,
        6833780,
        1235,
        6833796,
        1240,
        6834480,
        1241,
        6834576,
        1242,
        6834868,
        1241,
        6835040,
        1244,
        6835072,
        1249,
        6835916,
        1250,
        6835996,
        1251,
        6836080,
        1252,
        6836462,
        1257,
        6836508,
        1260,
        6836590,
        1261,
        6837892,
        1266,
        6837944,
        1269,
        6838164,
        1270,
        6838334,
        1269,
        6838844,
        1270,
        6839050,
        1271,
        6839236,
        1272,
        6839294,
        1273,
        6839334,
        1276,
        6839344,
        1277,
        6839412,
        1279,
        6839432,
        1280,
        6839548,
        1281,
        6839568,
        1282,
        6839720,
        1281,
        6839952,
        1282,
        6840238,
        1283,
        6840346,
        1281,
        6840356,
        1284,
        6840458,
        1285,
        6840774,
        1290,
        6841260,
        1291,
        6841606,
        1292,
        6841792,
        1293,
        6841972,
        1294,
        6842004,
        1295,
        6842072,
        1294,
        6842148,
        1293,
        6842188,
        1294,
        6842240,
        1299,
        6842244,
        1300,
        6842276,
        1301,
        6842776,
        1300,
        6842984,
        1301,
        6843568,
        1302,
        6843614,
        1308,
        6843696,
        1309,
        6844200,
        1310,
        6844620,
        1311,
        6845080,
        1314,
        6845156,
        1322,
        6846048,
        1323,
        6846436,
        1325,
        6846560,
        1334,
        6846664,
        1333,
        6846876,
        1334,
        6847120,
        1333,
        6847248,
        1334,
        6847260,
        1333,
        6847508,
        1334,
        6847548,
        1335,
        6847576,
        1336,
        6847672,
        1335,
        6847688,
        1336,
        6847696,
        1335,
        6847832,
        1336,
        6847958,
        1338,
        6848012,
        1341,
        6848236,
        1334,
        6848268,
        1342,
        6848292,
        1334,
        6848320,
        1342,
        6848568,
        1334,
        6848578,
        1342,
        6848812,
        1343,
        6849452,
        1347,
        6849800,
        1345,
        6849856,
        1347,
        6849888,
        1344,
        6850258,
        1345,
        6850832,
        1348,
        6850872,
        1351,
        6850890,
        1352,
        6850896,
        1353,
        6851170,
        1354,
        6851566,
        1355,
        6852126,
        1354,
        6852340,
        1360,
        6852716,
        1361,
        6853290,
        1360,
        6853452,
        1361,
        6853592,
        1362,
        6853720,
        1364,
        6853752,
        1366,
        6853772,
        1367,
        6854046,
        1369,
        6854640,
        1370,
        6854752,
        1371,
        6855136,
        1372,
        6855198,
        1375,
        6855330,
        1376,
        6855486,
        1377,
        6855516,
        1376,
        6856068,
        1377,
        6856420,
        1378,
        6856612,
        1384,
        6856636,
        1388,
        6856674,
        1389,
        6857710,
        1390,
        6857892,
        1391,
        6858028,
        1394,
        6858056,
        1396,
        6858066,
        1397,
        6858084,
        1400,
        6858172,
        1401,
        6858292,
        1402,
        6859272,
        1403,
        6859448,
        1407,
        6859482,
        1408,
        6859498,
        1409,
        6859522,
        1369,
        6859584,
        1409,
        6860632,
        1410,
        6860954,
        1411,
        6861068,
        1410,
        6861300,
        1411,
        6862006,
        1369,
        6862020,
        1411,
        6862440,
        1418,
        6862554,
        1419,
        6862970,
        1418,
        6863464,
        1419,
        6863588,
        1420,
        6863736,
        1419,
        6863744,
        1420,
        6863796,
        1421,
        6863828,
        1420,
        6863840,
        1423,
        6863880,
        1426,
        6863918,
        1427,
        6864094,
        1428,
        6864716,
        1429,
        6864792,
        1430,
        6864916,
        1428,
        6864950,
        1430,
        6865144,
        1431,
        6865208,
        1432,
        6865260,
        1434,
        6865290,
        1435,
        6866688,
        1438,
        6866764,
        1441,
        6867746,
        1440,
        6867910,
        1027,
        6867940,
        1440,
        6868148,
        1445,
        6868350,
        1444,
        6868954,
        1445,
        6869236,
        1446,
        6869548,
        1448,
        6869604,
        1451,
        6869724,
        1452,
        6869998,
        1449,
        6870462,
        1450,
        6870512,
        1449,
        6870906,
        1448,
        6870996,
        1450,
        6871036,
        1452,
        6871536,
        1453,
        6872052,
        1454,
        6872280,
        1455,
        6872416,
        1459,
        6872652,
        1460,
        6872990,
        1461,
        6873894,
        1462,
        6873908,
        1463,
        6874010,
        1464,
        6875288,
        1466,
        6875308,
        1467,
        6875430,
        1468,
        6875696,
        1467,
        6876772,
        1473,
        6876798,
        1475,
        6876842,
        1476,
        6876980,
        1477,
        6877298,
        1478,
        6877748,
        1479,
        6877924,
        1480,
        6878182,
        1482,
        6878224,
        1485,
        6879036,
        1486,
        6879416,
        1485,
        6879432,
        1486,
        6879556,
        1485,
        6879684,
        1484,
        6880058,
        1483,
        6881036,
        1482,
        6881058,
        1484,
        6881140,
        1489,
        6881228,
        1490,
        6881280,
        1491,
        6881400,
        1492,
        6882324,
        1493,
        6882540,
        1495,
        6882568,
        1497,
        6882640,
        1496,
        6882650,
        1497,
        6882804,
        1498,
        6883040,
        1497,
        6883188,
        1496,
        6883934,
        1497,
        6883952,
        1500,
        6884020,
        1501,
        6884056,
        1500,
        6884100,
        1501,
        6884160,
        1502,
        6884752,
        1501,
        6885170,
        1500,
        6885392,
        1504,
        6885404,
        1505,
        6885436,
        1507,
        6885500,
        1508,
        6886832,
        1509,
        6886876,
        1510,
        6886922,
        1514,
        6887350,
        1513,
        6888208,
        1514,
        6888232,
        1516,
        6888328,
        1522,
        6888366,
        1524,
        6889704,
        1525,
        6889748,
        1528,
        6889764,
        1530,
        6890230,
        1528,
        6891124,
        1531,
        6891184,
        1532,
        6891192,
        1534,
        6891212,
        1535,
        6891276,
        1536,
        6892184,
        1537,
        6892612,
        1538,
        6892624,
        1539,
        6893020,
        1540,
        6893320,
        1541,
        6893604,
        1542,
        6894030,
        1544,
        6894176,
        1549,
        6894260,
        1550,
        6895508,
        1555,
        6895574,
        1556,
        6895924,
        1555,
        6896054,
        1556,
        6896368,
        1557,
        6896560,
        1558,
        6896944,
        1560,
        6896946,
        1562,
        6896966,
        1565,
        6897172,
        1566,
        6897256,
        1567,
        6897500,
        1566,
        6897828,
        1567,
        6898056,
        1566,
        6898212,
        1567,
        6898340,
        1570,
        6898404,
        1573,
        6899480,
        1574,
        6899824,
        1577,
        6899928,
        1578,
        6900374,
        1577,
        6900520,
        1541,
        6900546,
        1577,
        6900612,
        1576,
        6900730,
        1573,
        6900732,
        1576,
        6901220,
        1578,
        6901320,
        1585,
        6901504,
        1586,
        6901648,
        1585,
        6901872,
        1586,
        6902020,
        1576,
        6902032,
        1586,
        6902094,
        1587,
        6902360,
        1588,
        6902678,
        1589,
        6902732,
        1591,
        6902912,
        1592,
        6903496,
        1593,
        6904140,
        1596,
        6905546,
        1597,
        6905574,
        1598,
        6905602,
        1599,
        6906648,
        1178,
        6906670,
        1600,
        6906924,
        1602,
        6907100,
        1605,
        6908020,
        1606,
        6908560,
        1609,
        6908620,
        1612,
        6908676,
        1613,
        6908912,
        1612,
        6908928,
        1611,
        6909228,
        1612,
        6909596,
        1613,
        6909704,
        1614,
        6909872,
        1615,
        6909932,
        1617,
        6910152,
        1618,
        6910264,
        1617,
        6910462,
        1616,
        6910596,
        1615,
        6911284,
        1616,
        6911356,
        1620,
        6912734,
        1622,
        6912780,
        1624,
        6912792,
        1625,
        6912964,
        1626,
        6913192,
        1625,
        6913838,
        1626,
        6914084,
        1627,
        6914192,
        1629,
        6914246,
        1635,
        6914366,
        1625,
        6914400,
        1636,
        6915696,
        1639,
        6916550,
        1640,
        6916826,
        1641,
        6916868,
        1642,
        6916930,
        -1,
        6917008,
        1642,
        6917064,
        1643,
        6917088,
        1644,
        6917276,
        1643,
        6917476,
        1639,
        6917488,
        1643,
        6917844,
        1642,
        6918038,
        -1,
        6918086,
        1642,
        6918156,
        1641,
        6918204,
        1642,
        6918252,
        1643,
        6918332,
        1645,
        6918534,
        1649,
        6918604,
        1653,
        6918640,
        1625,
        6918644,
        1653,
        6918936,
        1652,
        6919854,
        1653,
        6919940,
        1655,
        6920008,
        1661,
        6920124,
        1662,
        6920164,
        1661,
        6920856,
        1660,
        6921088,
        1661,
        6921142,
        1660,
        6921188,
        1661,
        6921240,
        1662,
        6921364,
        1663,
        6921412,
        1665,
        6921446,
        1669,
        6921520,
        1670,
        6921584,
        1671,
        6921636,
        1670,
        6921816,
        1669,
        6921822,
        1670,
        6921824,
        1669,
        6921968,
        1668,
        6922146,
        1669,
        6922272,
        1668,
        6922300,
        1669,
        6922484,
        1668,
        6922528,
        1667,
        6922596,
        1661,
        6922600,
        1667,
        6922782,
        1669,
        6922792,
        1661,
        6922848,
        1673,
        6922880,
        1677,
        6922936,
        1680,
        6923146,
        1681,
        6923304,
        -1,
        6923376,
        1682,
        6923976,
        1681,
        6924136,
        1682,
        6924300,
        1688,
        6924340,
        1691,
        6924374,
        1690,
        6924476,
        1689,
        6924640,
        1690,
        6924824,
        1689,
        6924870,
        1690,
        6925104,
        1689,
        6925176,
        1690,
        6925696,
        1691,
        6925732,
        1696,
        6925796,
        1697,
        6926530,
        1698,
        6926748,
        1699,
        6927064,
        1700,
        6927140,
        1701,
        6927248,
        1705,
        6927462,
        1706,
        6927876,
        1707,
        6927984,
        1708,
        6927998,
        1707,
        6928052,
        1708,
        6928146,
        1709,
        6928592,
        1713,
        6928604,
        1714,
        6928634,
        1716,
        6928648,
        1717,
        6928738,
        1718,
        6929540,
        1719,
        6929576,
        1720,
        6929664,
        1721,
        6930040,
        1723,
        6930068,
        1725,
        6930412,
        1724,
        6930548,
        1723,
        6930780,
        1724,
        6931448,
        1725,
        6931492,
        1727,
        6931576,
        1728,
        6931862,
        1729,
        6932352,
        1730,
        6932400,
        1731,
        6932572,
        1732,
        6932628,
        1733,
        6932784,
        1734,
        6932892,
        1724,
        6932920,
        1734,
        6932970,
        1738,
        6933036,
        1739,
        6933704,
        1740,
        6933924,
        1741,
        6934134,
        1742,
        6934332,
        1743,
        6934392,
        1746,
        6934764,
        1747,
        6934864,
        1746,
        6935188,
        1747,
        6935636,
        1748,
        6935776,
        1751,
        6935786,
        1752,
        6935882,
        1757,
        6935944,
        1755,
        6935984,
        1754,
        6936308,
        1755,
        6936720,
        1754,
        6936810,
        1755,
        6937064,
        1754,
        6937232,
        1757,
        6937310,
        1760,
        6937370,
        1763,
        6937636,
        1762,
        6938118,
        1763,
        6938232,
        1764,
        6938236,
        1763,
        6938252,
        1764,
        6938602,
        1763,
        6938660,
        1764,
        6938956,
        1765,
        6939124,
        1764,
        6939160,
        1765,
        6940116,
        1769,
        6940480,
        1768,
        6940970,
        1769,
        6941272,
        1768,
        6941286,
        1769,
        6941380,
        1770,
        6941382,
        1768,
        6941384,
        1770,
        6941408,
        1771,
        6941544,
        1777,
        6942514,
        1778,
        6942632,
        1779,
        6942648,
        1778,
        6942732,
        1779,
        6943030,
        1781,
        6943134,
        1782,
        6943464,
        1781,
        6944172,
        1780,
        6944274,
        1779,
        6944380,
        1781,
        6944436,
        1785,
        6944940,
        1784,
        6945288,
        1785,
        6945822,
        1791,
        6946116,
        1792,
        6947044,
        1793,
        6947236,
        1796,
        6947344,
        1798,
        6947512,
        1797,
        6947550,
        1798,
        6947688,
        1796,
        6947920,
        1795,
        6948216,
        1796,
        6948312,
        1797,
        6948372,
        1799,
        6948688,
        1800,
        6948768,
        1802,
        6948920,
        1801,
        6949364,
        1800,
        6949824,
        1799,
        6950048,
        1360,
        6950216,
        1804,
        6951542,
        1803,
        6951558,
        1804,
        6951606,
        1805,
        6952480,
        1806,
        6952714,
        1805,
        6952734,
        1806,
        6952884,
        1807,
        6953012,
        1813,
        6953032,
        1814,
        6953128,
        1807,
        6953176,
        1814,
        6953212,
        1815,
        6953266,
        1807,
        6953268,
        1815,
        6953374,
        1816,
        6953488,
        1815,
        6953842,
        1814,
        6954440,
        1817,
        6954460,
        1818,
        6954484,
        1820,
        6954764,
        1821,
        6954820,
        1814,
        6954830,
        1821,
        6955124,
        1822,
        6955904,
        1824,
        6956016,
        1825,
        6956604,
        1824,
        6956704,
        1825,
        6956906,
        1826,
        6956960,
        1828,
        6957264,
        1835,
        6957308,
        1836,
        6957444,
        1846,
        6958180,
        1847,
        6958394,
        1848,
        6958504,
        1849,
        6958672,
        1850,
        6958802,
        1855,
        6958870,
        1858,
        6959142,
        1859,
        6959624,
        1860,
        6959648,
        1861,
        6959672,
        1862,
        6959756,
        1863,
        6959906,
        1864,
        6959936,
        1865,
        6959980,
        1866,
        6960194,
        1868,
        6960216,
        1871,
        6960274,
        1873,
        6960452,
        1875,
        6960480,
        1876,
        6960534,
        1877,
        6960630,
        1878,
        6960740,
        1877,
        6961068,
        1878,
        6961112,
        1879,
        6961182,
        1880,
        6961212,
        1882,
        6961300,
        1884,
        6961330,
        1886,
        6961344,
        1887,
        6961358,
        1888,
        6961404,
        1889,
        6961418,
        1890,
        6961462,
        1891,
        6961498,
        1896,
        6961532,
        1898,
        6961552,
        1901,
        6961568,
        1902,
        6961646,
        1916,
        6961668,
        1921,
        6961700,
        1941,
        6961720,
        1942,
        6961744,
        1943,
        6961778,
        1945,
        6961836,
        1947,
        6961862,
        1948,
        6961940,
        1949,
        6962014,
        1951,
        6962140,
        1952,
        6962342,
        1953,
        6962444,
        1955,
        6962500,
        1957,
        6962556,
        1961,
        6962600,
        1962,
        6962646,
        1968,
        6962744,
        1979,
        6962770,
        1980,
        6962808,
        1983,
        6962832,
        1985,
        6962872,
        1987,
        6962890,
        1988,
        6962928,
        1992,
        6962932,
        1993,
        6962964,
        1998,
        6962996,
        1999,
        6963028,
        2006,
        6963052,
        2015,
        6963116,
        2054,
        6963202,
        2077,
        6963236,
        1962,
        6963252,
        2079,
        6963262,
        2080,
        6963344,
        2084,
        6963404,
        2092,
        6963412,
        2091,
        6963476,
        2094,
        6963638,
        2092,
        6963704,
        2093,
        6963754,
        2092,
        6963812,
        2093,
        6963844,
        2092,
        6963846,
        2094,
        6963876,
        2095,
        6963892,
        2096,
        6963920,
        2095,
        6963940,
        2097,
        6963960,
        2102,
        6964000,
        2104,
        6964020,
        2105,
        6964056,
        2108,
        6964072,
        2109,
        6964092,
        2112,
        6964104,
        2114,
        6964142,
        2116,
        6964176,
        2119,
        6964210,
        2121,
        6964306,
        2130,
        6964432,
        2148,
        6964462,
        2150,
        6964528,
        2182,
        6964546,
        2190,
        6964562,
        2199,
        6964620,
        2207,
        6964640,
        2209,
        6964648,
        2211,
        6964704,
        2218,
        6964756,
        2221,
        6964784,
        2224,
        6964870,
        2228,
        6964888,
        2229,
        6965070,
        2230,
        6965112,
        2229,
        6965140,
        2230,
        6965152,
        2229,
        6965280,
        2231,
        6965348,
        2230,
        6965372,
        2232,
        6965468,
        2240,
        6965556,
        2242,
        6965596,
        2243,
        6965618,
        2246,
        6965650,
        2247,
        6965680,
        2248,
        6965792,
        2258,
        6965982,
        2305,
        6966016,
        2326,
        6966116,
        2333,
        6966160,
        2334,
        6966184,
        2337,
        6966284,
        2344,
        6966332,
        2348,
        6966500,
        2353,
        6966608,
        2229,
        6966612,
        2353,
        6966704,
        2355,
        6966820,
        2360,
        6966866,
        2363,
        6966900,
        2362,
        6967004,
        2368,
        6967048,
        2371,
        6967070,
        2372,
        6967090,
        2373,
        6967100,
        2375,
        6967164,
        2379,
        6967184,
        2378,
        6967308,
        2384,
        6967340,
        2388,
        6967392,
        2410,
        6967512,
        2440,
        6967760,
        2453,
        6967862,
        2229,
        6967872,
        2452,
        6967908,
        2454,
        6967928,
        2456,
        6968002,
        2455,
        6968164,
        2456,
        6968252,
        2457,
        6968268,
        2458,
        6968320,
        2459,
        6968330,
        2462,
        6968360,
        2463,
        6968408,
        2464,
        6968436,
        2467,
        6968500,
        2468,
        6968512,
        2471,
        6968608,
        2473,
        6968658,
        2479,
        6968680,
        2480,
        6968690,
        2482,
        6968726,
        2484,
        6968804,
        2486,
        6968842,
        2499,
        6968864,
        2507,
        6968896,
        2515,
        6968946,
        2513,
        6969014,
        2514,
        6969064,
        2511,
        6969092,
        2512,
        6969104,
        2513,
        6969116,
        2514,
        6969164,
        2515,
        6969212,
        2514,
        6969252,
        2515,
        6969328,
        2516,
        6969360,
        2517,
        6969392,
        2516,
        6969440,
        2515,
        6969480,
        2514,
        6969488,
        2515,
        6969578,
        2514,
        6969624,
        2515,
        6969692,
        2516,
        6969728,
        2517,
        6969812,
        2519,
        6969832,
        2520,
        6969876,
        2521,
        6969880,
        2522,
        6969900,
        2521,
        6970024,
        2519,
        6970040,
        2518,
        6970068,
        2517,
        6970092,
        2515,
        6970136,
        2516,
        6970156,
        2515,
        6970168,
        2513,
        6970180,
        2514,
        6970220,
        2515,
        6970232,
        2514,
        6970284,
        2523,
        6970312,
        2529,
        6970344,
        2535,
        6970368,
        2534,
        6970400,
        2533,
        6970416,
        2534,
        6970612,
        2532,
        6970644,
        2531,
        6970680,
        2528,
        6970700,
        2526,
        6970790,
        2525,
        6970830,
        2524,
        6970886,
        2520,
        6971020,
        2521,
        6971108,
        2522,
        6971172,
        2524,
        6971236,
        2526,
        6971252,
        2529,
        6971308,
        2531,
        6971364,
        2528,
        6971412,
        2529,
        6971504,
        2533,
        6971524,
        2532,
        6971556,
        2535,
        6971588,
        2537,
        6971592,
        2536,
        6971610,
        2535,
        6971628,
        2536,
        6971748,
        2555,
        6971790,
        2575,
        6971820,
        2577,
        6971864,
        2580,
        6971876,
        2581,
        6971896,
        2582,
        6971926,
        2583,
        6971964,
        2589,
        6972008,
        2588,
        6972070,
        2584,
        6972156,
        2581,
        6972204,
        2579,
        6972260,
        2578,
        6972316,
        2577,
        6972486,
        2521,
        6972508,
        2577,
        6972566,
        2578,
        6972588,
        2577,
        6972610,
        2578,
        6972644,
        2577,
        6972656,
        2578,
        6972712,
        2583,
        6972780,
        2585,
        6972792,
        2586,
        6972810,
        2587,
        6972900,
        2584,
        6972920,
        2587,
        6972976,
        2585,
        6972984,
        2586,
        6973006,
        2588,
        6973060,
        2593,
        6973108,
        2596,
        6973144,
        2602,
        6973152,
        2605,
        6973226,
        2623,
        6973254,
        2626,
        6973284,
        2627,
        6973308,
        2628,
        6973356,
        2630,
        6973366,
        2631,
        6973408,
        2632,
        6973416,
        2631,
        6973466,
        2632,
        6973476,
        2633,
        6973514,
        2632,
        6973580,
        2633,
        6973612,
        2632,
        6973852,
        2633,
        6974000,
        2634,
        6974004,
        2633,
        6974094,
        2634,
        6974134,
        2635,
        6974140,
        2636,
        6974216,
        2639,
        6974290,
        2641,
        6974328,
        2640,
        6974388,
        2643,
        6974436,
        2645,
        6974480,
        2646,
        6974516,
        2650,
        6974524,
        2585,
        6974548,
        2652,
        6974580,
        2656,
        6974612,
        2662,
        6974736,
        2669,
        6974770,
        2670,
        6974828,
        2672,
        6974904,
        2673,
        6974936,
        2674,
        6974994,
        2676,
        6975040,
        2675,
        6975088,
        2676,
        6975196,
        2677,
        6975604,
        2678,
        6975696,
        2682,
        6975720,
        2683,
        6975798,
        2684,
        6975854,
        2683,
        6975900,
        2684,
        6976042,
        2686,
        6976126,
        2691,
        6976216,
        2693,
        6976228,
        2694,
        6976364,
        2695,
        6976400,
        2696,
        6976986,
        2698,
        6977102,
        2699,
        6977108,
        2698,
        6977140,
        2700,
        6977148,
        2701,
        6977178,
        2702,
        6977228,
        2703,
        6977300,
        2704,
        6977456,
        2706,
        6977476,
        2710,
        6977516,
        2714,
        6977592,
        2716,
        6977682,
        2717,
        6977800,
        2718,
        6977842,
        2719,
        6978312,
        2721,
        6978624,
        2722,
        6978664,
        2721,
        6978668,
        2722,
        6978740,
        2723,
        6978876,
        2724,
        6978932,
        2726,
        6978940,
        2729,
        6978982,
        2732,
        6979008,
        2733,
        6979044,
        2734,
        6979066,
        2735,
        6979088,
        2736,
        6979140,
        2738,
        6979182,
        2739,
        6979230,
        2740,
        6979356,
        2741,
        6979454,
        2742,
        6979524,
        2743,
        6979828,
        2745,
        6979866,
        2746,
        6979908,
        2747,
        6979974,
        2748,
        6980096,
        2749,
        6980158,
        2752,
        6980244,
        2753,
        6980354,
        2755,
        6980452,
        2762,
        6980498,
        2763,
        6980632,
        2764,
        6980680,
        2765,
        6980724,
        2764,
        6980820,
        2763,
        6981092,
        2764,
        6981244,
        2763,
        6981284,
        2764,
        6981292,
        2765,
        6981340,
        2766,
        6981474,
        2768,
        6981520,
        2769,
        6981558,
        2770,
        6981572,
        2771,
        6981628,
        2770,
        6981672,
        2771,
        6981704,
        2772,
        6981784,
        2771,
        6981804,
        2773,
        6981870,
        2779,
        6981944,
        2781,
        6982064,
        2780,
        6982168,
        2781,
        6982196,
        2782,
        6982322,
        2783,
        6982468,
        2784,
        6982562,
        2785,
        6982704,
        2786,
        6982780,
        2787,
        6982950,
        2788,
        6982958,
        2789,
        6983016,
        2790,
        6983164,
        2783,
        6983178,
        2790,
        6983196,
        2791,
        6983226,
        2792,
        6983292,
        2801,
        6983340,
        2781,
        6983404,
        2802,
        6983436,
        2803,
        6983514,
        2804,
        6983676,
        2805,
        6983692,
        2806,
        6983794,
        2805,
        6983920,
        2808,
        6984012,
        2809,
        6984060,
        2790,
        6984096,
        2810,
        6984128,
        2811,
        6984146,
        2812,
        6984164,
        2813,
        6984228,
        2814,
        6984248,
        2815,
        6984304,
        2816,
        6984344,
        2815,
        6984402,
        2816,
        6984478,
        2817,
        6984560,
        2818,
        6984688,
        2823,
        6984760,
        2826,
        6984774,
        2825,
        6984790,
        2826,
        6984856,
        2827,
        6984908,
        2829,
        6984992,
        2830,
        6985038,
        2831,
        6985208,
        2830,
        6985412,
        2781,
        6985500,
        2830,
        6985922,
        2831,
        6986016,
        2832,
        6986100,
        2836,
        6986140,
        2842,
        6986192,
        2848,
        6986320,
        2847,
        6986340,
        2848,
        6986458,
        2849,
        6986560,
        2848,
        6986584,
        2847,
        6986600,
        2831,
        6986620,
        2847,
        6986820,
        2848,
        6986860,
        2849,
        6987024,
        2850,
        6987112,
        2851,
        6987280,
        2854,
        6987556,
        2858,
        6987588,
        2861,
        6987632,
        2866,
        6987800,
        2865,
        6987964,
        2867,
        6988148,
        2866,
        6988464,
        2867,
        6988606,
        2869,
        6988678,
        2870,
        6988888,
        2871,
        6988948,
        2872,
        6988980,
        2873,
        6989004,
        2875,
        6989016,
        2876,
        6989056,
        2881,
        6989136,
        2880,
        6989264,
        2879,
        6989424,
        2880,
        6989448,
        2881,
        6989468,
        2882,
        6989560,
        2883,
        6990088,
        2882,
        6990132,
        2883,
        6990142,
        2882,
        6990272,
        2883,
        6990300,
        2884,
        6990352,
        2885,
        6990408,
        2887,
        6990450,
        2891,
        6990476,
        2899,
        6990510,
        2901,
        6990548,
        2902,
        6990576,
        2903,
        6990624,
        2904,
        6990672,
        2903,
        6990744,
        2904,
        6990830,
        2903,
        6990928,
        2902,
        6991286,
        2903,
        6991540,
        2904,
        6991644,
        2905,
        6991752,
        2906,
        6991864,
        2908,
        6991910,
        2909,
        6992010,
        2910,
        6992100,
        2911,
        6992170,
        2912,
        6992344,
        2913,
        6992350,
        2914,
        6992368,
        2915,
        6992392,
        2916,
        6992636,
        2915,
        6992748,
        2916,
        6992872,
        2917,
        6992926,
        2918,
        6992970,
        2919,
        6993036,
        2921,
        6993292,
        2926,
        6993332,
        2931,
        6993350,
        2932,
        6993376,
        2934,
        6993460,
        2936,
        6993540,
        2937,
        6993808,
        2935,
        6994322,
        2936,
        6994574,
        2935,
        6994780,
        2938,
        6994806,
        2940,
        6994980,
        2941,
        6994988,
        2943,
        6995020,
        2944,
        6995108,
        2945,
        6995122,
        2946,
        6995256,
        2947,
        6995272,
        2948,
        6995626,
        2949,
        6995724,
        2950,
        6995952,
        2951,
        6996072,
        2950,
        6996278,
        2955,
        6996320,
        2956,
        6996360,
        2957,
        6996528,
        2958,
        6996848,
        2959,
        6996988,
        2960,
        6997164,
        2961,
        6997244,
        2960,
        6997336,
        2961,
        6997460,
        2962,
        6997462,
        2961,
        6997696,
        2969,
        6997780,
        2970,
        6998044,
        2971,
        6998166,
        2972,
        6998660,
        2973,
        6998790,
        2972,
        6999068,
        2974,
        6999104,
        2978,
        6999122,
        2985,
        6999224,
        2986,
        6999448,
        2985,
        6999784,
        2986,
        7000028,
        2987,
        7000146,
        2988,
        7000188,
        2972,
        7000196,
        2988,
        7000334,
        2989,
        7000356,
        2990,
        7000522,
        2995,
        7000536,
        2997,
        7000568,
        3000,
        7000584,
        3003,
        7000632,
        3004,
        7000710,
        3005,
        7000868,
        3006,
        7001096,
        3005,
        7001196,
        3004,
        7001280,
        2986,
        7001362,
        3004,
        7001424,
        3005,
        7001548,
        3007,
        7001608,
        3009,
        7001776,
        3010,
        7001954,
        3016,
        7002064,
        3021,
        7002310,
        3022,
        7002340,
        3021,
        7003212,
        3022,
        7003384,
        3028,
        7003436,
        3030,
        7003456,
        3033,
        7003472,
        3034,
        7003524,
        3036,
        7003598,
        3037,
        7003620,
        3038,
        7003690,
        3039,
        7003734,
        3040,
        7003776,
        3041,
        7003992,
        3040,
        7004052,
        3041,
        7004092,
        3021,
        7004160,
        3041,
        7004360,
        3042,
        7004480,
        3043,
        7004904,
        3053,
        7005108,
        3054,
        7005416,
        3055,
        7005846,
        3056,
        7006310,
        3067,
        7006668,
        3068,
        7006744,
        3069,
        7007086,
        3071,
        7007296,
        3072,
        7007480,
        3073,
        7007736,
        3080,
        7007778,
        3083,
        7007876,
        3084,
        7007894,
        3085,
        7008176,
        3084,
        7008232,
        3083,
        7008252,
        3084,
        7008656,
        3085,
        7008688,
        3086,
        7009150,
        3091,
        7009186,
        3096,
        7009200,
        3098,
        7009268,
        3097,
        7009516,
        3098,
        7009908,
        3083,
        7009924,
        3098,
        7010410,
        3099,
        7010592,
        3103,
        7010636,
        3106,
        7010692,
        3108,
        7010856,
        3109,
        7011126,
        3110,
        7011132,
        3109,
        7011168,
        3110,
        7011172,
        3111,
        7011416,
        3083,
        7011460,
        3110,
        7011528,
        3111,
        7011584,
        3112,
        7011716,
        3113,
        7011816,
        3114,
        7012030,
        3118,
        7012074,
        3119,
        7013044,
        3118,
        7013056,
        3119,
        7013166,
        3120,
        7013268,
        3121,
        7013374,
        3122,
        7013530,
        3133,
        7014052,
        3132,
        7014168,
        3083,
        7014312,
        3132,
        7014812,
        3131,
        7014908,
        3134,
        7014954,
        3135,
        7014988,
        3136,
        7015052,
        3137,
        7015344,
        3136,
        7015446,
        3137,
        7015544,
        3083,
        7015612,
        3138,
        7016160,
        3139,
        7016258,
        3140,
        7016344,
        3146,
        7016450,
        3148,
        7016504,
        3149,
        7016512,
        3083,
        7016528,
        3149,
        7016576,
        3150,
        7016720,
        3149,
        7017462,
        3150,
        7017522,
        3149,
        7017826,
        3154,
        7017840,
        3156,
        7017870,
        3083,
        7017916,
        3157,
        7018048,
        3156,
        7018158,
        3157,
        7018270,
        3156,
        7018304,
        3157,
        7018368,
        3083,
        7018416,
        3158,
        7018890,
        3159,
        7019172,
        3160,
        7019228,
        3157,
        7019256,
        3162,
        7019272,
        3164,
        7019336,
        3166,
        7019424,
        3165,
        7019984,
        3083,
        7019992,
        3165,
        7020424,
        3166,
        7020544,
        3167,
        7020672,
        3169,
        7020708,
        3171,
        7020712,
        3172,
        7020788,
        3166,
        7020818,
        3172,
        7020844,
        3173,
        7020876,
        3174,
        7021126,
        3172,
        7021464,
        3083,
        7021504,
        3172,
        7021560,
        3083,
        7021576,
        3172,
        7021600,
        3157,
        7021608,
        3158,
        7021612,
        3172,
        7022088,
        3173,
        7022134,
        3175,
        7022172,
        3181,
        7022260,
        3182,
        7022780,
        3183,
        7023196,
        3182,
        7023200,
        3183,
        7023236,
        3182,
        7023312,
        3183,
        7023364,
        3182,
        7023570,
        3188,
        7023588,
        3190,
        7023624,
        3192,
        7023788,
        3157,
        7023792,
        3193,
        7024020,
        3194,
        7024028,
        3193,
        7025010,
        3197,
        7025024,
        3198,
        7025060,
        3201,
        7025092,
        3202,
        7026416,
        3203,
        7026500,
        3211,
        7026594,
        3083,
        7026608,
        3211,
        7026676,
        -1,
        7026736,
        3211,
        7027714,
        3212,
        7027912,
        3227,
        7027972,
        3225,
        7028022,
        3083,
        7028028,
        3225,
        7028660,
        3212,
        7028664,
        3224,
        7028856,
        3225,
        7029310,
        3226,
        7029332,
        3227,
        7029366,
        3230,
        7029416,
        3083,
        7029440,
        3230,
        7029488,
        2520,
        7029572,
        3230,
        7029660,
        3083,
        7029668,
        3230,
        7029690,
        3083,
        7029692,
        3230,
        7029792,
        3229,
        7030170,
        3083,
        7030176,
        3229,
        7030756,
        3231,
        7030816,
        3234,
        7030992,
        3235,
        7031240,
        3233,
        7031512,
        3083,
        7031530,
        3233,
        7031692,
        3234,
        7032108,
        3235,
        7032148,
        3236,
        7032206,
        3238,
        7032316,
        3242,
        7032480,
        3241,
        7032496,
        3240,
        7032504,
        3239,
        7032870,
        2084,
        7032904,
        2078,
        7032984,
        3083,
        7033000,
        2078,
        7033012,
        2079,
        7033154,
        2080,
        7033638,
        2082,
        7033720,
        2085,
        7033958,
        2084,
        7034320,
        3083,
        7034362,
        2084,
        7034544,
        2085,
        7034652,
        2086,
        7035052,
        2087,
        7035102,
        2089,
        7035128,
        2092,
        7035548,
        -1,
        7035556,
        2092,
        7035968,
        2093,
        7036102,
        2092,
        7036520,
        2093,
        7036580,
        2100,
        7036668,
        3083,
        7036674,
        2100,
        7036776,
        3083,
        7036824,
        2100,
        7037964,
        2102,
        7038064,
        2104,
        7038212,
        2103,
        7038872,
        2104,
        7039392,
        2105,
        7039456,
        2108,
        7039586,
        2109,
        7040682,
        2110,
        7041672,
        2111,
        7041724,
        2112,
        7042128,
        2113,
        7043170,
        2114,
        7043500,
        2115,
        7043748,
        2116,
        7044028,
        3230,
        7044044,
        2116,
        7045206,
        2117,
        7045358,
        2119,
        7045416,
        2122,
        7045426,
        2124,
        7045502,
        2134,
        7045532,
        2136,
        7045564,
        2138,
        7045646,
        2144,
        7046472,
        2145,
        7046820,
        2146,
        7047412,
        2147,
        7047496,
        2146,
        7047828,
        2147,
        7047916,
        2149,
        7048080,
        2156,
        7048240,
        2155,
        7048292,
        2116,
        7048300,
        2155,
        7048452,
        2156,
        7048670,
        2157,
        7049476,
        2160,
        7049504,
        2162,
        7049516,
        2164,
        7049562,
        2165,
        7049958,
        2166,
        7050208,
        3083,
        7050228,
        2166,
        7050638,
        2167,
        7051004,
        2171,
        7051064,
        2172,
        7051128,
        2171,
        7051204,
        2172,
        7051252,
        2173,
        7051284,
        2174,
        7051700,
        2175,
        7051776,
        3083,
        7051788,
        2175,
        7051856,
        2174,
        7052352,
        2176,
        7052396,
        2179,
        7052930,
        2180,
        7053136,
        3083,
        7053176,
        2180,
        7053232,
        2181,
        7053252,
        2182,
        7053488,
        2183,
        7053544,
        2184,
        7053712,
        2185,
        7053794,
        2186,
        7053832,
        2194,
        7053896,
        2195,
        7054432,
        3083,
        7054492,
        2195,
        7054780,
        2196,
        7054808,
        2197,
        7055034,
        2198,
        7055218,
        2199,
        7055228,
        2202,
        7055272,
        2208,
        7055304,
        2210,
        7055530,
        2211,
        7055878,
        3083,
        7055890,
        2211,
        7056472,
        2212,
        7056712,
        2223,
        7056748,
        2224,
        7057080,
        2223,
        7057192,
        2224,
        7057524,
        3083,
        7057532,
        2224,
        7057708,
        2222,
        7057992,
        2223,
        7058152,
        2229,
        7058274,
        2230,
        7058528,
        2231,
        7058570,
        2232,
        7059524,
        2233,
        7059556,
        2234,
        7059584,
        2235,
        7059604,
        2238,
        7059824,
        2237,
        7060000,
        2238,
        7060044,
        2239,
        7060140,
        2230,
        7060248,
        2239,
        7060260,
        2240,
        7060496,
        2241,
        7060600,
        2240,
        7060764,
        2241,
        7060864,
        2242,
        7060934,
        2241,
        7061020,
        2245,
        7061032,
        2250,
        7061092,
        2251,
        7061902,
        2252,
        7062290,
        2253,
        7062426,
        2255,
        7062468,
        2259,
        7062476,
        2261,
        7062868,
        2262,
        7063322,
        2264,
        7063586,
        2265,
        7063628,
        2267,
        7063676,
        2268,
        7063716,
        2272,
        7063788,
        2274,
        7063816,
        2276,
        7063868,
        2284,
        7063884,
        2285,
        7063912,
        2293,
        7063994,
        2296,
        7064048,
        2298,
        7064084,
        2300,
        7064128,
        2301,
        7064160,
        2298,
        7064172,
        2297,
        7064224,
        2296,
        7064684,
        2297,
        7064700,
        2298,
        7064778,
        2299,
        7064880,
        2300,
        7064928,
        2301,
        7064984,
        2304,
        7065008,
        2305,
        7065028,
        2306,
        7065112,
        1796,
        7065150,
        2223,
        7065184,
        2309,
        7065196,
        2310,
        7065236,
        2312,
        7065268,
        2314,
        7065300,
        2317,
        7065374,
        2330,
        7065400,
        2331,
        7065416,
        2332,
        7065480,
        2335,
        7065778,
        2334,
        7066304,
        2338,
        7066416,
        2341,
        7066472,
        2343,
        7066560,
        2347,
        7066584,
        2348,
        7066680,
        2351,
        7066748,
        2355,
        7066786,
        2362,
        7066866,
        2363,
        7066992,
        2366,
        7067034,
        2365,
        7067104,
        2364,
        7067380,
        2365,
        7067522,
        2366,
        7067572,
        2341,
        7067600,
        2368,
        7067908,
        2369,
        7068170,
        2370,
        7068268,
        2390,
        7068414,
        2391,
        7068464,
        2369,
        7068480,
        2391,
        7068488,
        2392,
        7068712,
        2390,
        7068752,
        2389,
        7068868,
        2388,
        7068974,
        2224,
        7068988,
        2388,
        7069292,
        2389,
        7069408,
        2388,
        7069502,
        2389,
        7069628,
        2391,
        7069648,
        2392,
        7069660,
        2396,
        7069710,
        2400,
        7069808,
        2402,
        7070138,
        2401,
        7070316,
        2400,
        7070470,
        2401,
        7070632,
        2402,
        7070648,
        2403,
        7070794,
        2405
    ],
    [
        6515144,
        8999,
        0,
        6515902,
        -1,
        -1,
        6649996,
        4699,
        0,
        6654572,
        4799,
        0,
        6654676,
        4699,
        0,
        6656154,
        3999,
        0,
        6664988,
        4799,
        0,
        6665026,
        3999,
        0,
        6675634,
        4699,
        0,
        6675776,
        -1,
        -1,
        6686808,
        3999,
        0,
        6686868,
        -1,
        -1,
        6689188,
        3999,
        0,
        6689220,
        -1,
        -1,
        6696620,
        3999,
        0,
        6696722,
        -1,
        -1,
        6700508,
        3999,
        0,
        6700572,
        -1,
        -1,
        6707034,
        3999,
        0,
        6707196,
        -1,
        -1,
        6711344,
        3999,
        0,
        6712008,
        -1,
        -1,
        6716758,
        4699,
        0,
        6717158,
        4799,
        0,
        6717212,
        4699,
        0,
        6718224,
        4699,
        0,
        6718484,
        3999,
        0,
        6718536,
        4799,
        0,
        6718762,
        4799,
        0,
        6719078,
        4899,
        0,
        6719126,
        4799,
        0,
        6719932,
        4899,
        0,
        6720204,
        4899,
        0,
        6720392,
        4799,
        0,
        6720808,
        4899,
        0,
        6720942,
        3999,
        0,
        6736854,
        2998,
        0,
        6739352,
        2997,
        0,
        6756988,
        2997,
        0,
        6765200,
        3999,
        0,
        6767228,
        2997,
        0,
        6777142,
        4899,
        0,
        6777146,
        3999,
        0,
        6777208,
        4997,
        0,
        6781164,
        5097,
        0,
        6781220,
        4997,
        0,
        6782378,
        5599,
        0,
        6782402,
        4997,
        0,
        6783352,
        5597,
        0,
        6783396,
        4997,
        0,
        6783544,
        5597,
        0,
        6783546,
        4997,
        0,
        6784194,
        5597,
        0,
        6784248,
        5697,
        0,
        6784316,
        5597,
        0,
        6785952,
        3999,
        0,
        6786534,
        4099,
        0,
        6786622,
        3999,
        0,
        6788200,
        4099,
        0,
        6788220,
        3999,
        0,
        6835926,
        4099,
        0,
        6835928,
        3999,
        0,
        6837608,
        4099,
        0,
        6837612,
        4099,
        0,
        6837632,
        3999,
        0,
        6867596,
        4099,
        0,
        6867646,
        3999,
        0,
        6874010,
        3999,
        0,
        6877924,
        3599,
        0,
        6895432,
        5099,
        0,
        6895508,
        3599,
        0,
        6898080,
        5099,
        0,
        6898188,
        3599,
        0,
        6908172,
        3999,
        0,
        6918534,
        3599,
        0,
        6958656,
        3999,
        0,
        6968608,
        3599,
        0,
        6976504,
        3999,
        0,
        6976558,
        3599,
        0,
        6976770,
        3999,
        0,
        6976840,
        3599,
        0,
        6976890,
        3999,
        0,
        6976970,
        3599,
        0,
        6977096,
        3999,
        0,
        6977426,
        3599,
        0,
        6977516,
        3999,
        0,
        6977914,
        3599,
        0,
        6978312,
        3999,
        0,
        6978316,
        3599,
        0,
        6978396,
        3999,
        0,
        6978476,
        3599,
        0,
        6978552,
        3999,
        0,
        6988372,
        4099,
        0,
        6988402,
        3999,
        0,
        7004904,
        4099,
        0,
        7004932,
        3999,
        0,
        7005416,
        4099,
        0,
        7005426,
        3999,
        0,
        7006100,
        4099,
        0,
        7006286,
        3999,
        0,
        7006668,
        4099,
        0,
        7006760,
        3999,
        0,
        7006958,
        3999,
        0,
        7008928,
        3599,
        0,
        7009150,
        3999,
        0,
        7019008,
        3599,
        0,
        7029044,
        3999,
        0,
        7031240,
        4099,
        0,
        7031260,
        3999,
        0,
        7031512,
        4099,
        0,
        7031532,
        3999,
        0,
        7033284,
        3499,
        0,
        7033436,
        3999,
        0,
        7033472,
        3499,
        0,
        7033720,
        3999,
        0,
        7033748,
        3499,
        0,
        7034026,
        3999,
        0,
        7034040,
        3499,
        0,
        7034124,
        -1,
        -1,
        7034316,
        3999,
        0,
        7049284,
        3598,
        0,
        7057556,
        3499,
        0
    ],
    [
        6722534,
        2581,
        0,
        6722904,
        -1,
        -1,
        6739562,
        2639,
        0,
        6740048,
        -1,
        -1,
        6743046,
        2639,
        0,
        6744602,
        -1,
        -1,
        6756286,
        2697,
        0,
        6756456,
        -1,
        -1,
        6762258,
        2697,
        0,
        6762536,
        -1,
        -1,
        6772416,
        2697,
        0,
        6773312,
        -1,
        -1,
        6788648,
        3621,
        0,
        6789468,
        -1,
        -1,
        6803856,
        3832,
        0,
        6804716,
        3809,
        0,
        6816508,
        3738,
        0,
        6828868,
        3820,
        0,
        6830552,
        3903,
        0,
        6842684,
        3786,
        0,
        6845622,
        3868,
        0,
        6857248,
        3751,
        0,
        6869472,
        3634,
        0,
        6880660,
        3517,
        0,
        6893232,
        3401,
        0,
        6905820,
        3284,
        0,
        6909704,
        3366,
        0,
        6913378,
        3448,
        0,
        6915696,
        3530,
        0,
        6927628,
        3460,
        0,
        6939112,
        3390,
        0,
        6944940,
        3472,
        0,
        6956392,
        3402,
        0,
        6967340,
        3495,
        0,
        6971790,
        3563,
        0,
        6981900,
        3645,
        0,
        6986974,
        3727,
        0,
        6990830,
        3810,
        0,
        7000304,
        3892,
        0,
        7011716,
        3821,
        0,
        7019576,
        3563,
        0,
        7031782,
        3493,
        0,
        7033284,
        3464,
        0,
        7034768,
        3821,
        0,
        7041534,
        3904,
        0,
        7050760,
        3562,
        0,
        7057594,
        3464,
        0,
        7069408,
        3394,
        0
    ],
    [
        6773484,
        2697,
        0,
        6774224,
        -1,
        -1,
        6781964,
        3444,
        0,
        6782528,
        -1,
        -1,
        6790868,
        3512,
        0,
        6791092,
        -1,
        -1,
        6792052,
        3648,
        0,
        6792896,
        -1,
        -1,
        6793100,
        3716,
        0,
        6793268,
        -1,
        -1,
        6793992,
        3716,
        0,
        6804716,
        3693,
        0,
        6816508,
        3625,
        0,
        6828868,
        -1,
        -1,
        6865044,
        3637,
        0,
        6869472,
        3524,
        0,
        6880660,
        3411,
        0,
        6893232,
        3298,
        0,
        6905820,
        3184,
        0,
        6909704,
        3264,
        0,
        6913378,
        3344,
        0,
        6914308,
        -1,
        -1,
        6914620,
        3344,
        0,
        6915696,
        3423,
        0,
        6916066,
        -1,
        -1,
        6920248,
        3423,
        0,
        6926542,
        -1,
        -1,
        6926740,
        3423,
        0,
        6927628,
        3355,
        0,
        6939112,
        -1,
        -1,
        6943438,
        3287,
        0,
        6944940,
        3367,
        0,
        6949532,
        -1,
        -1,
        6951686,
        3367,
        0,
        6952776,
        -1,
        -1,
        6955028,
        3367,
        0,
        6956392,
        3299,
        0,
        6959890,
        -1,
        -1,
        6960740,
        3299,
        0,
        6964988,
        -1,
        -1,
        6989238,
        3614,
        0,
        6990830,
        3694,
        0,
        6991540,
        -1,
        -1,
        6994360,
        3694,
        0,
        6998044,
        -1,
        -1,
        7005660,
        3774,
        0,
        7006100,
        -1,
        -1,
        7022780,
        3455,
        0,
        7031512,
        -1,
        -1,
        7031782,
        3387,
        0,
        7032668,
        -1,
        -1,
        7045880,
        3785,
        0,
        7050760,
        3454,
        0,
        7053112,
        -1,
        -1,
        7053216,
        3454,
        0,
        7053300,
        -1,
        -1,
        7053932,
        3454,
        0,
        7054562,
        -1,
        -1,
        7055306,
        3454,
        0,
        7057594,
        3359,
        0,
        7069408,
        3291,
        0
    ],
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    [
        6722534,
        2581,
        0,
        6722904,
        -1,
        -1,
        6739562,
        2639,
        0,
        6740048,
        -1,
        -1,
        6743046,
        2639,
        0,
        6744602,
        -1,
        -1,
        6756286,
        2697,
        0,
        6756456,
        -1,
        -1,
        6762258,
        2697,
        0,
        6762536,
        -1,
        -1,
        6772416,
        2697,
        0,
        6773312,
        -1,
        -1,
        6773484,
        2697,
        0,
        6774224,
        -1,
        -1,
        6781964,
        3444,
        0,
        6782528,
        -1,
        -1,
        6788912,
        3621,
        0,
        6789468,
        -1,
        -1,
        6790868,
        3512,
        0,
        6791092,
        -1,
        -1,
        6792444,
        3648,
        0,
        6792896,
        -1,
        -1,
        6804716,
        3693,
        0,
        6808102,
        -1,
        -1,
        6810448,
        3693,
        0,
        6811406,
        -1,
        -1,
        6811584,
        3693,
        0,
        6813880,
        -1,
        -1,
        6814124,
        3693,
        0,
        6815128,
        -1,
        -1,
        6816508,
        3625,
        0,
        6828868,
        -1,
        -1,
        6843544,
        3786,
        0,
        6844200,
        -1,
        -1,
        6845080,
        3786,
        0,
        6845622,
        -1,
        -1,
        6857248,
        3751,
        0,
        6858292,
        -1,
        -1,
        6865044,
        3637,
        0,
        6869472,
        3524,
        0,
        6877930,
        -1,
        -1,
        6905820,
        3184,
        0,
        6909704,
        3264,
        0,
        6913378,
        3344,
        0,
        6914308,
        3448,
        0,
        6914620,
        3344,
        0,
        6915696,
        3423,
        0,
        6916066,
        3530,
        0,
        6918534,
        -1,
        -1,
        6941172,
        3390,
        0,
        6943438,
        3287,
        0,
        6944940,
        -1,
        -1,
        6956392,
        3299,
        0,
        6959142,
        -1,
        -1,
        6959454,
        3299,
        0,
        6959648,
        -1,
        -1,
        6960144,
        3402,
        0,
        6960740,
        3299,
        0,
        6963960,
        -1,
        -1,
        6964210,
        3299,
        0,
        6964988,
        3402,
        0,
        6966612,
        -1,
        -1,
        6966866,
        3402,
        0,
        6967340,
        3495,
        0,
        6968608,
        -1,
        -1,
        6977140,
        3563,
        0,
        6978312,
        -1,
        -1,
        6978572,
        3563,
        0,
        6980354,
        -1,
        -1,
        6980472,
        3563,
        0,
        6981356,
        -1,
        -1,
        6981784,
        3563,
        0,
        6981900,
        3645,
        0,
        6986974,
        3727,
        0,
        6989238,
        3614,
        0,
        6990830,
        -1,
        -1,
        6995908,
        3694,
        0,
        6998044,
        -1,
        -1,
        7029046,
        3455,
        0,
        7031782,
        3387,
        0,
        7032668,
        3493,
        0,
        7032940,
        -1,
        -1,
        7033032,
        3493,
        0,
        7033284,
        -1,
        -1,
        7034318,
        3464,
        0,
        7034768,
        -1,
        -1
    ],
    [
        6718224,
        2999,
        6720942,
        -1,
        6721060,
        3599,
        6722904,
        -1,
        6723242,
        3599,
        6724032,
        -1,
        6731312,
        3599,
        6731604,
        -1,
        6731972,
        3599,
        6736854,
        -1,
        6777142,
        2997,
        6785952,
        -1
    ]
]