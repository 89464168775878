import React from "react";
import { FaArrowsLeftRight } from "react-icons/fa6";
import { FaSearchPlus } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { TfiNewWindow } from "react-icons/tfi";
import ChartDemo from "../../charts";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
import { csvData } from "../../../constants";

interface ChartsProps {
  chartsData: any;
  chartsFilter: any;
  setChartsFilter: any;
  chartsLoader: any;
}
const Charts = ({
  chartsData,
  chartsFilter,
  setChartsFilter,
  chartsLoader,
}: ChartsProps) => {
  const classes = useStyles();

  const handleClick = (filter: any) => {
    setChartsFilter(filter);
  };

  return (
    <>
      {/* <ChartDemo /> */}
      {chartsLoader ? (
        <div className="loading-buyBox" style={{ overflow: "hidden" }}></div>
      ) : chartsData?.length ? (
        <ChartDemo chartsData={chartsData} chartsFilter={chartsFilter} />
      ) : (
        <></>
      )}

      <div className="d-flex gap-2 align-items-center flex-wrap">
        <Tooltip
          title={<div>Show 1 Day</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              chartsFilter === 0 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(0)}
          >
            Dy
          </button>
        </Tooltip>
        <Tooltip
          title={<div>Show 1 Week</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              chartsFilter === 1 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(1)}
          >
            Wk
          </button>
        </Tooltip>
        <Tooltip
          title={<div>Show 1 Month</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              chartsFilter === 2 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(2)}
          >
            Mo
          </button>
        </Tooltip>
        <Tooltip
          title={<div>Show 3 Months</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              chartsFilter === 3 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(3)}
          >
            3Mo
          </button>
        </Tooltip>
        <Tooltip
          title={<div>Show 1 Year</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              chartsFilter === 4 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(4)}
          >
            Yr
          </button>
        </Tooltip>
        <Tooltip
          title={<div>Show All Time</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              chartsFilter === 5 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(5)}
          >
            All
          </button>
        </Tooltip>

        <button className="discount-btn-refresh">
          <FaArrowsLeftRight />
        </button>
        <button className="discount-btn-refresh">
          <FaSearchPlus />
        </button>
        <button className="btn-refresh">
          <TfiNewWindow />
        </button>
        <button className="btn-refresh">
          <FaPlusCircle />
        </button>
      </div>
    </>
  );
};

export default Charts;
