import React, { useEffect, useRef, useState } from "react";
import Highcharts, { Point, color } from "highcharts";
import HCMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import "./index.css";

if (typeof Highcharts === "object") {
  HCMore(Highcharts);
}

// Define a custom interface for Point type
interface CustomPoint extends Point {
  highlight(event: MouseEvent): void;
}

declare module "highcharts" {
  interface Point {
    highlight(event: MouseEvent): void;
  }
}
interface ChartsProps {
  chartsData: any;
  chartsFilter: any;
}

interface CSVData {
  date: Date;
  price: number;
}

const ChartDemo = ({ chartsData, chartsFilter }: ChartsProps) => {
  const [chartData, setChartData] = useState<CSVData[]>([]);
  const priceChartRef = useRef<Highcharts.Chart | null>(null);

  const processCSVData = (csvData: number[], type?: any): CSVData[] => {
    if (!csvData || csvData.length === 0) {
      return [];
    }

    const keepaMinutesToDate = (keepaMinutes: number): Date => {
      const epochTime = (keepaMinutes + 21564000) * 60000; // Convert Keepa minutes to milliseconds
      return new Date(epochTime);
    };

    let maxPrice = -Infinity;
    let minPrice = Infinity;

    for (let i = 0; i < csvData.length; i += 2) {
      const price = type ? csvData[i + 1] : csvData[i + 1] / 100;
      maxPrice = Math.max(maxPrice, price);
      minPrice = Math.min(minPrice, price);
    }

    const priceData: CSVData[] = [];

    for (let i = 0; i < csvData.length; i += 2) {
      const date = keepaMinutesToDate(csvData[i]);
      let price = type ? csvData[i + 1] : csvData[i + 1] / 100;

      if (isNaN(price)) {
        continue;
      }

      price = (price - minPrice) / (maxPrice - minPrice);

      if (isNaN(price) || !isFinite(price)) {
        continue;
      }

      priceData.push({ date, price });
    }

    return priceData;
  };


  // const formatDateToUTC = (dateString: any) => {
  //   const date = new Date(dateString);

  //   const year = date.getUTCFullYear();
  //   const month = date.getUTCMonth();
  //   const day = date.getUTCDate();
  //   const hours = date.getUTCHours();
  //   const minutes = date.getUTCMinutes();

  //   return `Date.UTC(${year}, ${month}, ${day}, ${hours}, ${minutes})`;
  // };
  const formatDateToUTC = (dateString: any) => {
    const date = new Date(dateString);
    return date.getTime();
  };

  useEffect(() => {
    let AmazonData = processCSVData(chartsData?.[0]?.csv?.[0]);

    const FBAData = processCSVData(chartsData?.[0]?.csv?.[10]);
    const FBMData = processCSVData(chartsData?.[0]?.csv?.[7]);
    const buyBoxData = processCSVData(chartsData?.[0]?.csv?.[18]);
    const newData = processCSVData(chartsData?.[0]?.csv?.[1]);
    const salesRankData = processCSVData(chartsData?.[0]?.csv?.[3], "notPrice");
    const monthlySoldData = processCSVData(
      chartsData?.monthlySoldHistory,
      "notPrice"
    );
    const offersCountData = processCSVData(
      chartsData?.[0]?.csv?.[11],
      "notPrice"
    );
    const ratingData = processCSVData(chartsData?.[0]?.csv?.[16], "rating");
    const reviewCountData = processCSVData(chartsData?.[0]?.csv?.[17]);

    console.log("AmazonData:", AmazonData);
    console.log("FBAData:", FBAData);
    console.log("FBMData:", FBMData);
    console.log("buyBoxData:", buyBoxData);
    console.log("newData:", newData);
    console.log("ratingData:", ratingData);
    console.log("reviewCountData:", reviewCountData);
    console.log("offersCountData:", offersCountData);
    const getDateRangeFormatter = (chartsFilter: number) => {
      const oneDay = 24 * 60 * 60 * 1000;
      const today = new Date();
      const formattedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      const startOfWeek = new Date(formattedToday);
      startOfWeek.setDate(formattedToday.getDate() - formattedToday.getDay() + 1); // Set to the first day (Monday) of the current week

      const endOfWeek = new Date(formattedToday);
      endOfWeek.setDate(formattedToday.getDate() - formattedToday.getDay() + 7); // Set to the last day (Sunday) of the current week

      const formatDateWithinDays = (date: any, days: number, format: any) => {
        const formattedDate = new Date(date);
        const daysDiff = Math.floor((formattedToday.getTime() - formattedDate.getTime()) / oneDay);
        if (daysDiff <= days) {
          return formattedDate.toLocaleDateString(undefined, format);
        }
        return "";
      };

      const formatDateWithinMonths = (date: any, months: number) => {
        const formattedDate = new Date(date);
        const monthsDiff = (formattedToday.getFullYear() - formattedDate.getFullYear()) * 12 + formattedToday.getMonth() - formattedDate.getMonth();
        if (monthsDiff <= months) {
          return formattedDate.toLocaleDateString(undefined, {
            month: "short",
            year: "2-digit",
          });
        }
        return "";
      };

      switch (chartsFilter) {
        case 0:
          return (date: any) => {
            const formattedDate = new Date(date);
            return formattedDate.toDateString() === formattedToday.toDateString()
              ? formattedDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
              : "";
          };
        case 1:
          return (date: any) => {
            const formattedDate = new Date(date);
            if (formattedDate >= startOfWeek && formattedDate <= endOfWeek) {
              return formattedDate.toLocaleDateString(undefined, {
                day: "numeric",
                month: "short",
              });
            }
            return "";
          };
        case 2:
          return (date: any) => {
            const formattedDate = new Date(date);
            const daysDiff = Math.floor((formattedToday.getTime() - formattedDate.getTime()) / oneDay);
            if (daysDiff <= 31) {
              return `${formattedDate.getDate()}. ${formattedDate.toLocaleDateString(undefined, {
                month: "short",
              })}`;
            }
            return "";
          };
        case 3:
          return (date: any) => formatDateWithinMonths(date, 3);
        case 4:
          return (date: any) => formatDateWithinMonths(date, 12);
        default:
          return (date: any) => formatDateWithinMonths(date, 3);
      }
    };

    const container = document.getElementById("chart-container");

    ["mousemove", "touchmove", "touchstart"].forEach(function (eventType) {
      container?.addEventListener(eventType, function (e) {
        let chart, point, i, event;

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
          chart = Highcharts.charts[i];
          if (!chart) continue;
          event = chart?.pointer?.normalize(e as MouseEvent);
          point = chart?.series?.[0]?.searchPoint(event, true);

          if (point) {
            (point as CustomPoint).highlight(e as MouseEvent);
          }
        }
      });
    });

    const yAxisFormatter = (value: number): string => {
      return (value * 200).toFixed(0);
    };

    Highcharts.Pointer.prototype.reset = function () {
      return undefined;
    };

    Highcharts.Point.prototype.highlight = function (
      this: CustomPoint,
      event: Highcharts.PointerEventObject
    ) {
      event = this?.series?.chart?.pointer?.normalize(event);
      this.onMouseOver();
      this?.series?.chart.tooltip.refresh(this);
      this?.series?.chart.xAxis[0].drawCrosshair(event, this);
    };

    function syncExtremes(
      this: Highcharts.Axis,
      e: Highcharts.AxisSetExtremesEventObject
    ): void {
      const thisChart = this.chart;

      if (e.trigger !== "syncExtremes") {
        Highcharts.each(Highcharts.charts, function (chart: any) {
          if (chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes",
              });
            }
          }
        });
      }
    }

    const activity = {
      xData: Array.from({ length: 500 }, (_, i) => i + 1),
      datasets: [
        {
          name: "Amazon",
          data:
            AmazonData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          type: "spline",
          date: AmazonData?.map((item) => item?.date) || [],
          valueDecimals: 2,
          unit: "$",
          color: "#F9AF44",
        },
        {
          name: "FBA",
          data:
            FBAData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: FBAData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "$",
          color: "#FF764B",
        },
        {
          name: "FBM",
          data:
            FBMData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: FBMData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "$",
          color: "#22A7E8",
        },
        {
          name: "BuyBox",
          data:
            buyBoxData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: buyBoxData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "$",
          color: "#FF00B4",
        },
        {
          name: "New",
          data:
            newData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: newData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "$",
          color: "#8888DD",
        },
        {
          name: "Sale Rank",
          data:
            salesRankData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: salesRankData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "$",
          color: "#9DC59D",
        },
        {
          name: "Monthly Sold",
          data:
            monthlySoldData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: monthlySoldData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "m",
          color: "#D9A420",
        },
        {
          name: "Offer Count",
          data:
            offersCountData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: offersCountData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "m",
          color: "#8888DD",
        },
        {
          name: "Rating",
          data: (ratingData || [])
            .filter(item => !isNaN(item?.price) && typeof item?.date === 'string' && !isNaN(Date.parse(item?.date)))
            .map(item => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })),
          date: (ratingData || []).map(item => item?.date),
          type: "spline",
          valueDecimals: 2,
          unit: "m",
          color: "#3FB0A5",
        }
        ,
        {
          name: "Review Count",
          data:
            reviewCountData?.map((item) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: reviewCountData?.map((item) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "m",
          color: "#8AB300",
        },
      ],
    };

    const top5Datasets = activity.datasets
      .slice(0, 6)
      .sort((a, b) => b?.data?.length - a?.data?.length);

    const datasetAtIndex6 = activity.datasets[6];
    const remainingDatasets = activity.datasets
      .slice(7, 10)
      .sort((a, b) => b?.data?.length - a?.data?.length);

    const reorderedDatasets = [
      ...top5Datasets,
      datasetAtIndex6,
      ...remainingDatasets,
    ];

    activity.datasets = reorderedDatasets;

    const datasets = activity.datasets.map((dataset: any, i: number) => {
      return {
        data: Highcharts.map(dataset?.data, (val: any, j: number) => [
          val.x,
          val.y,
        ]),
        name: dataset.name,
        type: dataset.type,
        color: dataset?.color || "defaultColor",
        fillOpacity: 0.3,
        opacity: 1,
        tooltip: {
          valueSuffix: " " + dataset.unit,
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      };
    });
    const dateFormatter = (value: number) => {
      return Highcharts.dateFormat("%b '%y", value);
    };
    const chartDiv1 = document.createElement("div");
    const seriesOfThird = datasets
      ?.slice(0, 6)
      ?.map((series, index) => {
        // Skip the third index (index 2)
        if (index === 2) return null;

        return {
          type: "line",
          name: series?.name,
          data: series?.data,
          marker: {
            symbol: "square",
            radius: 4,
          },
          color: series?.color,
          lineWidth: 1.5,
          step: "left",
          opacity: 1,
          fillOpacity: 0,
        };
      })
      .filter(Boolean);
    chartDiv1.className = "chart";
    container?.appendChild(chartDiv1);
    Highcharts.chart(chartDiv1, {
      chart: {
        type: "line",
        marginLeft: 60,
        marginRight: 40,
        spacingTop: 20,
        spacingBottom: 20,
        zooming: {
          type: "x",
        },
      },
      title: {
        text: "Datasets 1-5",
        align: "left",
        margin: 0,
        x: 30,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
        align: "center",
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b '%y",
          year: "%b '%y",
        },
        labels: {
          formatter: function () {
            const dateRangeFormatter = getDateRangeFormatter(chartsFilter);
            return dateRangeFormatter(this.value);
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      tooltip: {
        shared: true,
        formatter: function () {
          const xValue = this.x;
          const dateIndex = activity.xData.findIndex(
            (_, index) => index === xValue
          );
          const date = dateIndex !== -1 ? activity.xData[dateIndex] : undefined;

          let tooltip =
            '<div style="font-size: 12px; padding: 10px; width: 200px;">';

          if (date !== undefined) {
            tooltip +=
              '<span style="margin-bottom: 5px;">' +
              Highcharts.dateFormat("%A, %b %e, %Y", date) +
              "</span><br><br>";
          }

          for (let i = 0; i < 6; i++) {
            const dataset = datasets[i];
            const point = dataset.data.find((data) => data[0] === xValue);
            if (point) {
              const pointColor = dataset.color;
              tooltip += `
          <div style="display: flex; justify-content: space-between;">
            <span style="color:${pointColor}; padding-right: 5px;">${dataset.name
                }:</span>
            <span>${dataset.name === "Sale Rank" ? "" : "$"}${point[1]?.toFixed(
                  2
                )}</span>
          </div><br>`;
            }
          }

          tooltip += "</div>";
          return tooltip;
        },
      },

      series: datasets?.slice(0, 6)?.map((series, index) => ({
        type: "line",
        name: series?.name,
        data: series?.data.filter(
          (point) => !isNaN(point[0]) && !isNaN(point[1])
        ),
        marker: {
          enabled: false,
          symbol: "square",
          radius: 4,
        },
        color: series?.color,
        lineWidth: 1.5,
        step: "left",
        opacity: 1,
        fillOpacity: 0,
      })),
    });

    const chartDiv2 = document.createElement("div");
    chartDiv2.className = "chart";
    container?.appendChild(chartDiv2);

    Highcharts.chart(chartDiv2, {
      chart: {
        type: "line",
        marginLeft: 40,
        spacingTop: 20,
        spacingBottom: 20,
        zooming: {
          type: "x",
        },
      },
      title: {
        text: "Dataset 6",
        align: "left",
        margin: 0,
        x: 30,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
        align: "center",
      },

      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b '%y",
          year: "%b '%y",
        },
        labels: {
          formatter: function () {
            return Highcharts.dateFormat("%b '%y", Number(this.value));
          },
        },
      },
      yAxis: [
        {
          title: {
            text: "",
          },
        },
        {
          title: {
            text: "Sold in past month",
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        formatter: function () {
          let tooltip = "";
          const xValue = this.x;
          const dataset = activity?.datasets[6];

          const yValue = dataset?.data?.find(
            (data, index) => activity.xData[index] === xValue
          );

          if (yValue !== undefined) {
            tooltip += `<br/>${dataset.name}: ${yValue?.y}`;
          }

          return tooltip;
        },

        borderWidth: 1,
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        shadow: true,
        style: {
          fontSize: "14px",
          padding: "8px",
        },
      },
      series: [datasets[6]]?.map((series) => ({
        ...series,
        type: "line",
        marker: {
          enabled: false,
        },
        fillOpacity: 0,
        lineWidth: 1,
        series: datasets,
      })),
    });

    const chartDiv3 = document.createElement("div");
    chartDiv3.className = "chart";
    container?.appendChild(chartDiv3);

    Highcharts.chart(chartDiv3, {
      chart: {
        type: "line",
        marginLeft: 40,
        spacingTop: 20,
        spacingBottom: 20,
        zooming: {
          type: "x",
        },
      },
      title: {
        text: "Combined Datasets 7-9",
        align: "left",
        margin: 0,
        x: 30,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
        align: "center",
      },

      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b '%y",
          year: "%b '%y",
        },
        labels: {
          formatter: function () {
            const dateRangeFormatter = getDateRangeFormatter(chartsFilter);
            return dateRangeFormatter(this.value);
          },
        },
      },
      yAxis: {

        title: {
          text: "",
        },
      },
      tooltip: {
        shared: true,
        formatter: function () {
          const xValue = this.x;
          const dateIndex = activity.xData.findIndex(
            (_, index) => index === xValue
          );
          const date = dateIndex !== -1 ? activity.xData[dateIndex] : undefined;

          let tooltip =
            '<div style="font-size: 12px; padding: 10px; width: 200px;">';

          if (date !== undefined) {
            tooltip +=
              '<span style="margin-bottom: 5px;">' +
              Highcharts.dateFormat("%A, %b %e, %Y", date) +
              "</span><br><br>";
          }

          for (let i = 7; i < 10; i++) {
            const dataset = datasets[i];
            const point = dataset.data.find((data) => data[0] === xValue);
            if (point) {
              const pointColor = dataset.color;
              tooltip += `
          <div style="display: flex; justify-content: space-between;">
            <span style="color:${pointColor}; padding-right: 5px;">${dataset.name
                }:</span>
            <span>${dataset.name === "Sale Rank" ? "" : "$"}${point[1]?.toFixed(
                  2
                )}</span>
          </div><br>`;
            }
          }

          tooltip += "</div>";
          return tooltip;
        },
      },

      series: datasets?.slice(7, 10)?.map((series) => ({
        type: "line",
        name: series?.name,
        data: series?.data,
        marker: {
          enabled: false,
          symbol: "square",
          radius: 4,
        },
        color: series?.color,
        lineWidth: 1.5,
        step: "left",
        opacity: 1,
        fillOpacity: 0,
      })),
    });

    // Cleanup function
    return () => {
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [chartsData]); // Empty dependency array to run only once after the initial render

  return <div id="chart-container" />;
};

export default ChartDemo;
