import React, { useEffect, useState } from "react";
import "./index.css";
import Offers from "./Partials/offers";
import ProfitCalculator from "./Partials/profitCalculator";
import Product from "./Partials/product";
import QuickInfo from "./Partials/quickInfo";
import RanksAndPrices from "./Partials/ranksAndPrices";
import {
  getBuyBoxAnalysis,
  getCharts,
  getProductInfo,
  getRanksAndPrices,
  getSellerOffers,
  profitCalculate,
} from "../../Service/services";
import toast from "react-hot-toast";
import Loader from "../Loader";
import {
  formatElapsedTime,
  handleOfferValues,
  handleRoiValues,
  handleSaleValue,
  handleValues,
} from "../../helpers";
import Widget from "./widget";
import Analysis from "./Partials/analysis";
import Charts from "./Partials/charts";
type DropdownState = {
  productDetails: boolean;
  quickInfo: boolean;
  profitCalculator: boolean;
  offers: boolean;
  ranks: boolean;
  sellerCentral: boolean;
  eBay: boolean;
  search: boolean;
  notesTags: boolean;
  geolocation: boolean;
  vatSettings: boolean;
  discounts: boolean;
  europeanMarketPlaces: boolean;
  roi: boolean;
  lookupDetails: boolean;
  alerts: boolean;
  keepa: boolean;
  variationBeta: boolean;
  buyBoxAnalysis: boolean;
  charts: boolean;
};
interface resultprops {
  searchResult: string;
}
interface errorResponse {
  data: {
    ErrorMessage?: string;
  };
}
interface ProfitCalculator {
  costPrice?: number;
  salePrice?: number;
  totalFees?: any;
  FBMCost?: any;
}

interface ProductDetails {
  profitCalculator?: ProfitCalculator;
  quickInfo?: any;
}
const Widgets = ({ searchResult }: resultprops) => {
  const [offers, setOffers] = useState<any>({});
  const [roi, setRoi] = useState<any>([]);
  const [productDetails, setProductDetails] = useState<any>({});
  const [fulFillmentType, setFulFillmentType] = useState<any>(0);
  const [offersFilter, setOffersFilter] = useState<any>({});
  const [buyBoxAnalysis, setBuyBoxAnalysis] = useState([]);
  const [selectedDay, setSelectedDay] = useState<any>("30");

  const [dropdownStates, setDropdownStates] = useState({
    productDetails: false,
    quickInfo: false,
    profitCalculator: false,
    offers: false,
    ranks: false,
    sellerCentral: false,
    eBay: false,
    search: false,
    notesTags: false,
    geolocation: false,
    vatSettings: false,
    discounts: false,
    europeanMarketPlaces: false,
    roi: false,
    lookupDetails: false,
    alerts: false,
    keepa: false,
    variationBeta: false,
    buyBoxAnalysis: false,
    charts: false,
  });
  //   const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [ranksAndPrices, setRanksAndPrices] = useState({});
  const [ranksLoader, setRanksLoader] = useState(false);
  const [buyBoxLoader, setBuyBoxLoader] = useState(false);
  const [chartsData, setChartsData] = useState([]);

  const [rankFilter, setRankFilter] = useState(0);
  const [chartsFilter, setChartsFilter] = useState(0);
  const [chartsLoader, setChartsLoader] = useState(false);

  const [buyBoxFilter, setBuyBoxFilter] = useState(30);

  const [totalDiscount, setTotalDiscount] = useState<any>("");
  const [referralFeePercentage, setReferralFeePercentage] = useState(0);

  const getProductDetails = async () => {
    try {
      setLoading(true);
      setRankFilter(0);

      const response = await getProductInfo(searchResult);
      if (response) {
        const salesValue = handleSaleValue(response?.data, "quickInfo");
        const updatedValue = handleValues(salesValue, "quickInfo", 0);
        setProductDetails(updatedValue);
        await getRanks();
        await getOffers(
          response?.data,
          false,
          false,
          updatedValue?.quickInfo?.costPrice
        );
        await getBuyBox();
        getChartsData();
        setDropdownStates((prevState) => ({
          ...prevState,
          productDetails: true,
          quickInfo: true,
          profitCalculator: true,
          offers: true,
          ranks: true,
          sellerCentral: true,
          eBay: true,
          search: true,
          notesTags: true,
          geolocation: true,
          vatSettings: true,
          discounts: true,
          europeanMarketPlaces: true,
          roi: true,
          lookupDetails: true,
          alerts: true,
          keepa: true,
          variationBeta: true,
          buyBoxAnalysis: true,
          charts: true,
        }));
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const getRanks = async () => {
    try {
      setRanksLoader(true);

      const response = await getRanksAndPrices(searchResult, rankFilter);

      if (response) {
        setRanksAndPrices(response?.data);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.title);
    } finally {
      setRanksLoader(false);
    }
  };

  const getOffers = async (
    productData: any,
    prime: any,
    live: any,
    cost: any
  ) => {
    try {
      setOffersFilter({
        prime: prime === "true" ? true : false,
        live,
      });
      const response = await getSellerOffers(searchResult, prime, live, cost);
      if (response) {
        // const offerValues = await handleOfferValues(
        //   response?.data,
        //   productData ?? productDetails,
        //   "quickInfo"
        // );
        setOffers(response?.data);
        return response?.data;
      }
    } catch (error: any) {
      console.log(
        error?.response?.data?.ErrorMessage ?? error?.response?.data?.title
      );
      // toast.error(
      //   error?.response?.data?.ErrorMessage ?? error?.response?.data?.title
      // );
    }
  };

  const getBuyBox = async () => {
    try {
      setBuyBoxLoader(true);
      const response = await getBuyBoxAnalysis(searchResult, buyBoxFilter);

      if (response) {
        const transformedData = response?.data?.map((item: any) => {
          const currentTime = new Date().getTime();
          const lastWonTime = new Date(item?.data?.lastWon).getTime();
          const elapsedTime = currentTime - lastWonTime;
          return {
            seller: item.sellerName,
            avgPrice: "$" + (item.data.avePrice / 100).toFixed(2),
            won: item?.data?.wonPercentage,

            lastWon: formatElapsedTime(elapsedTime),
            stock: item.data.stock,
            type: item.data.type,
            currentRatingCount: item.currentRatingCount,
            currentRating: item.currentRating,
          };
        });

        setBuyBoxAnalysis(transformedData);
      }
    } catch (error: any) {
      setBuyBoxLoader(false);
      toast.error(error?.response?.data?.ErrorMessage);
    } finally {
    }
  };

  const getChartsData = async () => {
    try {
      setChartsLoader(true);
      const response = await getCharts(searchResult, chartsFilter);

      if (response) {
        setChartsData(response?.data?.products);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorMessage);
    } finally {
      setChartsLoader(false);
    }
  };

  const processCSVData = (csvData: number[][]) => {
    const keepaMinutesToDate = (keepaMinutes: number) => {
      const epochTime = (keepaMinutes + 21564000) * 60;
      return new Date(epochTime * 1000);
    };

    const amazonPriceHistory = csvData[0];
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

    const priceData = [];
    for (let i = 0; i < amazonPriceHistory.length; i += 2) {
      const date = keepaMinutesToDate(amazonPriceHistory[i]);
      const price = amazonPriceHistory[i + 1] / 100;
      priceData.push({ date, price });
    }

    return priceData.filter(({ date }) => date >= oneWeekAgo);
  };

  const calculateProfit = async (
    updatedValues?: ProductDetails,
    type?: any,
    saleType?: any
  ) => {
    const data =
      fulFillmentType === 0
        ? {
          asin: searchResult,
          cost: updatedValues
            ? updatedValues?.profitCalculator?.costPrice
            : productDetails?.profitCalculator?.costPrice,
          price:
            updatedValues && type === "quickInfo"
              ? updatedValues?.quickInfo?.salePrice
              : updatedValues && type === "profitCalculator"
                ? updatedValues?.profitCalculator?.salePrice
                : productDetails && type === "quickInfo"
                  ? productDetails?.quickInfo?.salePrice
                  : productDetails?.profitCalculator?.salePrice,
          fulfillmentMethod: fulFillmentType,
        }
        : {
          asin: searchResult,
          cost: updatedValues
            ? updatedValues?.profitCalculator?.costPrice
            : productDetails?.profitCalculator?.costPrice,
          price:
            updatedValues && type === "quickInfo"
              ? updatedValues?.quickInfo?.salePrice
              : updatedValues && type === "profitCalculator"
                ? updatedValues?.profitCalculator?.salePrice
                : productDetails && type === "quickInfo"
                  ? productDetails?.quickInfo?.salePrice
                  : productDetails?.profitCalculator?.salePrice,
          fulfillmentMethod: fulFillmentType,
          fbmCost:
            updatedValues && updatedValues?.profitCalculator?.FBMCost
              ? updatedValues?.profitCalculator?.FBMCost
              : productDetails?.profitCalculator?.FBMCost
                ? productDetails?.profitCalculator?.FBMCost
                : 0,
        };

    if (productDetails?.profitCalculator) {
      try {
        const response = await profitCalculate(data);
        if (response?.status === 200) {
          let productValues: ProductDetails;
          setReferralFeePercentage(response?.data?.referralFeePercentage);
          if (updatedValues) {
            productValues = { ...updatedValues };
          } else {
            productValues = { ...productDetails };
          }
          if (!productValues.profitCalculator) {
            productValues.profitCalculator = {};
          }
          if (response?.data?.totalFees) {
            if (fulFillmentType == 1) {
              productValues.profitCalculator.totalFees = {
                ...response.data.totalFees,
                fulfilment_FBM: response.data.totalFees.fulfilment_FBA,
              };
              delete productValues.profitCalculator.totalFees.fulfilment_FBA;
            } else {
              productValues.profitCalculator.totalFees =
                response.data.totalFees;
            }
          }
          const salesValue = handleSaleValue(productValues, "quickInfo");
          const updatedValue = handleValues(salesValue, type, totalDiscount);
          setProductDetails(updatedValue);
          if (!saleType) {
            const offerValues = await handleOfferValues(
              offers,
              productValues,
              type
            );
            setOffers(offerValues);
            const roiValues = handleRoiValues(roi, productValues, type);
            setRoi(roiValues);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleToggle = (dropDownName: keyof DropdownState) => {
    setDropdownStates((prevState: DropdownState) => ({
      ...prevState,
      [dropDownName]: !prevState[dropDownName],
    }));
  };

  useEffect(() => {
    if (searchResult && rankFilter >= 0) getRanks();
  }, [rankFilter]);

  useEffect(() => {
    if (searchResult) getChartsData();
  }, [chartsFilter]);
  useEffect(() => {
    if (searchResult) getProductDetails();
  }, [searchResult]);

  useEffect(() => {
    if (fulFillmentType == 0 || fulFillmentType == 1) {
      calculateProfit(productDetails, "quickInfo");
    }
  }, [fulFillmentType]);

  useEffect(() => {
    if (searchResult && buyBoxFilter) getBuyBox();
  }, [buyBoxFilter]);
  return (
    <>
      {loading ? (
        <div className="loading-loader">
          <Loader />
        </div>
      ) : (
        <div className=" mb-3 grid-container">
          <div className="grid-item d-flex flex-column gap-2">
            <div className="product-container">
              <Product
                productDetails={productDetails?.productDetails}
                activeKey={dropdownStates}
                setActiveKey={setDropdownStates}
                handleToggle={handleToggle}
              />
            </div>
            <div className="offers-container">
              <Widget
                title="Offers"
                icon="shoppingcart"
                activeKey={dropdownStates}
                setActiveKey={setDropdownStates}
                handleToggle={handleToggle}
                children={
                  <Offers
                    offers={offers}
                    getOffers={getOffers}
                    productDetails={productDetails}
                  />
                }
              />
            </div>
          </div>
          <div className="grid-item d-flex flex-column gap-2">
            <div className="profit-calculator">
              <ProfitCalculator
                productDetails={productDetails}
                setProductDetails={setProductDetails}
                activeKey={dropdownStates}
                setActiveKey={setDropdownStates}
                handleToggle={handleToggle}
                offers={offers}
                setOffers={setOffers}
                roi={roi}
                setRoi={setRoi}
                totalDiscount={totalDiscount}
                setFulFillmentType={setFulFillmentType}
                calculateProfit={calculateProfit}
                referralFee={referralFeePercentage}
                getOffers={getOffers}
                offersFilter={offersFilter}
              />
            </div>
            <div className="ebay-container">
              <Widget
                title="Buy Box Analysis"
                icon="piechart"
                activeKey={dropdownStates}
                setActiveKey={setDropdownStates}
                handleToggle={handleToggle}
                children={
                  <Analysis
                    buyBoxAnalysis={buyBoxAnalysis}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setBuyBoxFilter={setBuyBoxFilter}
                    buyBoxLoader={buyBoxLoader}
                    setBuyBoxLoader={setBuyBoxLoader}
                  />
                }
              />
            </div>
          </div>
          <div className="grid-item d-flex flex-column gap-2">
            <div className="quick-info">
              <QuickInfo
                productDetails={productDetails}
                setProductDetails={setProductDetails}
                offers={offers}
                setOffers={setOffers}
                roi={roi}
                setRoi={setRoi}
                activeKey={dropdownStates}
                setActiveKey={setDropdownStates}
                handleToggle={handleToggle}
                totalDiscount={totalDiscount}
                calculateProfit={calculateProfit}
                getOffers={getOffers}
                offersFilter={offersFilter}
              />
            </div>
            <div className="ebay-container">
              <Widget
                title="Charts"
                icon="charts"
                activeKey={dropdownStates}
                setActiveKey={setDropdownStates}
                handleToggle={handleToggle}
                children={
                  <Charts
                    chartsData={chartsData}
                    chartsFilter={chartsFilter}
                    setChartsFilter={setChartsFilter}
                    chartsLoader={chartsLoader}
                  />
                }
              />
            </div>
          </div>

          <div className="grid-item d-flex flex-column gap-2">
            <div className="ranks-and-prices">
              <RanksAndPrices
                ranksAndPrices={ranksAndPrices}
                rankFilter={rankFilter}
                setRankFilter={setRankFilter}
                activeKey={dropdownStates}
                loading={ranksLoader}
                setActiveKey={setDropdownStates}
                getRanksAndPrices={getRanks}
                handleToggle={handleToggle}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Widgets;
